import { useRef, useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import Box from '@mui/material/Box';
// import { useViewport } from 'reactflow';

import {
	itemWidth,
	itemHeight,
	gridGap,
} from './index';
import Loading from '../Loading';

const styles = {
	'.dropzone-container': {
		width: '100%',
		height: '100%',
	},
	'.instructions': {
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	'.hidden': {
		visibility: 'hidden',
	},
	'.lock-ovelay': {
		position: 'absolute',
	},
};

function DropZone({
	children,
	className,
	accept = 'dnd-item',
	name = 'dropzone',
	instructions,
	x,
	y,
	zoom,
	isLoading,
	showGlobalMenu,
	...rest
}) {
	const rootRef = useRef();
	const [offsetParent, setOffsetParent] = useState();

	useEffect(() => {
		if (!rootRef.current) return;
		const { left, top } = rootRef.current.getBoundingClientRect();
		setOffsetParent({ left, top });
	}, [rootRef?.current, showGlobalMenu]);

	const [{ canDrop, isOver }, drop] = useDrop(
		() => ({
			accept,
			// Props to collect
			collect: (monitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			}),
			drop: (_, monitor) => {
				const dropOffset = monitor.getClientOffset();
				const roundedX = Math.round(
					(dropOffset.x - offsetParent?.left - itemWidth / 2 - x) /
						(gridGap * zoom),
				);
				const roundedY = Math.round(
					(dropOffset.y - offsetParent?.top - itemHeight / 2 - y) /
						(gridGap * zoom),
				);

				return {
					x: roundedX * gridGap,
					y: roundedY * gridGap,
				};
			},
		}),
		[offsetParent, x, y, zoom],
	);

	return (
		<Box
			ref={rootRef}
			sx={styles}
			className={`dropzone-root ${className || ''}`}
			{...rest}
		>
			<Box className={isLoading ? 'lock-overlay' : 'hidden'}>
				<Loading loading={isLoading}>loading</Loading>
			</Box>
			<Box
				className={!isLoading ? 'dropzone-container' : 'hidden'}
				ref={drop}
				role={'Dustbin'}
			>
				{instructions(canDrop)}
				{children}
			</Box>
		</Box>
	);
}
export default DropZone;
