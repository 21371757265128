import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/designs`;

export const saveDesign = async (data, headers = {}) =>
	fetchPost(BASE, headers, data);

export const getDesign = async (params, headers = {}) =>
	fetchGet(`${BASE}/recipe/${encodeURIComponent(params)}`, headers);

export const getDesignBatch = async (params, headers = {}) =>
	fetchGet(`${BASE}/batch/${encodeURIComponent(params)}`, headers);

export const getDesignList = async (params, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);
