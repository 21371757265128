import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import { clientFields } from '@pw/components/Client/clientFields';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useContext, useMemo } from 'react';

function Properties() {
	const { readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path='properties'>
				<AssetAccounts />
			</FormikProvider>
		)
	);
}

function propertyFields(fields = {}) {
	const { accounts = {} } = fields;
	return {
		accounts: assetAccountsFields(accounts),
	};
}
function ChangeOwnerShipForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Assets',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
			asset_types: [ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.pallet, ASSET_TYPES.container],
		},
		submitForm,
		SKUModal: SourceSKUModal,
	});

	const changeSetGenerator = useMemo(() => (initialValues) => ({
		...requestIdFields(initialValues),
		properties: propertyFields(initialValues.properties),
		client: clientFields(initialValues.client),
	}), []);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback((entity) => {
		initSources({ ...entity, assets: entity?. sources, skus: entity?.sku_sources });
	}, [initSources]);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
		}),
		[sourceSkus, sourceAssets],
	);

	return (
		<>
			<RequestForm
				requestLabel='Change Ownership'
				requestType={REQUEST_TYPES.change_ownership}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />

				<Properties />
				<FormSubmitter />
			</RequestForm>

			<SourceModals />
		</>
	);
}

export default withAppLayout(ChangeOwnerShipForm, {
	title: 'Transfer Ownership',
});
