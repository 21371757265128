import Box from '@mui/material/Box';

export function FlexBox({ children, ...props }) {
	return (
		<Box
			display='flex'
			gap={2}
			justifyContent='space-between'
			alignItems='center'
			width='100%'
			{...props}
		>
			{children}
		</Box>
	);
}
