import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikForm,
	FormikNumberField
} from '@pw/components/Forms/FormikForm';
import { useMemo } from 'react';
import * as yup from 'yup';

function ServiceSKU({ item, onClose }) {
	// We track the "amount" we want at the SKU Item level
	const { amount = 0 } = item ?? {};

	const changeSet = useMemo(
		() => ({
			amount: [
				amount ? amount : '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
		}),
		[amount],
	);

	const handleSubmit = (values) => {
		const sku = {
			...item,
			amount: values.amount,
		};
		console.log('Setting SKU', values, sku);
		onClose(sku);
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={1}>
				<FormikNumberField label='Items' name='amount' fullWidth />

				<Errors />

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default ServiceSKU;
