import { Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import Client from '@pw/components/Client';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import { ASSET_ACCOUNT_TYPE_TO_LABEL } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsOnlineHook } from '@pw/redux/containers/App';
import { useAccountPermissions } from '@pw/redux/containers/User/hooks';
import { mt2 } from '@pw/styles/margins';
import { useContext } from 'react';

export function AssetAccountsImp({
	label = 'Asset Accounts',
	disabled = false,
}) {
	const { values } = useContext(FormikContext);
	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				label={<H5>{label}</H5>}
				disabled={disabled}
			/>
			<Collapse in={!!values?.enable} unmountOnExit>
				<Stack spacing="1.5rem" sx={{ ...mt2 }}>
					<Client
						name='bo'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['0']}`}
						disabled={disabled}
						withEnable={false}
					/>
					<Client
						name='mo'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['1']}`}
						disabled={disabled}
						withEnable={false}
						noCustomers={true}
					/>
					<Client
						name='ro'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['2']}`}
						disabled={disabled}
						withEnable={false}
						noCustomers={true}
					/>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function AssetAccounts({ name = 'accounts', ...rest }) {
	const { editOwners } = useAccountPermissions();
	const online = useIsOnlineHook();
	const { readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path={name}>
				<AssetAccountsImp {...rest} disabled={!editOwners || !online} />
			</FormikProvider>
		)
	);
}
