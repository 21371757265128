import { StoreOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import NameDisplay from './NameDisplay';
import ProductCodeDisplay from './ProductCodeDisplay';

function VendorDisplay({ value }) {
	if (!value) return;
	if (typeof value === 'string') {
		return (
			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					gap: '0.25rem',
				}}
			>
				<StoreOutlined style={{ width: '12px', height: 'auto' }} />
				<Body3>
					<Bolder>Vendor:</Bolder>
					&nbsp;
					{value}
				</Body3>
			</Box>
		);
	}
	const { vendor, product } = value ?? {};

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<Body3>
				<Bolder>Vendor:</Bolder>
				&nbsp;
				<Stack paddingLeft={'12px'} spacing={0.5}>
					<NameDisplay name={vendor?.name ?? vendor?.public_name} />
					<ProductCodeDisplay value={product} />
				</Stack>
			</Body3>
		</Box>
	);
}

export default VendorDisplay;
