import Box from '@mui/material/Box';
import CountdownContext from '@pw/context/CountdownContext';
import { useContext } from 'react';

export function CountdownTimer({
  initial,
  showZeroValues,
}) {
  const {
    days = initial?.days ?? 0,
    hours = initial?.hours ?? 0,
    minutes = initial?.minutes ?? 0,
    seconds = initial?.seconds ?? 0,
  } = useContext(CountdownContext);

  const pad = (num) => `${num}`.padStart(2, '0');

  const d = pad(days);
  const h = pad(hours);
  const m = pad(minutes);
  const s = pad(seconds);

  return (
    <Box className="countdown">
      <Box className="timer">
        {(days >= 1 || showZeroValues) && (
          <Box className="segment">
            <Box className="number">{d}</Box>
            <Box className="unit"> days</Box>
          </Box>
        )}

        {days < 1 && (
          <>
            {(hours > 0 || showZeroValues) && (
              <Box className="segment">
                <Box className="number">{h}</Box>
                <Box className="unit">h</Box>
              </Box>
            )}

            {(minutes > 0 || showZeroValues) && (
              <Box className="segment">
                <Box className="number">{m}</Box>
                <Box className="unit">m</Box>
              </Box>
            )}

            <Box className="segment">
              <Box className="number">{s}</Box>
              <Box className="unit">s</Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
