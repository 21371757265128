// import { itemWidth, itemHeight } from 'src/pages/app/production-designer';

export const itemWidth = 190;
export const itemHeight = itemWidth;


const styles = {
  '&.node-root': {
    position: 'relative',
    visibility: 'visible',
    overflow: 'visible',
    width: `${itemWidth}px`,
    height: `${itemHeight}px`,
    boxSizing: 'border-box',
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    transform: 'rotate(45deg)',
    transformOrigin: 'center center',
    // ['@property --handle-color']: {
    //   syntax: 'color',
    //   inherits: false,
    // },

    '&:hover': {
      '.node-handle': { zIndex: 3, }
    },
    '&::after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      opacity: 0,
      left: '1rem',
      top: '1rem',
      right: '1rem',
      bottom: '1rem',
      padding: '1rem',
      boxSizing: 'content-box',
      border: '2px dashed #ccc',
      transition: 'all 0.4s',
      zIndex: 0,
    },
  },
  '.node-handle': { transition: 'all 0.8s', },
  '--handle-color': '#EBEAE9',
  '.label': { color: '#00A89D', },
  '&.node-root:hover': {
    '--handle-color': '#F0AA29',
  },
  '.handle-connected': {
    '--handle-color': '#F0AA29',
    opacity: 1
  },
  '&.material-node': {
    '.label': { color: '#00A89D' }
  },
  '&.process-node': {
    '--handle-color': '#00A89D',
    '.handle-type-source': { opacity: 0, },
    '.handle-type-source.handle-connected': { opacity: 1, },

    '.handle-base': { opacity: 1, },

    '.node-content': {
      backgroundColor: '#00A89D',
      color: 'white'
    },
    '&:hover': {
      '.handle-type-source': { opacity: 1 },
      '.handle-type-target': { opacity: 1 },
      '.handle-connected': { opacity: 1 },
    },
  },

  '&.observation-node': {
    '.node-content': { backgroundColor: '#C753A8', },
    '.icon-label-group': { color: 'white', },
    '.node-handle': { color: '#B4509F' },
    '--handle-color': '#B4509F',
  },
  '&.operation-node': {
    '.node-content': { backgroundColor: '#C2D559', },
    '.icon-label-group': { color: '#707070', },
    // '.node-handle': { color: '#082345' },
    '--handle-color': '#C2D559',
  },
  '&.node-selected': {
    '&::after': {
      opacity: 1,
      zIndex: -1,
    },
  },


  '.node-content': {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    position: 'absolute',
    boxShadow: '1px 1px 3px 0px #00000040',
    left: '2rem',
    right: '2rem',
    top: '2rem',
    bottom: '2rem',
    textAlign: 'center'
  },
  '.icon-label-group': {
    width: '100%',
    height: '100%',
    transform: 'rotate(-45deg) translateY(-0.5rem)',
    transformOrigin: 'center center'
  },
  '.icon-container': {
    display: 'block',
    position: 'relative',
    top: '1.5rem',
    fontSize: '3rem',
    height: '1em',
    width: 'auto',
    lineHeight: 1,
  },
  '.label': {
    position: 'relative',
    top: '1.75rem',
    fontSize: `${14 / 16}rem`,
  },
  '.more': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    color: '#00A89D',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(135deg, transparent 0%,  transparent 77%,  #EBEAE9 77%)',

    svg: {
      color: '#00A89D',
      borderRadius: '100%',
      border: '1px solid'
    },

    '.more-button': {
      position: 'absolute',
      right: '-1.1rem',
      bottom: '-.4rem',
      width: '3em',
      height: '2em',
      transform: 'rotate(-45deg)',
      fontSize: '1.5rem',
    },
  },

  '.node-handle': {
    width: '14px',
    height: '14px',
    margin: '-7px',
    backgroundImage: 'radial-gradient(var(--handle-color) 0%, var(--handle-color) 35%, white 35%, white 35%)',
    border: '0.125rem solid var(--handle-color)',
    transform: 'unset !important',
  },
  // offset by padding on root

  '.top': { top: '2rem', left: '2rem', },
  '.right': { right: '2rem', left: 'auto', top: '2rem', },
  '.bottom': { bottom: '2rem', top: 'auto', right: '2rem', left: 'auto' },
  '.left': { left: '2rem', bottom: '2rem', top: 'auto' },
  '.top-left': { top: '50%', left: '2rem' },
  '.top-right': { left: '50%', top: '2rem' },
  '.bottom-right': { bottom: '50%', right: '2rem', left: 'auto' },
  '.bottom-left': { left: '50%', bottom: '2rem' },

  // on process nodes, position 
  // '.target': {
  //   // opacity: 0,
  //   border: '1px solid red',
  //   '&.top': { left: '2rem' },
  //   '&.right': { top: '2rem' },
  //   '&.bottom': { left: 'auto', right: '1.25rem' },
  //   '&.left': { top: 'auto', bottom: '1.25rem' },
  // },
};

export default styles;
