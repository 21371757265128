import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikDatePicker,
	FormikForm,
	FormikMeasuresField,
	FormikNumberField,
	FormikSelect,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { InventorySelectorV2 } from '@pw/components/InventorySelector';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import TrackedSKUSetup from '@pw/components/SKUSelector/modals/dest/TrackedSKUSetup';
import { H5 } from '@pw/components/Typography';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS, SKU_TYPES } from '@pw/consts/sku';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from '@pw/styles/modal.styles';
import toSKUStorageItem from '@pw/utilities/adapters/toSKUStorageItem';
import { COMP, ID } from '@pw/utilities/comp';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import * as yup from 'yup';

export function SKUStorageItem({ item, unit }) {
	const { amount } = item;

	return <AmountDisplay label='Allocated' unit={unit} amount={amount} />;
}

function DeliverySKUModal({ item, open, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	const {
		sku_type,
		sku_name,
		sku_description,
		entries = [{ amount: null }],
		properties = {},
		vendors = [],
	} = item ?? {};

	const { unit, last_number } = properties;

	// We expect there to be an entry - which has the specific values we want for delivery..
	const entry = entries[0];
	const {
		expiry,
		vendor,
		total_cost,
		duty_paid = false,
		amount,
		storage = [],
		tracked = [],
	} = entry;

	const converter = useConverter();

	const hasUnits = useMemo(
		() =>
			![
				SKU_TYPES.TRACKED,
				SKU_TYPES.FINISHED,
				SKU_TYPES.WIP,
				SKU_TYPES.SERVICE,
				SKU_TYPES.CONSUMABLE,
			].includes(sku_type),
		[sku_type],
	);

	console.log('SKU Item', hasUnits, item);

	const isExpiring = useMemo(
		() => [SKU_TYPES.EXPIRING].includes(sku_type),
		[sku_type],
	);
	const isTracked = useMemo(
		() => [SKU_TYPES.TRACKED].includes(sku_type),
		[sku_type],
	);
	const isFinishedGoods = useMemo(
		() => [SKU_TYPES.FINISHED].includes(sku_type),
		[sku_type],
	);
	const isService = useMemo(
		() => [SKU_TYPES.SERVICE].includes(sku_type),
		[sku_type],
	);
	const isGeneral = useMemo(
		() =>
			![SKU_TYPES.TRACKED, SKU_TYPES.WASTE, SKU_TYPES.SERVICE].includes(
				sku_type,
			),
		[sku_type],
	);

	// Any tracked assets
	const [
		trackedAssets,
		initTrackedAssets,
		,
		upsertTrackedAsset,
		removeTrackedAsset,
	] = useItemListManager(ID.asset, COMP.asset, tracked);
	// Storage assets
	const [storageAssets, , , upsertAsset, removeAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
		storage,
	);
	// Specific storage asset being edited
	const [storageAsset, setStorageAsset] = useState(null);

	const count = useMemo(() => {
		console.log('Amount', amount, 'Tracked', trackedAssets.length);
		if (trackedAssets.length > 0) {
			return trackedAssets.length;
		}
		return amount;
	}, [amount, trackedAssets]);

	const [ProgressBar, { setProgress }] = useProgress();

	const dp = useMemo(() => {
		if (isFinishedGoods) {
			return {
				duty_paid: [!!duty_paid, yup.boolean()],
			};
		}
		return {};
	}, [duty_paid, isFinishedGoods]);

	const exp = useMemo(() => {
		if (isExpiring) {
			return {
				expiry: [
					expiry ?? new Date().getTime(),
					yup.number().integer().required('Expiry required!'),
				],
			};
		}
		return {};
	}, [expiry, isExpiring]);

	const changeSet = useMemo(
		() => ({
			...(!isService
				? {
						vendor: [
							vendor,
							yup.object().shape({
								name: yup.string(),
								public_name: yup.string(),
								id: yup.string(),
								hash: yup.string(),
								type: yup.string(),
							}),
						],
					}
				: {}),
			amount: [
				!isTracked && unit && count ? converter.cx(count, null, unit) : count ?? '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
			...exp,
			...dp,
			total_cost: [
				total_cost ?? '',
				yup.number().nullable().min(0, 'Must be positive!'),
			],
		}),
		[],
	);

	const vendorOptions = useMemo(
		() => (vendors ?? []).map((v) => ({ label: v?.product, value: v })),
		[vendors],
	);

	const handleSubmit = useCallback(
		(values) => {
			const updatedEntry = {
				...entry,
				vendor: values?.vendor,
				total_cost: values?.total_cost,
				expiry: values?.expiry,
				duty_paid: values?.duty_paid,
				amount: hasUnits
					? converter.cx(values.amount, unit)
					: tracked.length > 0
						? tracked.length
						: values.amount,
				storage: storageAssets,
				tracked: trackedAssets,
			};

			console.log('Updated Entry', hasUnits, updatedEntry);

			let processed = item?.processed ?? INVENTORY_STATUS.PENDING;
			if (storageAssets && storageAssets.length > 0) {
				processed = INVENTORY_STATUS.CONFIRMED;
				storageAssets.forEach((i) => {
					i.duty_paid = values?.duty_paid;
					// i.processed = processed;
					if (i.processed !== ASSET_PROCESSED_STATUS.CONFIRMED) {
						processed = INVENTORY_STATUS.PENDING;
					}
				});
			}

			const sku = {
				...item,
				processed,
				last_number: Number(last_number) + trackedAssets.length,
				entries: [updatedEntry],
			};
			console.log('SKU', sku);

			onClose(sku);
		},
		[item, entry, storageAssets, trackedAssets],
	);

	useEffect(
		() => {
			const totalAllocated = storageAssets.reduce(
				(v, i) =>
					Number(v) + Number(unit ? converter.cx(i.amount, null, unit) : i.amount * 1.0),
				0,
			);
			console.log('Total allocated', totalAllocated);
			setProgress(totalAllocated);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[storageAssets, unit],
	);

	// Need to adapt the SKU to meet the SKUItem/SKUEntryItem/SKUStorage structure
	// As this is a delivery, we'll add one basic entry here
	const handleAdd = (asset) => {
		if (asset?.asset_status === ASSET_STATUS.DEFECTED) {
			enqueueSnackbar(`Defective assets cannot be selected!`, {
				variant: 'error',
			});
			return;
		}
		setStorageAsset(toSKUStorageItem(asset));
	};

	const display = (value) => (
		<SKUStorageItem
			item={value}
			key={value.asset_id}
			unit={hasUnits ? unit : null}
		/>
	);

	const filter = useMemo(
		() => ({
			asset_types: [ASSET_TYPES.pallet, ASSET_TYPES.container],
		}),
		[],
	);

	return (
		<>
			<Modal open={open} onClose={() => onClose()}>
				<Stack sx={styles} className='root' spacing={2}>
					<FlexBox>
						<H5>{sku_name} Delivery</H5>
						<IconButton
							onClick={() => onClose()}
							className='close-btn'
							aria-label='Close'
						>
							<CloseIcon />
						</IconButton>
					</FlexBox>

					<Instructions>{sku_description}</Instructions>

					<Box
						sx={{
							overflowY: 'auto',
							height: 'auto',
							maxHeight: 'calc(95vh - 9rem)',
						}}
					>
						<FormikForm
							changeSet={changeSet}
							onSubmit={handleSubmit}
							enableReinitialize
						>
							<Stack spacing='2rem'>
								{!isService && (
									<FormikSelect
										label='Vendor'
										name='vendor'
										options={vendorOptions}
										fullWidth
									/>
								)}
								{isExpiring && (
									<FormikDatePicker label='Expiry' name='expiry' fullWidth />
								)}
								{isFinishedGoods && (
									<FormikCheckBox label='Duty Paid' name='duty_paid' />
								)}
								<FlexBox>
									<FormikMeasuresField
										label={hasUnits ? 'Amount' : 'Items'}
										name='amount'
										unit={hasUnits ? unit : null}
										fullWidth
									/>
									{!isService && (
										<FormikNumberField
											label='Total Cost'
											name='total_cost'
											fullWidth
										/>
									)}
								</FlexBox>

								{isGeneral && (
									<>
										<Instructions>
											Please select the storage for the contents of this
											delivery.
										</Instructions>

										<ProgressBar name='amount' label='Amount' />

										<InventorySelectorV2
											title='Storage Allocation'
											types={filter}
											onAdd={handleAdd}
											assets={[
												storageAssets,
												setStorageAsset,
												removeAsset,
												display,
											]}
										/>
									</>
								)}

								{isTracked && (
									<TrackedSKUSetup
										sku={item}
										field='amount'
										assets={[
											trackedAssets,
											initTrackedAssets,
											upsertTrackedAsset,
											removeTrackedAsset,
										]}
									/>
								)}

								<Errors />

								<Box className='action-buttons'>
									<TextButton
										size='small'
										handleClick={() => onClose()}
										color='secondary'
									>
										Cancel
									</TextButton>
									<FilledButton
										type='submit'
										size='small'
										disabled={isTracked && trackedAssets.length === 0}
									>
										Save
									</FilledButton>
								</Box>
							</Stack>
						</FormikForm>
					</Box>
				</Stack>
			</Modal>

			{!!storageAsset && (
				<StorageSetupModal
					open={!!storageAsset}
					unit={hasUnits ? unit : null}
					item={storageAsset}
					onClose={(v) => {
						if (v) {
							upsertAsset(v);
						}
						setStorageAsset(null);
					}}
				/>
			)}
		</>
	);
}

export default DeliverySKUModal;
