import generalInformationFields from './generalInformationFields';
import part1Fields from './part1Fields';
import part2Fields from './part2Fields';
import signatureFields from './signatureFields';

export default function TTBReport_11Fields(fields = {}) {
	const { general_information, part1, part2, signature } = fields;

	return {
		general_information: generalInformationFields(general_information),
		part1: part1Fields(part1),
		part2: part2Fields(part2),
		signature: signatureFields(signature),
	};
}
