import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import ProductionOfWhiskeyReport from './ProductionOfWhiskeyReport';

export default function ProductionOfWhiskey({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<ProductionOfWhiskeyReport name='1_Bourbon' title='1.Bourbon' />
					<ProductionOfWhiskeyReport name='2_Corn' title='2.Corn' />
					<ProductionOfWhiskeyReport name='3_Rye' title='3.Rye' />
					<ProductionOfWhiskeyReport name='4_Light' title='4.Light' />
					<ProductionOfWhiskeyReport
						name='5_Other_Specify'
						title='5.Other-Specify'
					/>
					<ProductionOfWhiskeyReport
						name='6_Other_Specify'
						title='6.Other-Specify'
					/>
					<ProductionOfWhiskeyReport
						name='7_Other_Specify'
						title='7.Other-Specify'
					/>
					<ProductionOfWhiskeyReport
						name='8_Other_Specify'
						title='8.Other-Specify'
					/>
				</>
			)}
		</FormikProvider>
	);
}
