import { ExpandMore as ExpandMoreIcon, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import {
	FormikRadioGroup,
	FormikSelect,
	FormikTextAreaField,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { H4 } from '@pw/components/Typography';
import controlStates from '@pw/consts/controlStates.js';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';

const years = Array.from({ length: 30 }, (_, i) => 2021 + i);
const plantTypes = [
	{ value: '1', label: 'Small' },
	{ value: '2', label: 'Medium' },
	{ value: '3', label: 'Large' },
];

export default function Part1({ name, title = 'Part 1', disabled = false }) {
	const [expanded, setExpanded] = useState(false);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<FormikSelect
						name='Report_Year'
						label='Report for calendar year'
						options={years.map((year) => ({ value: year, label: year }))}
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikRadioGroup
						name='typeOfPlant'
						label='Type of Plant'
						options={plantTypes}
						required
						disabled={disabled}
					/>

					<FormikTextAreaField
						name='Proprietor_Name'
						label='Name of Proprietor'
						required
						fullWidth
						multiline={true}
						rows={4}
						disabled={disabled}
					/>
					<FormikTextField
						name='address1'
						label='Address 1 '
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='address2'
						label='Address 2 '
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='city'
						label='City'
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikSelect
						name='state'
						label='State'
						options={controlStates}
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='zipCode'
						label='Zip Code'
						required
						fullWidth
						disabled={disabled}
					/>
				</>
			)}
		</FormikProvider>
	);
}
