import * as yup from 'yup';
import spiritReportFields from './spiritReportFields';

export default function part3Fields(fields = {}) {
	const {
		P3_Spirits_Beginning = '',
		P3_Spirits_Distilling = '',
		P3_Spirits_Received = '',
		P3_Spirits_Inventory = '',
		P3_Spirits_Imported = '',
		part3_6 = {},
		P3_Spirits_Making_Fuel = '',
		P3_Spirits_Used_Fuel = '',
		P3_Spirits_Used_Distilling = '',
		P3_Spirits_Lost = '',
		P3_Spirits_InventoryLoss = '',
		P3_Spirits_Transferred = '',
		P3_Spirits_Destroyed = '',
		part3_15 = {},
		P3_FuelAlcohol_Manufactured = '',
		P3_FuelAlcohol_Distributed = '',
		P3_FuelAlcohol_Customs = '',
		P3_FuelAlcohol_Used_Fuel = '',
		P3_FuelAlcohol_Destroyed = '',
		P3_FuelAlcohol_Other = '',
		P3_FuelAlcohol_Remarks = '',
		perjury2 = 1,
	} = fields;

	const P3_Spirits_Total =
		Number(P3_Spirits_Beginning) +
		Number(P3_Spirits_Distilling) +
		Number(P3_Spirits_Received) +
		Number(P3_Spirits_Inventory) +
		Number(P3_Spirits_Imported) +
		Number(part3_6.proofGallons || 0);
	const P3_Spirits_Total_16 =
		Number(P3_Spirits_Making_Fuel) +
		Number(P3_Spirits_Used_Fuel) +
		Number(P3_Spirits_Used_Distilling) +
		Number(P3_Spirits_Lost) +
		Number(P3_Spirits_InventoryLoss) +
		Number(P3_Spirits_Transferred) +
		Number(P3_Spirits_Destroyed) +
		Number(part3_15.proofGallons || 0);
	const P3_Spirits_End = Number(P3_Spirits_Total) - Number(P3_Spirits_Total_16);
	return {
		P3_Spirits_Beginning: [
			P3_Spirits_Beginning,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Distilling: [
			P3_Spirits_Distilling,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Received: [
			P3_Spirits_Received,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Inventory: [
			P3_Spirits_Inventory,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Imported: [
			P3_Spirits_Imported,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		part3_6: spiritReportFields(part3_6),
		P3_Spirits_Total: [
			P3_Spirits_Total,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Making_Fuel: [
			P3_Spirits_Making_Fuel,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Used_Fuel: [
			P3_Spirits_Used_Fuel,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Used_Distilling: [
			P3_Spirits_Used_Distilling,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Lost: [
			P3_Spirits_Lost,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_InventoryLoss: [
			P3_Spirits_InventoryLoss,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Transferred: [
			P3_Spirits_Transferred,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_Destroyed: [
			P3_Spirits_Destroyed,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		part3_15: spiritReportFields(part3_15),
		P3_Spirits_Total_16: [
			P3_Spirits_Total_16,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_Spirits_End: [
			P3_Spirits_End,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Manufactured: [
			P3_FuelAlcohol_Manufactured,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Distributed: [
			P3_FuelAlcohol_Distributed,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Customs: [
			P3_FuelAlcohol_Customs,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Used_Fuel: [
			P3_FuelAlcohol_Used_Fuel,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Destroyed: [
			P3_FuelAlcohol_Destroyed,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Other: [
			P3_FuelAlcohol_Other,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P3_FuelAlcohol_Remarks: [P3_FuelAlcohol_Remarks, yup.string()],
		perjury2: [perjury2, yup.boolean()],
	};
}
