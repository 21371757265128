import { cloneSKU, createSKU } from "@pw/services/sku.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const cloneSKUThunk = createAsyncThunk(
	'sku/clone',
	async (sku, { rejectWithValue }) => {
		try {
			const response = await cloneSKU(sku);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const upsertSKUThunk = createAsyncThunk(
	'sku/upsert',
	async (sku, { rejectWithValue }) => {
		try {
			const response = await createSKU(sku);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);