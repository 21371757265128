import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS, UNITS_REVERSE } from '@pw/consts/units';
import { useMemo } from 'react';

const useLiquidSummaryCalculator = (converter, skuList, assets = [], skus = []) => {

  return useMemo(() => {
    let obl = 0;
    let ola = 0;
    let rbl = 0;
    let rla = 0;
    let sbl = 0;
    let sla = 0;
    let si = 0;
    let sg = 0;
    let nw = 0;
    let gw = 0;
    let ebl = 0;
    let tbl = 0;
    let tabv = 0;
    let dpbl = 0;
    let dpla = 0;
    let dpsbl = 0;
    let dpsla = 0;

    assets.forEach((asset) => {
      const { properties = {} } = asset;
      const { liquid = {}, sku_id } = properties;
      const { level = {} } = liquid;
      const {
        bl,
        la,
        updated_la,
        updated_bl,
        abv,
        updated_abv,
        tcf,
        updated_tcf,
        duty_paid_bl,
        duty_paid_la,
      } = level;

      obl += Number(bl ?? 0);
      ola += Number(la ?? 0);
      rbl += Number(updated_bl ?? 0);
      rla += Number(updated_la ?? 0);
      dpbl += Number(duty_paid_bl ?? 0);
      dpla += Number(duty_paid_la ?? 0);

      const tcfv = Number(updated_tcf ?? tcf ?? '1.000');
      const abvv = Number(updated_abv ?? abv);

      // Scale up the bulk by the tcf
      const bulk = Number(updated_bl ?? bl);
      tbl += bulk;
      ebl += bulk * tcfv;
      tabv += bulk * abvv;

      // console.log(' --> ', bulk, tcfv, abvv);
      // console.log('Asset', asset.asset_id, sku_id);

      if (sku_id) {
        const fs = skuList.find((s) => s.sku_id === sku_id);
        if (fs) {
          const { net_weight, gross_weight } = fs.properties ?? {};
          if (net_weight) {
            nw += converter.cx(net_weight.value, net_weight.unit, UNITS.kilograms);
          }
          if (gross_weight) {
            gw += converter.cx(gross_weight.value, gross_weight.unit, UNITS.kilograms);
          }
        }
      }
    });

    skus.forEach((sku) => {
      if (sku.sku_type === SKU_TYPES.FINISHED) {
        // console.log('Sku', sku.sku_id, sku.sku_name, sku.entries, sku.properties);
        const items = Number((sku.entries ?? []).reduce((acc, curr) => acc + Number(curr.amount), 0));

        const { count = 1, size, abv, net_weight, gross_weight } = sku.properties ?? {};
        si += items;
        sg += Math.ceil(items / Number(count));

        let val_s = 0;
        if (size) {
          const val = items * Number(size.value);
          val_s = converter.cx(val, size.unit, UNITS.liters);
        }

        // console.log(' --> values', si, sg, size, val_s);

        const abv_val = converter.cx(abv?.value, abv?.unit, UNITS.abv);
        sbl += Number(val_s);
        sla += Number(val_s) * (abv_val / 100);

        dpsbl += sbl;
        dpsla += sla;

        nw += converter.cx(net_weight?.value, UNITS_REVERSE[net_weight?.unit], UNITS.kilograms) * items;
        gw += converter.cx(gross_weight?.value, UNITS_REVERSE[gross_weight?.unit], UNITS.kilograms) * items;
      }
    });

    if (assets.length > 0 || si > 0) {
      // Calculate the weighted average abv and the tcf
      const wabv = tbl > 0 ? tabv / tbl : null;
      const wtcf = tbl > 0 ? ebl / tbl : null;
      return {
        asset_count: assets.length,
        original_bulk: obl,
        original_la: ola,
        updated_bulk: rbl,
        updated_la: rla,
        sku_count: si,
        sku_bulk: sbl,
        sku_la: sla,
        sku_groups: sg,
        net_weight: nw,
        gross_weight: gw,
        tcf_bulk: ebl, // tcf adjusted bulk
        weighted_abv: wabv,
        weighted_tcf: wtcf,
        duty_paid_asset_bulk: dpbl,
        duty_paid_asset_la: dpla,
        duty_paid_sku_bulk: dpsbl,
        duty_paid_sku_la: dpsla,
      };
    } else {
      return {
        asset_count: 0,
        sku_count: 0,
      };
    }
  }, [converter, skuList, assets, skus]);
};

export default useLiquidSummaryCalculator;
