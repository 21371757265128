import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Part1Report({
	name,
	title = 'On Hand First of Month',
	disabled,
	bonded = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='b'
				label='Whisky Distilled At 160° And Under'
				fullWidth
				sx={{ ...(!bonded && styles.transaction) }}
			/>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='c'
					label='Whisky Distilled At Over 160°'
					fullWidth
					sx={{ ...(!bonded && styles.transaction) }}
				/>
				<FormikTextField
					name='d'
					label='Brandy Distilled At 170° And Under'
					fullWidth
				/>
			</FlexBox>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='e'
					label='Brandy Distilled At Over 170°'
					fullWidth
				/>
				<FormikTextField
					name='f'
					label='Rum'
					fullWidth
					sx={{ ...(!bonded && styles.transaction) }}
				/>
				<FormikTextField
					name='g'
					label='Gin'
					fullWidth
					sx={{ ...(!bonded && styles.transaction) }}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
