import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { useCompanyPermissions } from '@pw/redux/containers/User/hooks';
import { upsertTeamThunk } from '@pw/redux/thunks/teams';
import { FieldArray } from 'formik';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function PermissionSelector() {
  const permissions = useCompanyPermissions();
  const { values } = useContext(FormikContext);

  return (
    <Stack spacing="0.33rem">
      <Box className="fixedList">
        <FieldArray
          name="permissions"
          render={(arrayHelpers) => (
            <Stack spacing="0.75rem">
              {permissions.map((perm) => (
                <Stack direction="row" spacing="0.5rem" key={perm.code}>
                  <input
                    name="permissions"
                    type="checkbox"
                    value={perm.code}
                    checked={values.permissions.includes(perm.code)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        arrayHelpers.push(perm.code);
                      } else {
                        const idx = values.permissions.indexOf(perm.code);
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                  <span>{perm.label}</span>
                </Stack>
              ))}
            </Stack>
          )}
        />
      </Box>

      <Errors />
    </Stack>
  );
}

function Team({ team, onClose }) {
  const dispatch = useDispatch();
  
  const onSubmit = useCallback(async (values) => {
    dispatch(upsertTeamThunk(values)).unwrap().then(onClose);
  }, [onClose]);

  const changeSet = {
    // company identifiers
    name: [team?.name ?? '', yup.string().required('Name is required!')],
    description: [team?.description ?? '', yup.string().required('Description is required!')],
    permissions: [team?.permissions ?? [], yup.array().required('Permissions are required!').min(1, 'Select at least one permission for the role!')],
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={onSubmit}
    >
      <Stack className="form" spacing="2rem">
        <Stack spacing="1.5rem">
          <H5>Team</H5>
          <FormikTextField name="name" label="Team name" fullWidth required />
          <FormikTextField name="description" label="Team description" required />
        </Stack>

        <Stack spacing="1.5rem">
          <H5>Permissions</H5>
          <PermissionSelector />
        </Stack>

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={onClose}
            color='secondary'
            label='Cancel'
          />
          <FilledButton type='submit' size='small'>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default Team;
