import { Close } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
} from '@mui/material';
import formatCompanyAddress from '@pw/components/Administration/formatCompanyAddress';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { Body2, Body3 } from '@pw/components/Typography';
import { Status } from '@pw/consts/admin';
import { confirmPartnerThunk } from '@pw/redux/thunks/partners';
import useCopyToClipboard from '@pw/utilities/hooks/logic/useCopyToClipboard';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function ConfirmPartnersInfoDialog({ open, onClose }) {
	const dispatch = useDispatch();
	const [handleCopy] = useCopyToClipboard();
	const {
		company_name = '',
		company_key = '',
		excise_id = '',
		status = '',
	} = open ?? {};
	
	// const [confirmApi, { isLoading }] = useConfirmPartnerMutation();

	const handleOnClose = useCallback(() => onClose(false), [onClose]);
	const handleConfirm = useCallback(
		(status) => () => {
			dispatch(confirmPartnerThunk({
				...open,
				status,
			}))
				.unwrap()
				.then(() => handleOnClose());
		},
		[open],
	);

	return (
		<Dialog
			open={!!open}
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth='xs'
		>
			<IconButton
				aria-label='close'
				onClick={handleOnClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<DialogTitle>Invite response</DialogTitle>
			<DialogContent>
				{open ? (
					<Stack spacing={1}>
						<Stack spacing={1}>
							<Body3>Organization Name:</Body3>
							<Body2>{company_name}</Body2>
						</Stack>
						<Stack spacing={1}>
							<Body3>Organization approval status:</Body3>
							<Body2>{status.toUpperCase()}</Body2>
						</Stack>
						<Stack spacing={1}>
							<Body3>Organization Address:</Body3>
							<Body2>{formatCompanyAddress(open)}</Body2>
						</Stack>
						<Box pb={1}></Box>
						{excise_id ? (
							<FormControl variant='outlined'>
								<InputLabel htmlFor='public-key'>Excise ID</InputLabel>
								<OutlinedInput
									id='Excise ID'
									readOnly
									size='small'
									value={excise_id}
									label='Excise ID'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='Copy Excise ID'
												onClick={() => handleCopy(excise_id)}
												edge='end'
											>
												<ContentCopyIcon />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						) : null}
						{company_key ? (
							<FormControl variant='outlined'>
								<InputLabel htmlFor='public-key'>Public key</InputLabel>
								<OutlinedInput
									id='public-key'
									readOnly
									value={company_key}
									label='Public key'
									size='small'
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='Copy public key'
												onClick={() => handleCopy(company_key)}
												edge='end'
											>
												<ContentCopyIcon />
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						) : null}
					</Stack>
				) : null}
			</DialogContent>
			<DialogActions sx={{ m: 2 }}>
				<TextButton
					size='small'
					handleClick={handleConfirm(Status.REJECTED)}
					color='secondary'
				>
					Reject
				</TextButton>
				<FilledButton
					type='submit'
					size='small'
					handleClick={handleConfirm(Status.APPROVED)}
				>
					Confirm
				</FilledButton>
			</DialogActions>
		</Dialog>
	);
}
