import Box from '@mui/material/Box';
import { useCallback } from 'react';
import {
  useStore, SmoothStepEdge, getSmoothStepPath, internalsSymbol
} from 'reactflow';

export const Position = {
  // process -> process source:source pairs
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left',
  // material -> process, process -> observation, process -> operation
  TopRight: 'top',
  BottomRight: 'right',
  BottomLeft: 'bottom',
  TopLeft: 'left',
};

const calcOffset = (node, position, handleType) => {
  const handleBounds = node[internalsSymbol].handleBounds;
  const handle = (handleBounds[handleType] || handleBounds['source']).find((h) => h.position === position);

  let offsetX = (handle.width / 2);
  let offsetY = (handle.height / 2);

  switch (position) {
    case Position.Left:
      offsetX = 1 * handle.width;
      break;
    case Position.Right:
      offsetX = -1 * handle.width;
      break;
    case Position.Top:
      offsetY = 1 * handle.height;
      break;
    case Position.Bottom:
      offsetY = -1 * handle.height;
      break;
  }
  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function CustomSmoothStepEdge({ id, source, target, className, selected, ...rest }) {
  const sourceNode = useStore(useCallback(
    (store) => store.nodeInternals.get(source)
    , [source]));
  const targetNode = useStore(useCallback(
    (store) => store.nodeInternals.get(target)
    , [target]));

  if (!(sourceNode && targetNode)) return null;
  const category = (sourceNode.data.category === 'process' &&
    targetNode.data.category === 'process')
    ? 'process'
    : 'default';

  const classNames = [
    category === 'process' ? 'process-edge' : 'default-edge',
    selected ? 'selected' : ''
  ].join(' ');

  const styles = {
    '.custom-edge': {
      outlineOffset: '0.25rem',
      outlineStyle: 'dashed',
    },
    '&.process-edge path': {
      stroke: '#00A89D',
      strokeWidth: '3px',
      transform: 'translate(3px, 3px)',
      borderRadius: 0,
    },
    '&.default-edge path': {
      stroke: '#E3A428',
      strokeWidth: '2px',
      transform: 'translate(2px, 2px)',
      borderRadius: 0,
    },
    '&.selected': {
      outline: '2px dashed #ccc',
      strokeDashOffset: 0,
    }
  }

  const { sourcePosition, targetPosition } = rest;
  const [sourceX, sourceY] = calcOffset(sourceNode, sourcePosition, 'source');
  const [targetX, targetY] = calcOffset(targetNode, targetPosition, 'target');

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetPosition,
    targetX,
    targetY,
    borderRadius: 0,
    strokeWidth: 3
  });

  return (
    <Box
      as="g"
      sx={styles}
      className={`custom-edge ${className || ''} ${classNames || ''}`}
    >
      <SmoothStepEdge
        id={id}
        edgePath={edgePath}
        {...rest}
      // {...rest}
      />
    </Box>
  );
}

export default CustomSmoothStepEdge;
