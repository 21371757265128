import { createTheme } from '@mui/material/styles';
import { f1, f2, f3, f4, f5, f6 } from '@pw/styles/fonts';

export const breakpoints = {
	xs: 0,
	sm: 600,
	md: 900,
	lg: 1200,
	xl: 1536,
	xxl: 1920,
};
// xs: '480px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1600px',

export default createTheme({
	palette: {
		primary: {
			main: '#F0AA29',
		},
		secondary: {
			main: '#000',
		},
	},
	breakpoints: {
		values: breakpoints
	},

	typography: {
		fontFamily: [
			'Poppins',
			'-apple-system',
			'BlinkMacSystemFont',
			'Segoe UI',
			'Roboto',
			'Helvetica Neue',
			'Arial',
			'Noto Sans',
			'sans-serif',
			'Apple Color Emoji',
			'Segoe UI Emoji',
			'Segoe UI Symbol',
			'Noto Color Emoji',
		].join(),
		h1: {
			lineHeight: '1.25',
			...f1,
			fontWeight: 700,
		},
		h2: {
			lineHeight: '1.15',
			...f2,
			fontWeight: 700,
		},
		h3: {
			lineHeight: '1.05',
			...f3,
			fontWeight: 600,
		},
		h4: {
			...f4,
			fontWeight: 600,
		},
		h5: {
			...f4,
			fontWeight: 600,
		},
		h6: {
			...f5,
			fontWeight: 600,
		},
		subtitle1: {
			...f6,
			fontWeight: 500,
		},
		subtitle2: {
			...f6,
			fontWeight: 400,
		},
		body1: {
			...f4,
			fontWeight: 300,
		},
		body2: {
			...f5,
		},
		overline: {
			lineHeight: 1,
			...f6,
		},
	},
	components: {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					padding: 0,
					fontWeight: '400',
					backgroundColor: 'transparent',
					color: '#7c6c4f',
				},
			},
		},

		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					'&.MuiBreadcrumbs-root': {
						'& .MuiBreadcrumbs-li:last-of-type': {
							'& .MuiLink-root': {
								textDecoration: 'none',
								fontWeight: '500',
							},
						},
					},
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					borderRadius: '5px',
					color: '#000',
					fill: '#000',
				},
			},
		},

		MuiSelect: {
			defaultProps: {
				disableUnderline: true,
			},
		},

		MuiTextField: {
			styleOverrides: {
				root: {
					'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
						display: 'none',
					},
					'& input[type=number]': {
						MozAppearance: 'textfield',
					},
				},
			},
			defaultProps: {
				// InputProps: {
				// 	disableUnderline: true,
				// },
			},
		},

		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					minHeight: '44px',
					fontSize: '16px',
					letterSpacing: '0.05em',
					px: '1.5rem',
					py: '0.5rem',
					width: '100%',
					borderWidth: 0,
					borderRadius: '5px',
					fontWeight: '500',
					textTransform: 'capitalize',

					'&.MuiButton-containedPrimary': {
						'&[disabled]': {
							background: '#ccc',
							color: '#888',
						},
					},
				},
				outlined: {
					minHeight: 0,
				},
				sizeSmall: {
					width: 'min-content',
					minHeight: 0,
				},
			},
		},

		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					minWidth: 'min-content',
					whiteSpace: 'nowrap',
				},
			},
		},

		MuiCheckbox: {
			styleOverrides: {
				root: {},
			},
		},

		MuiFormLabel: {
			styleOverrides: {
				asterisk: { color: 'red' },
			},
		},
	},

	shape: {
		borderRadius: 5,
	},
});
