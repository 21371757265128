import * as yup from 'yup';

export default function part2ReportFields(fields = {}) {
	const { h, i, j, k, l } = fields;

	return {
		h: [h, yup.number().min(0, 'Vodka value cannot be less than 0')],
		i: [
			i,
			yup
				.number()
				.min(
					0,
					'Alcohol and Spirits 190° and Over value cannot be less than 0',
				),
		],
		j: [
			j,
			yup
				.number()
				.min(0, 'Alcohol and Spirits Under 190° value cannot be less than 0'),
		],
		k: [k, yup.number().min(0, 'Other value cannot be less than 0')],
		l: [l, yup.number().min(0, 'Wine value cannot be less than 0')],
	};
}
