import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import { useCompanyId, useCompanyRoles, useCompanyTeams } from '@pw/redux/containers/User/hooks';
import { upsertUserThunk } from '@pw/redux/thunks/user';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function User({ user, onClose }) {
  const dispatch = useDispatch();
  
  const teams = useCompanyTeams();
  const roles = useCompanyRoles();

  const company_id = useCompanyId();

  const onSubmit = useCallback(
    async (values) => {
      dispatch(upsertUserThunk({
        company_id,
        corporate: true,
        ...values,
      }))
        .unwrap().then(onClose);
    },
    [onClose],
  );

  const teamOptions = useMemo(() => {
    return [...teams.map((team) => ({
      label: team.path.replace('/t/', ''),
      value: team.path,
    }))];
  }, [teams]);

  const roleOptions = useMemo(() => {
    return [...roles.map((role) => ({
      label: role.name ?? role.path.replace('/r/', ''),
      value: role.path,
    }))];
  }, [roles]);

  const changeSet = {
    // User details
    name: [user?.name ?? '', yup.string().required('Name is required!')],
    email: [user?.email ?? '', yup.string().email().required('Email is required!')],
    role: [user?.role_id ?? '', yup.string()],
    team: [user?.team_id ?? '', yup.string()],
  };

  return (
    <FormikForm changeSet={changeSet} onSubmit={onSubmit}>
      <Stack className="form" spacing="2rem">
        <H5>User</H5>
        <FormikTextField name="name" label="Full name" fullWidth required />
        <FormikTextField name="email" label="Email" required />
        <FormikSelect name="role" label="Role" options={roleOptions} />
        <FormikSelect name="team" label="Team" options={teamOptions} />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={onClose}
            color='secondary'
            label='Cancel'
          />

          <FilledButton type='submit' size='small'>
            Invite
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default User;
