import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

export default function ProductionOfBrandyReport({
	name,
	title = 'Grape Brandy',
	disabled = false,
	specify = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='a'
					label='Kind'
					// required
					fullWidth
					disabled={!specify}
				/>
				<FormikTextField
					name='b'
					label='Proof Gallons'
					// required
					fullWidth
					disabled={disabled}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
