import { Position } from './CustomSmoothStepEdge';
import { CustomHandle } from './Handles';

const ProcessToProcessHandles = ({ type, ...rest }) => (
  <>
    {/* {type === 'target' && ( */}
    <CustomHandle
      className="top"
      id={`handle-top-${type}`}
      type={type}
      position={Position.Top}
      {...rest}
    />
    {/* )} */}

    <CustomHandle
      className="right"
      id={`handle-right-${type}`}
      type={type}
      position={Position.Right}
      {...rest}
    />

    {/* {type === 'source' && ( */}
    <CustomHandle
      className="bottom"
      id={`handle-bottom-${type}`}
      type={type}
      position={Position.Bottom}
      {...rest}
    />
    {/* )} */}

    <CustomHandle
      className="left"
      id={`handle-left-${type}`}
      type={type}
      position={Position.Left}
      {...rest}
    />
  </>
)

export default ProcessToProcessHandles;