import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Client from '@pw/components/Client';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestID from '@pw/components/RequestID';
import { useAccountChain, useAccountPlatform } from '@pw/redux/containers/User';
import { upsertRequestThunk } from '@pw/redux/thunks/request';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import { useDispatch } from 'react-redux';

function RequestForm({
	id,
	requestType,
	entity,
	title,
	changeSet,
	readonly = false,
	edit = false,
	onCancel,
	onBeforeSave,
	onSave,
	children,
}) {
	const dispatch = useDispatch();

	const platform = useAccountPlatform();
	const chain = useAccountChain();
	const sameCompany = useCheckCompany(entity);

	const handleSubmit = async (values) => {
		// if (sameCompany) {
		// 	downloadJson(values?.properties, 'form-data.json');
		// 	returnl
		// }

		const updated = onBeforeSave ? onBeforeSave(values) : values;

		console.log('Submitting', updated);
		dispatch(upsertRequestThunk({
			...entity,
			...updated,
			request_type: requestType,
			chain,
			platform,
		}))
			.unwrap()
			.then((response) => onSave(response));
	};

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			enableReinitialize
			readonly={readonly}
			edit={edit}
		>
			<FormWrapper>
				<RequestID type={requestType} title={title} label='ID.' />

				{children}

				{!readonly && (
					<Client name='client' />
				)}

				{!readonly && (
					<>
						<Errors />

						<Box className='action-buttons'>
							{onCancel && (
								<TextButton
									size='small'
									handleClick={onCancel}
									color='secondary'
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small'>
								{id?.length ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</>
				)}
			</FormWrapper>
		</FormikForm>
	);
}

export default RequestForm;
