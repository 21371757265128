import React, { memo } from 'react';
import { useEdges, getConnectedEdges } from 'reactflow';

import Handles from './Handles';
import ProcessToProcessHandles from './ProcessToProcessHandles';
import Box from '@mui/material/Box';
import styles from './Node.styles';
import { Add } from '@mui/icons-material';
import { iconFromName } from '../nodes';
import { ButtonBase } from '@mui/material';

export default memo(({ data, id, className, dragging, selected, ...rest }) => {
  const edges = useEdges();
  const connectedEdges = getConnectedEdges([{ data, id }], edges);

  const { icon = null, label } = data;
  const Icon = iconFromName(icon);

  return (
    <Box
      className={`node-root ${data.category}-node 
      ${selected ? 'node-selected' : ''}
      ${className || ''}`}
      sx={styles}
      id={id}
    >

      <Box className="node-content">
        <Box className="icon-label-group">
          <Box className="icon-container">
            {Icon}
          </Box>
          <Box className="label">
            {label}
          </Box>
        </Box>

        <Box className="more">
          <ButtonBase className="more-button">
            <Add />
          </ButtonBase>
        </Box>
      </Box>

      <Handles
        type={['observation', 'operation'].includes(data.category)
          ? 'target' : 'source'}
        // type="target"
        isConnectableStart={true}
        connectedEdges={connectedEdges}
        nodeId={id}
      />

      {/* gives us the TL TR BR BL handles for material -> process */}
      {data.category === 'process' && (
        <ProcessToProcessHandles
          type="source"
          isConnectableEnd={true}
          connectedEdges={connectedEdges}
          nodeId={id}
        />
      )}
    </Box>
  );
});
