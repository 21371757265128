import { useMemo } from "react";
import { Box } from "@mui/material";
import { H6 } from "../../Typography";
import { iconFromName } from '../nodes';

import styles from './Tile.styles';

const Tile = ({ className, ...data }) => {
  const { label, icon,
    // type, variants
  } = data;

  const Icon = useMemo(() => iconFromName(icon)
    , [icon]);

  return (
    <Box
      sx={styles}
      className={`tile-root ${className || ''}`}
    >
      <Box className="icon-container">
        {Icon}
      </Box>
      <H6 className="heading label">{label}</H6>
    </Box>
  );
}

export default Tile;
