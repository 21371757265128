import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withDialogCard from '@pw/components/Cards/DialogCard';
import ExplorerLocation from '@pw/components/ExplorerLocation';
import { explorerLocationFields } from '@pw/components/ExplorerLocation/explorerLocationFields';
import ExplorerTable from '@pw/components/ExplorerTable';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { paginationFields } from '@pw/components/Pagination/paginationFields';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';

function Explorer() {
	const changeSet = {
		location: explorerLocationFields({}),
		...paginationFields('12'),
	};
	const facilities = useCompanyFacilities();

	usePageTitleHook('Explorer');

	return (
		<Box sx={styles} className='root'>
			<FormikForm changeSet={changeSet}>
				<Stack spacing={3}>
					<ExplorerLocation
						name='location'
						label='Location'
						facilities={facilities}
					/>
					<ExplorerTable />
				</Stack>
			</FormikForm>
		</Box>
	);
}

export default withAppLayout(withDialogCard(Explorer));
