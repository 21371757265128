/* eslint-disable react/prop-types */
import { AddCircle, PendingActions, QrCode2 } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import CachedIcon from '@mui/icons-material/Cached';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import {
	DataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';
import formatCompanyAddress from '@pw/components/Administration/formatCompanyAddress';
import { QrScannerDialog } from '@pw/components/ScanQR';
import { Status } from '@pw/consts/admin';
import { CLIENT_TYPE } from '@pw/consts/contacts';
import { useCompanyId, useCompanyPartners } from '@pw/redux/containers/User';
import {
	refetchPartnersThunk,
	upsertPartnerThunk,
} from '@pw/redux/thunks/partners';
import useModalState from '@pw/utilities/hooks/components/useModalState';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import match from '@pw/utilities/match';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GridToolbarButton } from '../Owners/components/GridToolbarButton';
import { ModalWithClose } from '../../../Dialogs/ModalWithClose';
import OwnerAssets from '../Owners/components/OwnerAssets';
import { ConfirmPartnersInfoDialog } from './components/ConfirmPartnersInfoDialog';
import { InvitePartnerDialog } from './components/InvitePartnerDialog';
import PartnerForm from './components/PartnerForm';
import { ShareWithPartnerDialogQR } from './components/ShareWithPartnerDialogQR';

const MODALS = {
	edit_partner: 'edit_partner',
	qr_scan: 'qr_scan',
	approval: 'approval',
	share: 'share',
	invite: 'invite',
	view_partner_assets: 'view_partner_assets',
};

const CustomToolbar = forwardRef(function CustomToolbar(
	{
		showQuickFilter = false,
		quickFilterProps = {},
		onClickRefetch,
		openModal,
		...other
	},
	ref,
) {
	const isMobile = useIsMobile();
	// const rootProps = useGridRootProps();
	return (
		<GridToolbarContainer ref={ref} {...other}>
			{!!isMobile && <GridToolbarColumnsButton />}
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			<GridToolbarButton
				button={{
					children: 'refetch',
					onClick: onClickRefetch,
					startIcon: <CachedIcon />,
				}}
				tooltip={{ title: 'refetch' }}
			/>
			<GridToolbarButton
				button={{
					children: 'Share',
					onClick: () => openModal(MODALS.share, {}),
					startIcon: <QrCode2 />,
				}}
				tooltip={{ title: 'Share' }}
			/>
			<GridToolbarButton
				button={{
					children: 'Scan',
					onClick: () => openModal(MODALS.qr_scan, {}),
					startIcon: <QrCodeScannerIcon />,
				}}
				tooltip={{ title: 'Scan' }}
			/>
			<GridToolbarButton
				button={{
					children: 'Invite',
					onClick: () => openModal(MODALS.invite, {}),
					startIcon: <ScheduleSendIcon />,
				}}
				tooltip={{ title: 'Invite' }}
			/>
			<GridToolbarButton
				button={{
					children: 'add',
					onClick: () => openModal(MODALS.edit_partner, {}),
					startIcon: <AddCircle />,
				}}
				tooltip={{ title: 'add' }}
			/>
			<Box sx={{ flex: 1 }} />
			{showQuickFilter && <GridToolbarQuickFilter {...quickFilterProps} />}
		</GridToolbarContainer>
	);
});

function Partners() {
	const dispatch = useDispatch();
	// selectors
	const company_id = useCompanyId();
	// states
	const [searchParams, setSearchParams] = useSearchParams();
	const { modal, openModal, closeModal } = useModalState();

	// apis
	const partners = useCompanyPartners();

	// callback
	const handleForceRefetch = useCallback(() => {
		dispatch(refetchPartnersThunk({force:true}));
	}, [dispatch]);

	const onScan = useCallback(
		({ text }) => {
			const urlObj = new URL(text);
			const company_id = urlObj.searchParams.get('qq');
			const partner_id = urlObj.searchParams.get('q');
			dispatch(upsertPartnerThunk({ company_id, partner_id }))
				.unwrap()
				.then((data) => openModal(MODALS.approval, data));
		},
		[dispatch, openModal],
	);

	const columns = useMemo(
		() => [
			{
				field: 'status',
				headerName: 'Status',
				width: 70,
				renderCell: (params) => {
					return match(params.row.status, {
						[Status.APPROVED]: () => <BusinessIcon />,
						[Status.PENDING]: () => <PendingActions />,
						[Status.REJECTED]: () => <DomainDisabledIcon />,
						_: () => null,
					});
				},
			},
			{
				field: 'company_name',
				headerName: 'Name',
				width: 200,
				filterable: true,
			},
			{
				field: 'address',
				headerName: 'Address',
				width: 200,
				filterable: true,
				renderCell: (params) => formatCompanyAddress(params.row),
			},
			{
				field: 'created',
				headerName: 'Created',
				width: 160,
				filterable: true,
				renderCell: (params) => {
					return new Date(params.row.created).toLocaleString();
				},
			},
			{
				field: 'actions',
				headerName: 'Actions',
				width: 200,
				renderCell: (params) => (
					<BasicMenu
						menuOptions={[
							{
								title: 'Edit',
								onClick: () => openModal(MODALS.edit_partner, params.row),
							},
							{
								title: 'Confirm pending',
								onClick: () => openModal(MODALS.approval, params.row),
								disabled:
									params.row.status !== Status.APPROVED &&
									`/cc/${company_id}` === params.row.id,
							},
							{
								title: 'Share qr code',
								onClick: () => openModal(MODALS.share, params.row),
								disabled: params.row?.my_partner_id,
							},
							{
								title: 'Invite company',
								onClick: () => openModal(MODALS.invite, params.row),
								disabled: params.row?.my_partner_id,
							},
							{
								title: 'View partner assets',
								onClick: () =>
									openModal(MODALS.view_partner_assets, params.row),
								disabled: !params?.row?.company_id,
							},
						]}
					/>
				),
			},
		],
		[company_id, openModal],
	);

	// effects
	useEffect(() => {
		const company_id = searchParams.get('qq');
		const partner_id = searchParams.get('q');
		if (company_id)
			dispatch(upsertPartnerThunk({ company_id, partner_id }))
				.unwrap()
				.then((data) => openModal(MODALS.approval, data))
				.finally(() => setSearchParams({}));
	}, []);

	return (
		<Card>
			<CardHeader
				title='Manage Partners'
				subheader='Manage Partners who have access to assets managed by your organization.'
			/>
			<CardContent>
				<Box style={{ height: '69vh', width: '100%' }}>
					<DataGrid
						autoPageSize
						rows={partners ?? []}
						columns={columns}
						getRowId={(row) => row.path}
						disableSelectionOnClick
						slots={{ toolbar: CustomToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								onClickRefetch: handleForceRefetch,
								openModal,
							},
						}}
						sx={{
							'& .MuiDataGrid-columnHeaders': {
								position: 'sticky',
								top: 0,
								backgroundColor: '#f4f4f4',
								zIndex: 1,
							},
						}}
					/>
				</Box>
				{modal.fold({
					[MODALS.edit_partner]: (state) => (
						<ModalWithClose open={!!state.data} onClose={() => closeModal()}>
							<PartnerForm
								partner={state.data}
								readonly={
									state?.data?.status &&
									[Status.REJECTED, Status.PENDING].includes(
										state?.data?.status,
									)
								}
								onCancel={closeModal}
								onSubmit={closeModal}
							/>
						</ModalWithClose>
					),
					[MODALS.invite]: (state) => (
						<InvitePartnerDialog
							open={!!state?.data}
							onClose={() => closeModal()}
							partnerId={state?.data?.partner_id ?? ''}
						/>
					),
					[MODALS.share]: (state) => (
						<ShareWithPartnerDialogQR
							open={!!state?.data}
							onClose={() => closeModal()}
							partnerId={state?.data?.partner_id ?? ''}
						/>
					),
					[MODALS.approval]: (state) => (
						<ConfirmPartnersInfoDialog
							open={state?.data}
							onClose={() => {
								closeModal();
							}}
						/>
					),
					[MODALS.qr_scan]: () => (
						<QrScannerDialog
							closeDialog={() => closeModal()}
							closeOnSuccess
							onSuccess={onScan}
						/>
					),
					[MODALS.view_partner_assets]: (state) => (
						<ModalWithClose open={!!state.data} onClose={() => closeModal()}>
							<OwnerAssets
								type={CLIENT_TYPE.COMPANY}
								id={state.data?.account_id}
								title='Partner Assets'
							/>
						</ModalWithClose>
					),
				})}
			</CardContent>
		</Card>
	);
}

export default Partners;
