import {
	createRequest,
	migrateRequest,
	updateRequest,
	updateRequestAdmin,
	updateRequestAssign,
} from '@pw/services/request.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertRequestThunk = createAsyncThunk(
	'request/upsert',
	async (req, { rejectWithValue }) => {
		try {
			return req.path ? await updateRequest(req) : await createRequest(req);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const migrateRequestThunk = createAsyncThunk(
	'request/migrate',
	async (req, { rejectWithValue }) => {
		try {
			return await migrateRequest(req);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestApproveRejectThunk = createAsyncThunk(
	'request/admin',
	async (req, { rejectWithValue }) => {
		try {
			return await updateRequestAdmin(req);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestAssignThunk = createAsyncThunk(
	'request/assign',
	async (req, { rejectWithValue }) => {
		try {
			return await updateRequestAssign(req);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestCancelThunk = createAsyncThunk(
	'request/cancel',
	async (req, { rejectWithValue }) => {
		try {
			return await updateRequestAdmin(req);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestArchiveThunk = createAsyncThunk(
	'request/archive',
	async (req, { rejectWithValue }) => {
		try {
			return await updateRequestAdmin(req);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);
