import { ACCOUNT_STATUS } from '@pw/consts/account';
import { SKU_TYPES } from '@pw/consts/sku';
import getPermissions from '@pw/utilities/getPermissions';
import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';

// Selectors
export const activeUser = (state) => state[SLICE];
export const selectAccountId = createSelector(activeUser, (user) => user?.account_id);
export const selectAccountIdentity = createSelector(activeUser, (user) => user?.account_identity);
export const selectAccountName = createSelector(activeUser, (user) => user?.account_name);
export const selectAccountUsername = createSelector(activeUser, (user) => user?.account_username);
export const selectAccountEmail = createSelector(activeUser, (user) => user?.account_email);
export const selectAccountLocation = createSelector(activeUser, (user) => user?.account_location);
export const selectAccountLastAccess = createSelector(activeUser, (user) => user?.account_last_access);
export const selectAccountStatus = createSelector(activeUser, (user) => user?.account_status);
export const selectUnitFormat = createSelector(activeUser, (user) => user?.unit_format);
export const selectAccountPerms = createSelector(activeUser, (user) => user?.account_perms);
export const selectAccountPlatform = createSelector(activeUser, (user) => user?.platform);
export const selectAccountChain = createSelector(activeUser, (user) => user?.chain);

export const selectIsAccountActive = createSelector(selectAccountStatus, (status) => status === ACCOUNT_STATUS.ACTIVE);

// List of companies the user is associated with
export const selectAllCompanies = createSelector(activeUser, (user) => user?.companies);

// Get the current active company
export const activeCompany = createSelector(activeUser, (user) => user?.company);

export const selectCompanyId = createSelector(activeCompany, (company) => company?.company_id);

export const selectCompanyName = createSelector(activeCompany, (company) => company?.company_name);

export const selectCompanyCC = createSelector(activeCompany, (company) => company?.company_cc);

export const selectIsCompanyActive = createSelector(activeCompany, () => true);

export const selectIsUsUser = createSelector(activeCompany, (company) => ['US'].includes(company?.company_cc));

export const selectCanEdit = createSelector(
	selectIsCompanyActive,
	selectIsAccountActive,
	(companyActive, accountActive) => companyActive && accountActive,
);

export const selectPermissions = createSelector(
	selectCanEdit,
	selectAccountPerms,
	getPermissions,
);

export const selectCompanyUsers = createSelector(activeCompany, (company) => company?.users ?? []);

export const selectCompanySKUs = createSelector(activeCompany, (company) => company?.skus ?? []);

export const selectCompanyFacilities = createSelector(activeCompany, (companies) => companies?.facilities ?? []);

export const selectCompanyLiquidTypes = createSelector(selectCompanySKUs, (skus) => skus.filter((sku) => sku?.sku_type === SKU_TYPES.LIQUID));

export const selectCompanyRoles = createSelector(activeCompany, (company) => company?.roles ?? []);

export const selectCompanyPermissions = createSelector(activeCompany, (company) => company?.permissions ?? []);

export const selectCompanyTeams = createSelector(activeCompany, (company) => company?.teams ?? []);

export const selectBaseUnits = createSelector(activeUser, (user) => user?.units);

export const selectCompanySensors = createSelector(activeCompany, (company) => company?.sensors ?? []);

export const selectCompanyPartners = createSelector(activeCompany, (company) => company?.partners ?? []);

export const selectCompanyOwners = createSelector(activeCompany, (company) => company?.owners ?? []);
