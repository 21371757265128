import * as yup from 'yup';

export default function productionOfAlcoholFields(fields = {}) {
	const {
		Grain = '',
		Fruit = '',
		Molasses = '',
		EthylSulfate = '',
		EthyleneGas = '',
		SulphiteLiquors = '',
		FromRedistillation = '',
		Other_Specify = '',
	} = fields;

	return {
		Grain: [Grain, yup.string()],
		Fruit: [Fruit, yup.string()],
		Molasses: [Molasses, yup.string()],
		EthylSulfate: [EthylSulfate, yup.string()],
		EthyleneGas: [EthyleneGas, yup.string()],
		SulphiteLiquors: [SulphiteLiquors, yup.string()],
		FromRedistillation: [FromRedistillation, yup.string()],
		OtherSpecify: [Other_Specify, yup.string()],
	};
}
