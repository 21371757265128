import { Alert, AlertTitle } from '@mui/material';
import { useNotificationHook } from '@pw/redux/containers/App';
import { useCallback } from 'react';

// this can be used as standalone component
export function AlertView({ alert = null, ...props }) {
	if (!alert) return;

	return (
		<Alert severity={alert.severity} {...props}>
			{alert.title && <AlertTitle>{alert.title}</AlertTitle>}
			{alert.content}
		</Alert>
	);
}

// and also can be used as hook
const useAlertView = () => {
	const { error, setError } = useNotificationHook();

	// memoize the component
	const Component = useCallback((props) => <AlertView alert={error} {...props} />, [error]);

	return [Component, { setAlert: setError }];
};

export default useAlertView;
