import * as yup from 'yup';

function assetIdFields(params = {}) {
	const { rw_asset_id = '', asset_checkin_date = Date.now() } = params ?? {};
	return {
		rw_asset_id: [rw_asset_id, yup.string().required('Asset ID is required!')],
		asset_checkin_date: [asset_checkin_date, yup.number().required('Date is required!')],
	};
}
export default assetIdFields;
