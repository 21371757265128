import { Wifi, WifiOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { Body1 } from '@pw/components/Typography';
import { useOnlineHook } from '@pw/redux/containers/App/hooks';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import React from 'react';

/**
 * Renders a network switch component.
 *
 * @return {JSX.Element} The network switch component.
 */
export function NetworkSwitch() {
  const isMobile = useIsMobile();

  const { online, setOnlineState } = useOnlineHook();

  return !isMobile ? (
    <IconCircleButton
      onClick={() => setOnlineState(!online)}
    >
      {!online ? (
          <WifiOff color="error" />
        )
        : (
          <Wifi color="success" />
        )}
    </IconCircleButton>
  ) : null;
}

export function NetworkSwitchMobile() {
  const isMobile = useIsMobile();
  const { online, setOnlineState } = useOnlineHook();

  return isMobile ? (
    <Box
      flexDirection={'row'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Body1 className="text">{online ? 'Online' : 'Offline'}</Body1>
      <IconCircleButton
        onClick={() => setOnlineState(!online)}
      >
        {!online ? (
            <WifiOff color="error" />
          )
          : (
            <Wifi color="success" />
          )}
      </IconCircleButton>
    </Box>
  ) : null;
}
