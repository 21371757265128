import { UploadOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import NumberWidget from '@pw/components/Dashboard/components/NumberWidget';
import StatsWidget from '@pw/components/Dashboard/components/StatsWidget';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import Filter from '@pw/components/icons/Filter';
import { UNIT } from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useReportOverviewLazyQuery } from '@pw/utilities/hooks/service/useReportOverviewQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

function ReportWidget({ title }) {
	const navigate = useNavigate();
	const [fetchReportData, { data, isLoading }] = useReportOverviewLazyQuery();

	useEffect(() => {
		fetchReportData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpanded = () => setIsExpanded(!isExpanded);

	const converter = useConverter();

	const bl_unit = converter.unit(UNIT.BL);
	const la_unit = converter.unit(UNIT.LA);
	const {
		new_assets_count = 0,
		new_requests_count = 0,
		total_bl = 0,
		total_la = 0,
		total_la_in = 0,
		total_la_out = 0,
		total_bl_in = 0,
		total_bl_out = 0,
		total_bl_storage = 0,
		total_la_storage = 0,
		total_bl_operation = 0,
		total_la_operation = 0,
		start_date,
		end_date,
	} = data ?? {};

	const [tb, ta] = useMemo(() => {
		return [
			converter.from(total_bl ?? 0, UNIT.BL),
			converter.from(total_la ?? 0, UNIT.LA)
		];
	}, [total_bl, total_la]);

	const [li, lo] = useMemo(() => {
		return [
			converter.from(total_la_in ?? 0, UNIT.LA),
			converter.from(Math.abs(total_la_out ?? 0), UNIT.LA),
		];
	}, [total_la_in, total_la_out]);

	const [bi, bo] = useMemo(() => {
		return [
			converter.from(total_bl_in ?? 0, UNIT.BL),
			converter.from(Math.abs(total_bl_out ?? 0), UNIT.BL),
		];
	}, [total_bl_in, total_bl_out]);

	const [tbs, tls] = useMemo(() => {
		return [
			converter.from(total_bl_storage ?? 0, UNIT.BL),
			converter.from(total_la_storage ?? 0, UNIT.LA),
		];
	}, [total_bl_storage, total_la_storage]);

	const [tbo, tlo] = useMemo(() => {
		return [
			converter.from(total_bl_operation ?? 0, UNIT.BL),
			converter.from(total_la_operation ?? 0, UNIT.LA),
		];
	}, [total_bl_operation, total_la_operation]);

	const changeSet = useMemo(() => {
		return {
			start_date: [
				start_date,
				yup.number().min(0, 'Invalid date!').required('Date is required!'),
			],
			end_date: [
				end_date,
				yup.number().min(0, 'Invalid date!').required('Date is required!'),
			],
		};
	}, [start_date, end_date]);

	const onSubmit = useCallback(
		(values) => {
			console.log('values ', values);
			fetchReportData({
				start_date: values?.start_date,
				end_date: values?.end_date,
			});
		},
		[fetchReportData],
	);

	return (
		<Stack className='content-section'>
			<Stack>
				<FlexBox>
					<H5 className='content-section-title'>{title}</H5>
					<IconCircleButton onClick={toggleExpanded}>
						<Filter width='1.5em' height='1.5em' />
					</IconCircleButton>
				</FlexBox>
				{isExpanded && (
					<FormikForm changeSet={changeSet} onSubmit={onSubmit}>
						<Stack spacing={2} alignItems='top'>
							<FormikDatePicker
								name='start_date'
								label='Start Date'
								fullWidth
								required
							/>
							<FormikDatePicker
								name='end_date'
								label='End Date'
								fullWidth
								required
							/>
							<FilledButton
								label='Apply'
								loading={isLoading}
								type='submit'
								size='small'
							/>
						</Stack>
					</FormikForm>
				)}
			</Stack>
			<Stack className='content-section-content' spacing={1}>
				<H5>Summary</H5>
				<NumberWidget value={new_assets_count} label='Asset Count' />
				<NumberWidget value={new_requests_count} label='Request Count' />
				<H5>Totals</H5>
				<StatsWidget
					primary={{value: tb, unit: bl_unit}}
					secondary={{value: ta, unit: la_unit}}
					label='Total Liquid'
				/>
				<StatsWidget
					primary={{value: bi, unit: bl_unit}}
					secondary={{value: li, unit: la_unit}}
					label='Bulk In'
				/>
				<StatsWidget
					primary={{value: bo, unit: bl_unit}}
					secondary={{value: lo, unit: la_unit}}
					label='Bulk Out'
				/>
				<StatsWidget
					primary={{value: tbs, unit: bl_unit}}
					secondary={{value: tls, unit: la_unit}}
					label='Storage'
				/>
				<StatsWidget
					primary={{value: tbo, unit: bl_unit}}
					secondary={{value: tlo, unit: la_unit}}
					label='Operations'
				/>
			</Stack>

			<FlexBox className='content-section-footer'>
				<TitledButton
					handleClick={() => navigate(`/app/duty-submission`)}
					variant='outlined'
					color='secondary'
					label='Submit'
					// disabled={!isVisibleButton}
					sx={{ width: '72px', height: '55px' }}
				>
					<UploadOutlined height={24} width={24} />
				</TitledButton>
			</FlexBox>
		</Stack>
	);
}

export default ReportWidget;
