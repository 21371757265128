import Stack from '@mui/material/Stack';
import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { storageChargeCodeFields } from '@pw/components/ChargeCode/storageChargeCodeFields';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import SKUField from '@pw/components/SKUSelector/SKUField';
import skuFields from '@pw/components/SKUSelector/skuFields';
import templateFields from '@pw/components/TemplateHandler/templateFields';
import { Body2, H5, H6 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useAccountPermissions } from '@pw/redux/containers/User/hooks';
import processLiquid from '@pw/utilities/adapters/processLiquid';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}, converter) {
	const {
		ibc: tags = [],
		imported = false,
		duty_paid = false,
		liquid = {},
		sku_id = '',
		sku = {},
	} = fields;

	return {
		ibc: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		imported: [imported, yup.boolean()],
		duty_paid: [duty_paid, yup.boolean()],
		liquid: liquidPropertyFields(liquid, converter),
		...skuFields(sku_id, sku),
	};
}

function Properties() {
	const { editLiquid = false } = useAccountPermissions();
	const filter = (item) => item?.asset_type === ASSET_TYPES.ibc;

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>IBC Properties</H5>
				<SKUField
					title='Type'
					skuTypes={[SKU_TYPES.TRACKED]}
					tags='ibc'
					itemFilter={filter}
				/>
				<TagSelect name='ibc' type='ibc' />
				<Stack className='section'>
					<H6 className='section-title'>Liquid Status</H6>
					<FlexBox justifyContent='space-between'>
						<FormikCheckBox name='imported' label={<Body2>Imported</Body2>} />
						<FormikCheckBox name='duty_paid' label={<Body2>Duty Paid</Body2>} />
					</FlexBox>
				</Stack>
			</Stack>
			<LiquidProperties name='liquid' disabled={!editLiquid} />
		</FormikProvider>
	);
}

function IBCForm(props) {
	const converter = useConverter();

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			...skuFields(initialValues.sku_id, initialValues.sku),
			location: locationFields(initialValues?.location),
			properties: propertyFields(initialValues.properties, converter),
			accounts: assetAccountsFields(initialValues.accounts),
			charge_code: storageChargeCodeFields(initialValues?.charge_code),
		}),
		[converter],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
			unique_location_id: toUniqueLocation(entity.location),
		};
	};

	return (
		<AssetForm
			assetLabel='IBC'
			assetType={ASSET_TYPES.ibc}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<SKUField title='Marketplace Product' skuTypes={[SKU_TYPES.WIP]} />

			<Location name='location' />

			<Properties />

			<AssetAccounts />
		</AssetForm>
	);
}

export default withAppLayout(IBCForm, { title: 'IBC Management' });
