import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import ScanQR from '@pw/components/ScanQR';
import SearchSKU from '@pw/components/Search/SearchSKU';
import SelectedSKU from '@pw/components/SKUSelector/SelectedSKU';
import { Body3, H5, H6 } from '@pw/components/Typography';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

function SKUSelector({
	title = null,
	sectionTitle = null,
	list,
	onAdd,
	onEdit,
	onRemove,
	onQrScan,
	display,
	itemFilter = (x) => x,
	filter = [],
	single = false,
	summary = true,
}) {
	const { enqueueSnackbar } = useSnackbar();

	const selectSku = useCallback(
		(sku) => {
			console.log('Selected sku', sku);
			if (
				list.find((i) => i.path === sku.path) ||
				(single && list.length > 0)
			) {
				enqueueSnackbar('SKU already added!', { variant: 'info' });
				return false;
			}
			onAdd(sku);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[enqueueSnackbar, list, onAdd, single],
	);

	return (
		<Stack className='section'>
			{title && <H5 className="section-header">{title}</H5>}
			{sectionTitle && <H6 className="section-title">{sectionTitle}</H6>}

			<Collapse in={!(single && list.length === 1)} unmountOnExit>
				<SearchSKU
					title={title}
					sectionTitle={sectionTitle}
					label='SKU Name'
					onItemClick={selectSku}
					disabled={single && list.length === 1}
					filter={filter}
					itemFilter={itemFilter}
				/>
			</Collapse>
			{list.length > 0 && (
				<Box className='inventory'>
					{!!onQrScan && (
						<FlexBox className='inventory-header'>
							<ScanQR
								onSuccess={onQrScan}
								closeOnSuccess
								variant='outlined'
								color='secondary'
								sx={{ width: '72px', height: '55px' }}
								width={24}
								height={24}
								withLabel
								Component={TitledButton}
							/>
						</FlexBox>
					)}
					<Stack spacing={0} className='list'>
						{list.map((sku) => (
							<SelectedSKU
								item={sku}
								onEdit={onEdit}
								onRemove={onRemove}
								key={sku.path ?? sku.sku_id}
								display={display}
								disabled={true}
							/>
						))}
					</Stack>
					{summary && (
						<FlexBox className='inventory-footer'>
							<Stack spacing={0.75}>
								<AmountDisplay label='Items' amount={list.length} />
							</Stack>
						</FlexBox>
					)}
				</Box>
			)}
			{list.length === 0 && <Body3>None</Body3>}
		</Stack>
	);
}

export default SKUSelector;
