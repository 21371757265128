import { SKU_TYPE_REVERSE, SKU_TYPES } from '@pw/consts/sku';

export const getSkuAttrs = (sku) => {
  return [sku?.sku_id, sku?.sku_name, sku?.sku_description, SKU_TYPE_REVERSE[sku?.sku_type ?? SKU_TYPES.TRACKED]].join('|').toLowerCase();
};

export const getAssetAttrs = (asset) => {
  return [asset?.rw_asset_id, asset?.sku?.sku_name, asset?.properties?.sku?.sku_name, asset?.properties?.reference, asset?.properties?.liquid?.sku?.sku_name].join('|').toLowerCase();
};

export const getAccountAttrs = (item) => {
  return [item?.name, item?.public_name].join('|').toLowerCase();
};
