import { Print } from '@mui/icons-material';
import { Box } from '@mui/material';
import useUSCompliance from '@pw/compliance/cc/useUSCompliance';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKULocatorQRCode from '@pw/components/QRImage/SKULocatorQRCode';
import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS } from '@pw/consts/units';
import { useCompanySKUs, useCurrentCompany } from '@pw/redux/containers/User';
import convertStyleObjectToCSS from '@pw/utilities/convertStyleObjectToCSS';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import printElement from '@pw/utilities/printElement';
import { useSnackbar } from 'notistack';
import { useMemo, useRef } from 'react';
import Barcode from 'react-barcode';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.style';

const NABCALabel = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const converter = useConverter();
  const compliance = useUSCompliance();
  const liquidTypes = useLiquidTypes();

  const company = useCurrentCompany();
  const skuList = useCompanySKUs();

  const printRef = useRef();

  const { sku_id, labels, release: release_id } = location.state ?? {};

  if (!sku_id || !labels || !release_id) {
    enqueueSnackbar('No release specified!', { variant: 'error' });
    navigate('/admin/skus/finished');
  }

  const sku = skuList.find((s) => s.sku_id === sku_id);
  const properties = sku?.properties;

  if (!sku || sku.sku_type !== SKU_TYPES.FINISHED || !properties) {
    enqueueSnackbar('Invalid SKU!', { variant: 'error' });
    navigate(`/admin/skus/finished/${sku_id}`);
  }

  const release = properties?.releases?.find((r) => r.id === release_id);
  if (!release) {
    enqueueSnackbar('Invalid Release!', { variant: 'error' });
    navigate(`/admin/skus/finished/${sku_id}`);
  }

  console.log('Company', company);
  const dsp = company?.company_compliance?.excise_id;
  const name = company?.company_name;

  const {
    prefix = '',
    nabca = '',
    suffix = '',
    upc_code = '',
    scc_code = '',
    count = '',
    sc_codes = [],
    size,
    abv,
  } = properties;

  let stateNC = null;
  let statePA = null;
  let stateOthers = [];

  sc_codes.length > 0 &&
  sc_codes.forEach((item) => {
    if (item.state === 'NC') {
      stateNC = item;
    } else if (item.state === 'PA') {
      statePA = item;
    } else {
      stateOthers.push(item);
    }
  });

  const filledDate = (new Date(release?.date))?.toLocaleDateString();
  const sizeML = Number(converter.cx(size?.value, size?.unit, UNITS.milliliters)).toFixed(0);
  const proof = Number(converter.cx(abv?.value, abv?.unit, UNITS.proof)).toFixed(0);

  const liquid = liquidTypes.find((l) => l.value === release?.liquid_type);
  const taxCode = compliance.getTaxCode(liquid, abv);
  console.log('Kind', liquid, abv, taxCode);
  const lcs = Number(sizeML && count ? converter.cx(sizeML * count, UNITS.milliliters, UNITS.liters) : 0).toFixed(2);
  const pgcs = Number(converter.cx(lcs, UNITS.liters, UNITS.proof_gallons))?.toFixed(2);

  const qrParams = useMemo(() => {
    return {
      p: {
        n: [prefix, nabca, suffix],
        u: upc_code,
        s: scc_code,
        c: sc_codes,
      },
      s: sku_id,
      n: release?.name,
      r: release_id,
      c: company?.company_id,
      y: company?.company_name,
      d: company?.company_compliance?.excise_id,
      f: filledDate,
      m: sizeML,
      a: proof,
      b: count,
      l: lcs,
      g: pgcs,
      k: taxCode?.name,
    };
  }, [properties, company, release, taxCode]);

  const handlePrint = (ref) => {
    const element = ref?.current?.innerHTML;
    printElement({ element, styles: convertStyleObjectToCSS(styles) });
  };

  return (
    <Box sx={styles} className="root">
      <FlexBox justifyContent="flex-end" sx={{ marginBottom: 2 }}>
        <TitledButton
          handleClick={() => handlePrint(printRef)}
          variant="outlined"
          color="secondary"
          label="Print"
          sx={{ width: '72px', height: '55px' }}
        >
          <Print height={24} width={24} />
        </TitledButton>
      </FlexBox>
      <Box ref={printRef} sx={{ display: 'grid', gap: '10px' }}>
        {labels &&
          Array.from({ length: labels }, (_, index) => (
            <div
              key={index}
              className="container"
            >
              <div className="label">
                {/* First row */}
                <div className="long" style={{ gridColumn: '1 / span 4', gridRow: '1 / span 2' }}>{release?.name}</div>
                <div className="nabcaCode" style={{ gridColumn: '1 / span 4', gridRow: '3 / span 4' }}>
                  <div className="prefix">{prefix}</div>
                  <div className="code">{nabca}</div>
                  <div className="suffix"> {suffix}</div>
                </div>
                <div className="stateCode_b" style={{ gridColumn: '5 / span 2', gridRow: '1 / span 3' }}>
                  {stateOthers[0] && (
                    <span>{stateOthers[0]?.state}-{stateOthers[0]?.code}</span>
                  )}
                </div>
                <div className="stateCode_w" style={{ gridColumn: '5 / span 2', gridRow: '4 / span 3' }}>
                  {stateOthers[1] && (
                    <span>{stateOthers[1]?.state}-{stateOthers[1]?.code}</span>
                  )}
                </div>
                {/* Second row */}
                <div className="stateCode_b" style={{ gridColumn: '1 / span 2', gridRow: '7 / span 3' }}>
                  {stateOthers[2] && (
                    <span>{stateOthers[2]?.state}-{stateOthers[2]?.code}</span>
                  )}
                </div>
                <div className="stateCode_w" style={{ gridColumn: '3 / span 2', gridRow: '7 / span 3' }}>
                  {stateOthers[3] && (
                    <span>{stateOthers[3]?.state}-{stateOthers[3]?.code}</span>
                  )}
                </div>
                <div className="stateCode_b" style={{ gridColumn: '5 / span 2', gridRow: '7 / span 3' }}>
                  {stateOthers[4] && (
                    <span>{stateOthers[4]?.state}-{stateOthers[4]?.code}</span>
                  )}
                </div>
                {/* Third row */}
                <div className="upcBarcode" style={{ gridColumn: '1 / span 2', gridRow: '10 / span 6' }}>
                  <Barcode
                    width={1}
                    height={80}
                    fontSize={10}
                    value={upc_code}
                    format="UPC"
                  />
                </div>

                <div className="stateCode_b" style={{ gridColumn: '3 / span 2', gridRow: '10 / span 3' }}>
                  {stateOthers[5] && (
                    <span>{stateOthers[5]?.state}-{stateOthers[5]?.code}</span>
                  )}
                </div>
                <div className="stateCode_w" style={{ gridColumn: '3 / span 2', gridRow: '13 / span 3' }}>
                  {statePA && (
                    <span>
											<span>
												{statePA?.state}-
											</span>
                      {statePA?.code}
										</span>
                  )}
                </div>

                <div className="stateCode_w" style={{ gridColumn: '5 / span 2', gridRow: '10 / span 3' }}>
                  {stateOthers[6] && (
                    <span>{stateOthers[6]?.state}-{stateOthers[6]?.code}</span>
                  )}
                </div>
                <div className="stateCode_b" style={{ gridColumn: '5 / span 2', gridRow: '13 / span 3' }}>
                  {stateNC && (
                    <span>{stateNC?.state}-{stateNC?.code}</span>
                  )}
                </div>
                {/* Fourth row */}
                <div className="upcBarcode" style={{ gridColumn: '1 / span 6', gridRow: '16 / span 6' }}>
                  <Barcode value={scc_code} format="ITF" />
                </div>
                {/* Fifth row */}
                <div className="upcBarcode" style={{ gridColumn: '1 / span 3', gridRow: '22 / span 7' }}>
                  <SKULocatorQRCode params={{ ...qrParams, i: index + 1 }} type='n' />
                </div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '22' }}>{`Serial Number: `}<strong>{index + 1}</strong>
                </div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '23' }}>{`Date Filled: `}<strong>{filledDate}</strong>
                </div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '24' }}>{'Btl Size: '}<strong>{`${sizeML} mL`}</strong>
                </div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '25' }}>{`Btls/CS: `}<strong>{count}</strong></div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '26' }}>{'L/CS: '}<strong>{`${lcs} L`}</strong></div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '27' }}>{`Proof: `}<strong>{proof}</strong></div>
                <div className="long"
                     style={{ gridColumn: '4 / span 3', gridRow: '28' }}>{`PG/CS: `}<strong>{pgcs}</strong></div>
                {/* Sixth row */}
                <div className="long" style={{ gridColumn: '1 / span 6', gridRow: '29' }}>
                  {`Kind of Spirits: ${taxCode?.name}`}
                </div>
                <div className="long" style={{ gridColumn: '1 / span 6', gridRow: '30' }}>
                  {`${dsp}, ${name}`}
                </div>
              </div>
            </div>
          ))}
      </Box>
    </Box>
  );
};

export default NABCALabel;
