const styles = {
  '&.load-button-root': {
    // width: '100%',
    marginTop: '1rem',

    '.MuiSelect-filled': {
      paddingTop: '0.5rem',
      borderRadius: '0.25rem',
      backgroundColor: '#C7DA65',
      fontWeight: 500
    },


    // '> *': { width: '100%', },
    // '.MuiButton-root': {
    //   width: 'calc(100% - 1rem)',
    //   margin: '0.5rem 0.5rem 0',
    //   backgroundColor: 'white'
    // },
  },
};

export default styles;
