import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Address from '@pw/components/Company/Address';
import CompanyMemberships from '@pw/components/Company/CompanyMemberships';
import CompanySettings from '@pw/components/Company/CompanySettings';
import CompanyType from '@pw/components/Company/CompanyType';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import { useAccountLocation } from '@pw/redux/containers/User/hooks';
import { createCompanyThunk } from '@pw/redux/thunks/company';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function Company({ reload }) {
  const dispatch = useDispatch();

  const accountLocation = useAccountLocation();
  const country_code = accountLocation?.country_code ?? '';

  const onSubmit = useCallback((values) => {
    dispatch(createCompanyThunk(values))
      .unwrap()
      .then(reload);
  }, [reload]);

  const changeSet = {
    // company identifiers
    name: ['', yup.string().required('Company name is required!')],
    registration: ['', yup.string().required('Company registration is required!')],
    excise_id: ['', yup.string()],
    compliance_id: ['', yup.string()],
    wowgr: [false, yup.boolean()],
    // settings
    imperial_units: [false, yup.boolean()],
    //  address
    line1: ['', yup.string().required('Address is required!')],
    line2: ['', yup.string()],
    line3: ['', yup.string()],
    city: ['', yup.string().required('City is required!')],
    state: ['', yup.string()],
    country: [country_code, yup.string().required('Country is required!')],
    postCode: ['', yup.string().required('Post code is required!')],
    company_type: [
      '1',
      yup.string().required('Company type is required!'),
    ],
    warehousing: [true, yup.boolean()],
    production: [true, yup.boolean()],
    bottling: [true, yup.boolean()],
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={onSubmit}
    >
      <Stack className="form" spacing="2rem">
        <Stack spacing="1.5rem">
          <H5>Details</H5>
          <FormikTextField name="name" label="Company name" fullWidth required />
          <FormikTextField name="registration" label="Registration id" required />
          <FormikTextField name="excise_id" label="Excise/Tax id" />
          <FormikTextField name="compliance_id" label="Compliance reporting id" />
        </Stack>

        <Stack spacing='1.5rem'>
          <H5>Address</H5>
          <Address />
        </Stack>

        <CompanyType />

        <CompanyMemberships />

        <CompanySettings />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={reload}
            color='secondary'
          >
            Cancel
          </TextButton>

          <FilledButton type='submit' size='small'>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default Company;
