import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { Body1 } from '@pw/components/Typography';

export function ResetPasswordDialog({
	item,
	handleClose,
	handleResetPassword,
}) {
	const handleSubmit = () => {
		handleResetPassword(item);
		handleClose();
	};

	return (
		<ModalWithClose title='Reset Password' open={!!item} onClose={handleClose}>
			<Stack spacing={2}>
				<Body1>Full name: {item?.account_name}</Body1>
				<Body1>Contact email: {item?.account_email}</Body1>

				<Box className='action-buttons'>
					<TextButton size='small' handleClick={handleClose} color='secondary'>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small' handleClick={handleSubmit}>
						Confirm
					</FilledButton>
				</Box>				
			</Stack>
		</ModalWithClose>
	);
}
