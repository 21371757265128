import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DependentSKU from '@pw/components/SKUSelector/modals/source/DependentSKU';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';

function DependentSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_description } = item ?? {};

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{sku_name}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Stack spacing={3}>
					<Instructions>{sku_description}</Instructions>

					<DependentSKU item={item ?? {}} onClose={onClose} />
				</Stack>
			</Stack>
		</Modal>
	);
}

export default DependentSKUModal;
