import Box from '@mui/material/Box';
import { Handle } from 'reactflow';
import { Position } from './CustomSmoothStepEdge';

export const CustomHandle = ({ type, position, className, id, nodeId, connectedEdges, ...rest }) => {

  const isConnected = connectedEdges.find(
    ({ source, sourceHandle, targetHandle }) => {
      const isSource = source === nodeId;
      return isSource ? id === sourceHandle : id === targetHandle;
    }
  );
  const classes = isConnected ? 'handle-connected' : '';

  return (
    <Handle
      className={`node-handle handle-type-${type} ${className || ''} ${classes}`}
      id={id}
      type={type}
      position={position}
      {...rest}
    />
  );
}

const Handles = ({ className, id, type, ...rest }) => {
  return (
    <Box className={`handles-container ${className || ''}`}>
      <CustomHandle
        className="handle-base top-right"
        id={`handle-top-right-${type}`}
        type={type}
        position={Position.TopRight}
        {...rest}
      />

      <CustomHandle
        className="handle-base bottom-right"
        id={`handle-bottom-right-${type}`}
        type={type}
        position={Position.BottomRight}
        {...rest}
      />

      <CustomHandle
        className="handle-base bottom-left"
        id={`handle-bottom-left-${type}`}
        type={type}
        position={Position.BottomLeft}
        {...rest}
      />

      <CustomHandle
        className="handle-base top-left"
        id={`handle-top-left-${type}`}
        type={type}
        position={Position.TopLeft}
        {...rest}
      />
    </Box>
  );
}

export default Handles;