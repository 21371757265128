import { Close } from '@mui/icons-material';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
} from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { shareWithPartnerThunk } from '@pw/redux/thunks/partners';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

export function InvitePartnerDialog({ open, onClose, partnerId }) {
	const dispatch = useDispatch();

	const changeSet = useMemo(
		() => ({
			name: ['', yup.string().required('Full name required!')],
			email: [
				'',
				yup.string().email('Invalid email!').required('Email required!'),
			],
		}),
		[],
	);
	const handleOnClose = useCallback(() => onClose(), [onClose]);

	const handleSubmit = useCallback(
		(values) => {
			dispatch(shareWithPartnerThunk({ ...values, partner_id: partnerId }))
				.unwrap()
				.then(() => handleOnClose());
		},
		[partnerId],
	);

	return (
		<Dialog open={open} onClose={handleOnClose} maxWidth='xs' scroll='paper'>
			<IconButton
				aria-label='close'
				onClick={handleOnClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<DialogTitle>Invite New Partner</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<Instructions>
							Share your company info with new partner.
						</Instructions>
						<FormikTextField
							name='name'
							label='Full name'
							autoComplete='name'
							required
						/>
						<FormikTextField
							name='email'
							label='Contact email'
							autoComplete='email'
							required
						/>
					</Stack>
				</DialogContent>
				<DialogActions sx={{ m: 2 }}>
					<TextButton
						size='small'
						handleClick={() => handleOnClose()}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						type='submit'
						size='small'
					>
						Invite
					</FilledButton>
				</DialogActions>
			</FormikForm>
		</Dialog>
	);
}

InvitePartnerDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	partnerId: PropTypes.string,
};
