import { pageHeaderHeight } from "@pw/components/Layout/consts";

const styles = {
  '&.recipe-actions-root': {
    position: 'fixed',
    zIndex: 1,
    right: '1rem',
    top: `${pageHeaderHeight}px`,
    marginTop: '1rem',
    width: '100vw',
    maxWidth: 'calc(100vw - (18rem + 4rem))',
    transition: 'all 0.3s',
    // backgroundColor: 'white',
    // borderRadius: '0.5rem',
    overflow: 'hidden',
    // padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1rem',
    alignItems: 'flex-end',

    '&.with-global-menu': {
      maxWidth: {
        xs: 'calc(100vw - (18rem + 4rem + 150px))',
        md: 'calc(100vw - (18rem + 4rem + 250px))',
      }
    },
  },

  button: {
    whiteSpace: 'pre',
    backgroundColor: '#C7DA65',

    '&:hover': { backgroundColor: '#98AF24' },
  },

  '.heading': {
    position: 'absolute',
    whiteSpace: 'nowrap',
    zIndex: 2,
    top: 0,
    left: 0,
  },

  '.tabs': {
    width: 'auto',
    minWidth: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    position: 'relative',
    flexShrink: 1,
    alignItems: 'flex-start',
    marginBottom: '-1rem',
  },
  '.actions': {
    width: 'auto',
    alignSelf: 'center',
    minWidth: 'fit-content',
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '0.5rem',
    alignItems: 'stretch',
    flexShrink: 0,
    justifySelf: 'flex-end',
    marginLeft: 'auto'
  },

  '.MuiTabs-flexContainer': { paddingTop: '1.5rem' },
  '.MuiTab-root': {
    textTransform: 'none',
    minWidth: '4rem',
    alignItems: 'flex-start',
  },
  '.MuiTab-root': {
    backgroundColor: '#fafafa',
    color: '#212121',
    marginRight: '3px',

    '&:hover': { backgroundColor: '#eee' }
  },
  '.MuiTab-root.Mui-selected': {
    backgroundColor: '#eee',
    color: '#00A89D',
  },
  '.MuiTabs-indicator': {
    bottom: 0,
    height: '3px',
    background: '#00A89D',
    zIndex: 9,
  },
};

export default styles;