export const Status = {
	PENDING: 'pending',
	APPROVED: 'approved',
	REJECTED: 'rejected',
};
export const UK_COUNTRY_CODES = Object.keys({
	'GB-ENG': 'England',
	'GB-NIR': 'Northern Ireland',
	'GB-SCT': 'Scotland',
	'GB-WLS': 'Wales',
	'GB': 'United Kingdom',
});
