const styles = {
  '&.tile-root': {
    width: '3.875rem',
    textAlign: 'center',
    transition: 'all 0.2s',
    transformOrigin: 'bottom center',
    borderRadius: '0.25rem',
    py: 0,

    '&:hover': {
      transform: 'scale(1.05) translateY(-0.25rem)',
    },
  },

  '.icon-container': {
    display: 'block',
    padding: 0,
    width: '100%',
    height: '62px',
    padding: '0.5rem',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    boxShadow: '1px 1px 3px 0px #00000040',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  svg: {
    display: 'block',
    boxSizing: 'content-box',
    fontSize: '2.5rem',
    lineHeight: 1,
    height: '1em',
    width: '1em'
  },

  '.label': {
    marginTop: '0.25rem',
    fontSize: '0.75rem',
    lineHeight: 1.1,
    fontWeight: 400,
    color: '#393C3C',
    textAlign: 'center'
  },

  '&.material-tile': {
    '.label': { color: '#00A89D' },
  },
  '&.process-tile': {
    '.icon-container': { backgroundColor: '#00A89D', },
    '.label': { color: '#00A89D' },
    svg: { color: 'white', },
  },
  '&.observation-tile': {
    '.icon-container': { backgroundColor: '#C753A8', },
    svg: { color: 'white', },
  },
  '&.operation-tile': {
    '.icon-container': { backgroundColor: '#C7DA65', },
    svg: { color: '#707070', },
  },

};
export default styles;
