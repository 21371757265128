import { createCompany, listPermissions, listUsers, updateCompany } from "@pw/services/company.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setPageLoadingMessageThunk } from "./log";
import handleError from "./utils/handleError";
import loadCompanyState from "./utils/loadCompanyState";
import { getTaxCodes } from "@pw/services/taxCode.service";

export const createCompanyThunk = createAsyncThunk(
  `company/createCompanyThunk`,
  async (company, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Creating company', company);
      const response = await createCompany(company);
      console.log('  -->', response.company_id);

      dispatch(setPageLoadingMessageThunk({ message: 'Loading Users...' }))
      const users = await listUsers({ id: response.company_id });

      dispatch(setPageLoadingMessageThunk({ message: 'Loading Permissions...' }))
      const permissions = await listPermissions();

      dispatch(setPageLoadingMessageThunk({ message: 'Loading Tax Codes...' }))
      const taxCodes = await getTaxCodes().then(({ items = [] }) =>
        items?.map((item) => ({
          label: `${item?.path?.split('/').reverse()[0]} (${item?.description})`,
          value: item?.path,
        })),
      );

      return fulfillWithValue({
        ...response,
        skus: [],
        facilities: []  ,
        users,
        sensors: [],
        partners: [],
        owners: [],
        roles: [],
        teams: [],
        permissions,
        taxCodes,
      });
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  }
);  

export const updateCompanyThunk = createAsyncThunk(
  `company/updateCompanyThunk`,
  async (company, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Updating company', company);
      const response = await updateCompany(company);
      console.log('  -->', response.company_id);

      const updatedCompany = await loadCompanyState(response, dispatch);

      return fulfillWithValue(updatedCompany);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  }
);