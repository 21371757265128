export const COMP = {
	asset: (l, r) => l.rw_asset_id.localeCompare(r.rw_asset_id),
	request: (l, r) => l.rw_request_id.localeCompare(r.rw_request_id),
	sku: (l, r) => l.sku_name.localeCompare(r.sku_name),
	name: (l, r) => l.name.localeCompare(r.name),
	state: (l, r) => l.state.localeCompare(r.state),
	date: (l, r) => l.date.toString().localeCompare(r.date.toString()),
	sku_entry_id: (l, r) => l.sku_entry_id.localeCompare(r.sku_entry_id),
	sensor: (l, r) => l.path.localeCompare(r.path),
	coverageLocation: (l, r) => l.path.localeCompare(r.path),
	location: (l, r) => l.location_id.localeCompare(r.location_id),
	image: (l, r) =>
		l.key ? l.key.localeCompare(r.key) : l.dataUrl.localeCompare(r.dataUrl),
	id: (l, r) => l.id.localeCompare(r.id),
};

export const EQUAL = {
	asset: (l, r) => {
		if (!l || !r) return false;
		if (l.asset_id && r.asset_id) return l.asset_id === r.asset_id;
		if (l.path && r.path) return l.path === r.path;
		return l.rw_asset_id === r.rw_asset_id;
	},
	request: (l, r) => {
		if (!l || !r) return false;
		if (l.request_id && r.request_id) return l.request_id === r.request_id;
		if (l.path && r.path) return l.path === r.path;
		return l.rw_request_id === r.rw_request_id;
	},
	sensor: (l, r) => {
		if (!l || !r) return false;
		if (l.path && r.path) return l.path === r.path;
		return l.thing_id === r.thing_id;
	},
	coverageLocation: (l, r) => {
		if (!l || !r) return false;
		if (l.id && r.id) return l.id === r.id;
		if (l.path && r.path) return l.path === r.path;
	},
	sku: (l, r) => {
		if (!l || !r) return false;
		if (l.path && r.path) return l.path === r.path;
		return l.sku_name === r.sku_name;
	},
	sku_name: (l, r) => {
		if (!l || !r) return false;
		return l.sku_name === r.sku_name;
	},
	location: (l, r) => {
		if (!l || !r) return false;
		return l.location_id === r.location_id;
	},
	id: (l, r) => {
		if (!l || !r) return false;
		return l.id === r.id;
	},
};

export const ID = {
	asset: (a) => a.asset_id ?? a.path ?? a.rw_asset_id,
	request: (r) => r.request_id ?? r.path ?? r.rw_request_id,
	sku: (s) => s.sku_id ?? s.path ?? s.sku_name,
	name: (s) => s.name,
	state: (s) => s.state,
	date: (s) =>
		s.date.toString() /** this is number so need to convert to string*/,
	sensor: (s) => s.gateway_id ?? s.path ?? s.thing_id,
	sku_name: (s) => s.sku_name,
	sku_entry_id: (s) => s.sku_entry_id,
	coverageLocation: (s) => s?.id ?? s?.path,
	location: (l) => l.location_id,
	image: (i) => i.key || i.dataUrl,
	id: (i) => i.id,
};
