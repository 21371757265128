import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestID from '@pw/components/RequestID';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import { H5 } from '@pw/components/Typography';
import { REQUEST_EDIT_URL, REQUEST_TYPES } from '@pw/consts/requests';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { migrateRequestThunk } from '@pw/redux/thunks/request';
import styles from '@pw/styles/modal.styles';
import { useDispatch } from 'react-redux';

function ImportModal({ skus, entity, open, onClose }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const [mutate, { isLoading: isMutating = false }] = useRequestMutation();

	// console.log('Mapped SKUs', skus);
	//
	const changeSet = requestIdFields({});
	//
	// const mapAssets = useCallback(() => {
	// 	const { sources = [] } = entity;
	// 	return sources.map((s) => {
	// 		const asset = { ...s };
	// 		// Map the asset skus - or reset if not mapped..
	// 		let {
	// 			properties = {}, // this contains nested skus
	// 		} = asset;
	// 		let { sku_id: assetSkuId, level = {} } = properties;
	// 		let { liquid = {} } = level;
	// 		const { sku_id: liquidSkuId } = liquid;
	//
	// 		if (liquidSkuId) {
	// 			const sku = skus.find((s) => s.id === liquidSkuId);
	// 			if (sku && sku.mapped_sku) {
	// 				liquid.sku_id = sku.mapped_sku.sku_id;
	// 				liquid.sku = {
	// 					sku_name: sku.mapped_sku.sku_name,
	// 					sku_type: sku.mapped_sku.sku_type,
	// 					sku_description: sku.mapped_sku.sku_description,
	// 				};
	// 			} else {
	// 				liquid.sku_id = undefined;
	// 				liquid.sku = undefined;
	// 			}
	// 			level = {
	// 				...level,
	// 				liquid,
	// 			};
	// 			properties.level = level;
	// 		}
	//
	// 		if (assetSkuId) {
	// 			const sku = skus.find((s) => s.id === assetSkuId);
	// 			if (sku && sku.mapped_sku) {
	// 				properties.sku_id = sku.mapped_sku.sku_id;
	// 				properties.sku = {
	// 					sku_name: sku.mapped_sku.sku_name,
	// 					sku_type: sku.mapped_sku.sku_type,
	// 					sku_description: sku.mapped_sku.sku_description,
	// 				};
	// 			} else {
	// 				properties.sku_id = undefined;
	// 				properties.sku = undefined;
	// 			}
	// 		}
	//
	// 		return {
	// 			...asset,
	// 			sku_id: undefined,
	// 			sku: undefined,
	// 			properties,
	// 		};
	// 	});
	// }, [skus, entity]);
	//
	// const mapSkus = useCallback(() => {
	// 	const { sources = [], sku_sources = [] } = entity;
	// 	return sku_sources
	// 		.map((s) => {
	// 			const { sku_id, entries = [] } = s;
	// 			const { mapped_sku } = skus.find((s) => s.sku_id === sku_id) ?? {};
	// 			if (mapped_sku) {
	// 				// const mapped_entries = entries.map((e) => {
	// 				// 	return e;
	// 				// });
	//
	// 				// Grab the amounts from each entry, and the storage items..
	// 				let amount = 0;
	// 				let storage = [];
	//
	// 				entries.forEach((e) => {
	// 					amount += e.amount;
	// 					storage = storage.concat(e.storage);
	// 				});
	//
	// 				// Filter any storage items that are not mapped
	// 				storage = storage
	// 					.filter((s) => {
	// 						const { asset_id } = s;
	// 						const asset = sources.find((a) => a.asset_id === asset_id);
	// 						if (asset) {
	// 							return s;
	// 						}
	// 						return null;
	// 					})
	// 					.filter((s) => s);
	//
	// 				// Construct a new entry for this..
	// 				const mapped_entries = [
	// 					{
	// 						amount,
	// 						storage,
	// 					},
	// 				];
	//
	// 				console.log('Using mapped SKU', mapped_sku, mapped_entries);
	// 				return {
	// 					...mapped_sku,
	// 					entries: mapped_entries,
	// 				};
	// 			}
	// 			return null;
	// 		})
	// 		.filter((x) => x);
	// }, [skus, entity]);

	const handleSubmit = useCallback((values) => {
		dispatch(migrateRequestThunk({
			...values,
				request_type: REQUEST_TYPES.delivery,
				entity,
				skus,
			}))
			.unwrap()
			.then((response) => {
				const route = REQUEST_EDIT_URL?.[REQUEST_TYPES.delivery];
				navigate(`${route}/${response?.path}`, { replace: true });
			});
		},
		[entity, skus],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Import</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<FormikForm
					changeSet={changeSet}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					<FormWrapper>
						<RequestID
							type={REQUEST_TYPES.delivery}
							title='Delivery'
							label='ID.'
						/>

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
								label='Cancel'
							/>
							<FilledButton type='submit' size='small'>
								Import
							</FilledButton>
						</Box>
					</FormWrapper>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

export default ImportModal;
