import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Section1Report({
	name,
	title = 'On hand first month',
	disabled,
	specify = false,
	withdrawn  = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Transaction'
				fullWidth
				sx={{ ...(!specify && styles.transaction) }}
			/>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='b'
					label='Completely Denatured Alcohol'
					fullWidth
					sx={{ ...(specify && styles.transaction) }}
				/>
				<FormikTextField
					name='c'
					label='Specially Denatured Alcohol'
					fullWidth
					sx={{ ...((specify || withdrawn) && styles.transaction) }}
				/>
			</FlexBox>
			<FlexBox
				spacing={2}
				alignItems='top'
				sx={{ ...((specify || withdrawn) && styles.transaction) }}
			>
				<FormikTextField name='d' label='Specially Denatured Rum' fullWidth />
			</FlexBox>
		</FormikProvider>
	);
}
