import loadJSONFile from "../functions/uploadHandler";
import { useRef } from 'react';
const UploadButton = ({ callback, label = 'Upload', ...props }) => {
  const fileInputRef = useRef();

  return (
    <label
      variant="contained"
      component="label"
      htmlFor="recipe-upload"
      {...props}
    >
      {label}
      <input
        hidden
        accept=".json"
        id="recipe-upload"
        type="file"
        ref={fileInputRef}
        onChange={async (event) => {
          console.log({ event })
          await loadJSONFile(event, callback);
        }}
      />
    </label>
  )
}

export default UploadButton;
