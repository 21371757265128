/* eslint-disable react-hooks/rules-of-hooks */
import { useCompanyPartners } from '@pw/redux/containers/User';
import useDebouncedCallback from '@pw/utilities/hooks/logic/useDebouncedCallback';
import { useCallback, useEffect, useRef, useState } from 'react';


function usePartnerSearch() {
	const cache = useRef(new Map());
	const partners = useCompanyPartners();

	const [options, setOptions] = useState([]);

	const findPartner = useCallback((search) => {
		return partners.find((partner) => partner.name.toLowerCase().includes(search.toLowerCase()));
	}, [partners]);

	const handleOnSearchDebounced = useDebouncedCallback((search = '') => {
		if (search.length > 1) {
			if (!cache.current.has(search)) {
				cache.current.set(search, findPartner(search));
			}
			setOptions(cache.current.get(search) ?? []);
		}
	}, 300);

	useEffect(
		() => () => {
			// clean cache on unmount
			cache.current.clear();
		},
		[],
	);

	return [options, handleOnSearchDebounced];
}

export default usePartnerSearch;
