import { clientFields } from '@pw/components/Client/clientFields';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import SampleProperties from '@pw/components/SampleProperties';
import sampleFields from '@pw/components/SampleProperties/sampleFields';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS } from '@pw/consts/units';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function SampleForm() {
	let [searchParams] = useSearchParams();
	const asset = searchParams.get('asset');
	const [fetchAsset] = useAssetLazyQuery();
	const converter = useConverter();

	const [
		[sourceSkus],
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Assets',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.filling_tank,
			],
			sku_types: [SKU_TYPES.SERVICE],
		},
		SKUModal: SourceSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: sampleFields(initialValues?.properties),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
		},
		[initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
			properties: {
				...(entity?.properties ?? {}),
				sample_size: converter.cx(entity?.properties?.sample?.value, entity?.properties?.sample?.unit, UNITS.milliliters),
			}
		}),
		[sourceSkus, sourceAssets],
	);

	useEffect(() => {
		if (asset) {
			fetchAsset(asset).then((a) => {
				debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
			});
		}
	}, [asset, initSourceAssets, fetchAsset]);

	return (
		<>
			<RequestForm
				requestLabel='Sample'
				requestType={REQUEST_TYPES.sample}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
			>
				<SourceInventory />

				<SampleProperties name='properties' />
			</RequestForm>

			<SourceModals />
		</>
	);
}

export default withAppLayout(SampleForm, { title: 'Sample' });
