import { Box, Divider, Stack } from '@mui/material';
import { Body2, Overline } from '@pw/components/Typography';
import { useNavigate } from 'react-router-dom';
// import { notificationCount } from 'src/redux/containers/Notifications/selectors';
import { useLocation } from 'react-router-dom';
import styles from './notification.styles';

function NotificationContent() {
	const navigate = useNavigate();
	const location = useLocation();
	const { assignedRequests = 0, pendingAssets = 0 } = {};

	const handleClick = async () => {
		if (location.pathname !== '/app/tasks') {
			navigate('/app/tasks');
		}
	};

	return (
		<Box sx={styles} className='root'>
			<Box onClick={handleClick} className='list cursorPointer'>
				<Stack alignItems='center'>
					<Body2 sx={{ fontSize: '2rem' }}>{pendingAssets}</Body2>
					<Overline>Pending Assets</Overline>
				</Stack>

				<Divider className='divider' />

				<Stack alignItems='center'>
					<Body2 sx={{ fontSize: '2rem' }}>{assignedRequests}</Body2>
					<Overline>Assigned Requests</Overline>
				</Stack>
			</Box>
		</Box>
	);
}
export default NotificationContent;
