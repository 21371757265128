import * as yup from 'yup';

export default function part3Fields(fields = {}) {
	const {
		Puerto_Rican_Spirits = '1',
		Virgin_Island_Spirits = '1',
		Other = '1',
	} = fields;

	return {
		Puerto_Rican_Spirits: [
			Puerto_Rican_Spirits,
			yup.number().min(0, 'Puerto_Rican_Spirits value cannot be less than 0'),
		],
		Virgin_Island_Spirits: [
			Virgin_Island_Spirits,
			yup.number().min(0, 'Virgin_Island_Spirits value cannot be less than 0'),
		],
		Other: [Other, yup.number().min(0, 'Other value cannot be less than 0')],
	};
}
