import { useState, useEffect, createContext, useContext, useMemo, useCallback, useRef } from 'react';

const FormValuesContext = createContext({});

function FormValuesContextProvider({
  externalState = null,
  externalSetState = null,
  externalRefs = null,
  externalSetRefs = null,
  children
}) {
  const [internalState, internalSetState] = useState(externalState || {});
  const [internalRefs, internalSetRefs] = useState(externalRefs || {});

  const state = useMemo(() => externalState || internalState,
    [externalState, internalState]
  );
  const setState = useMemo(() => externalSetState || internalSetState,
    [externalSetState, internalSetState]
  );

  const setRefs = useMemo(() => externalSetRefs || internalSetRefs,
    [externalSetRefs, internalSetRefs]
  );
  const setRef = useCallback((fName, ref) => {
    setRefs((curr) => ({
      ...curr,
      [fName]: ref.current,
    }));
  }, [internalRefs]);

  const setField = useCallback((fName, fValue) => {
    console.log('setField', { name: fName, value: fValue });
    setState((curr) => ({
      ...curr,
      [fName]: fValue,
    }));
  }, []);

  const getField = useCallback((fName) => state[fName], [state]);

  const passProps = useMemo(() => ({
    values: state,
    setState,
    setField,
    getField,
    setRef,
  }), [state, setState, setField, getField, setRef]);

  useEffect(() => {
    if (!state) return;
    console.log('FVContext', { state });
  }, [state])
  useEffect(() => {
    if (!setState) return;
    console.log('FVContext', { setState });
  }, [setState])
  useEffect(() => {
    if (!getField) return;
    console.log('FVContext', { getField });
  }, [getField])
  useEffect(() => {
    if (!setField) return;
    console.log('FVContext', { setField });
  }, [setField])

  return (
    <FormValuesContext.Provider value={passProps}>
      {children}
    </FormValuesContext.Provider>
  );
}

export default FormValuesContextProvider;

export const useFormValuesContext = () => useContext(FormValuesContext);