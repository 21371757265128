import { AddCircle } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import { useCompanyTeams } from '@pw/redux/containers/User/hooks';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useState } from 'react';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import { Body3, H5 } from '@pw/components/Typography';
import styles from '@pw/styles/content.styles';
import Team from './TeamForm';

function TeamItem({ team }) {
  const teamName = team.path.replace('/t/', '');

  return (
    <Box className="listItem">
      <Box className="listContent">
        <H5>{teamName}</H5>
        <Body3>{team.description}</Body3>
      </Box>
    </Box>
  );
}

function Teams() {
  const [target, scroll] = useScrollTarget();

  const teams = useCompanyTeams();
  const [team, setTeam] = useState(null);

  useEffect(() => scroll(), [scroll]);

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      <Box className="header">
        <H5>Manage Teams</H5>

        <TitledButton
          handleClick={() => {
            setTeam({});
            scroll();
          }}
          variant='outlined'
          color='secondary'
          label='New'
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>Configures the teams that your users can be in.</Instructions>

      <Collapse in={!!team}>
        <Team
          team={team}
          onClose={() => {
            setTeam(null);
            scroll();
          }}
        />
      </Collapse>

      <Stack spacing={0} className="list">
        {teams.map((p) => <TeamItem key={p.path} team={p} />)}
      </Stack>

    </Stack>
  );
}

export default Teams;
