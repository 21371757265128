import { Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { SKU_EDIT_URL, SKU_TYPE_REVERSE } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';

const SelectedSKU = forwardRef(({
	item,
	onRemove,
	onEdit,
	display,
	selected,
}, ref) => {
	const { readonly } = useContext(FormikContext);
	const { sku_id, sku_name, sku_type, sku_description } = item ?? {};

	const openSku = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			const path = item?.sku_id ?? 'new';
			const route = SKU_EDIT_URL?.[item?.sku_type];
			console.log('Routing to', route, path);
			window.open(`${route}/${path}`, '_blank').focus();
		},
		[item],
	);

	return (
		<Box className='listItem' ref={ref}>
			{selected && selected(item) && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack spacing={0.5}>
					<IDDisplay value={`${sku_id} [${SKU_TYPE_REVERSE[sku_type]}]`} onClick={openSku} />
					<NameDisplay name={sku_name} />
					<DescriptionDisplay value={sku_description} />
					{display && display(item)}
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{!readonly && onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
				{!readonly && onRemove && (
					<IconCircleButton onClick={() => onRemove(item)}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
});

export default SelectedSKU;
