import * as yup from 'yup';

export default function part1ReportFields(fields = {}) {
	const { b, c, d, e, f, g } = fields;

	return {
		b: [
			b,
			yup
				.number()
				.min(
					0,
					'Whisky Distilled At 160° And Under value cannot be less than 0',
				),
		],
		c: [
			c,
			yup
				.number()
				.min(0, 'Whisky Distilled At Over 160° value cannot be less than 0'),
		],
		d: [
			d,
			yup
				.number()
				.min(
					0,
					'Brandy Distilled At 170° And Under value cannot be less than 0',
				),
		],
		e: [
			e,
			yup
				.number()
				.min(
					0,
					'Brandy Distilled At Over 170° in Bond value cannot be less than 0',
				),
		],
		f: [f, yup.number().min(0, 'Rum value cannot be less than 0')],
		g: [g, yup.number().min(0, 'Gin value cannot be less than 0')],
	};
}
