import { AddCircle, ContentCopy } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Upload from '@pw/components/BatchUpload';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikForm,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import AssetSummaryList from '@pw/components/SKUSelector/items/AssetSummaryList';
import Switch from '@pw/components/SwitchComponent';
import { Body1, Body2, H5, H6 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import styles from '@pw/styles/modal.styles';
import generateId from '@pw/utilities/generateId';
import generateIds from '@pw/utilities/generateIds';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import match from '@pw/utilities/match';
import parseIdPattern from '@pw/utilities/parseIdPattern';
import { useCallback, useContext, useMemo, useState } from 'react';
import * as yup from 'yup';

function LiquidFormModal({ open, asset, onCancel, upsert }) {
	const converter = useConverter();
	
	const { properties = {}, asset_type } = asset ?? {};
	const {
		liquid = {},
		reference = '',
		imported = false,
		duty_paid = false,
	} = properties;

	const changeSet = useMemo(
		() => ({
			...match(asset_type, {
				[ASSET_TYPES.cask]: {
					cask: [properties?.cask, yup.array().of(yup.string())],
					imported: [imported, yup.boolean()],
					duty_paid: [duty_paid, yup.boolean()],
					reference: [reference, yup.string()],
				},
				[ASSET_TYPES.ibc]: {
					ibc: [properties?.ibc, yup.array().of(yup.string())],
					imported: [imported, yup.boolean()],
					duty_paid: [duty_paid, yup.boolean()],
					reference: [reference, yup.string()],
				},
				[ASSET_TYPES.pallet]: {
					pallet: [properties?.pallet, yup.array().of(yup.string())],
				},
				[ASSET_TYPES.tanker]: {
					tags: [properties?.tags, yup.array().of(yup.string())],
				},
				_: {},
			}),
			liquid: liquidPropertyFields(liquid, converter, true),
		}),
		[
			asset_type,
			properties?.cask,
			properties?.ibc,
			properties?.pallet,
			properties?.tags,
			imported,
			duty_paid,
			reference,
			liquid,
			converter,
		],
	);

	const handleSubmit = useCallback(
		(values) => {
			try {
				const newAsset = {
					...asset,
					properties: {
						...properties,
						...match(asset_type, {
							[ASSET_TYPES.cask]: {
								cask: values?.cask,
								reference: values?.reference,
								imported: values?.imported,
								duty_paid: values?.duty_paid,
							},
							[ASSET_TYPES.ibc]: {
								ibc: values?.ibc,
								reference: values?.reference,
								imported: values?.imported,
								duty_paid: values?.duty_paid,
							},
							[ASSET_TYPES.pallet]: {
								pallet: values?.pallet,
								reference: values?.reference,
								imported: values?.imported,
								duty_paid: values?.duty_paid,
							},
							[ASSET_TYPES.tanker]: {
								tags: values?.tags,
							},
							_: {},
						}),
						liquid: {
							...liquid,
							...values.liquid,
						},
					},
				};
				console.log('Upserting asset', newAsset);
				upsert(newAsset);
			} catch (e) {
				console.log('Failed to update', e);
			}
		},
		[asset, properties, asset_type, liquid, upsert],
	);

	return (
		<Modal open={open} onClose={onCancel}>
			<Stack sx={styles} className='root' spacing={2}>
				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing={2} className='main-content'>
						<Switch value={asset_type}>
							<Switch.Case condition={ASSET_TYPES.cask}>
								<H5>Cask Properties</H5>
								<FormikTextField name='reference' label='Reference' fullWidth />
								<TagSelect
									name='cask'
									type='cask'
								/>
								<Stack className='section'>
									<H6 className='section-title'>Liquid Status</H6>
									<FlexBox justifyContent='space-between'>
										<FormikCheckBox
											name='imported'
											label={<Body2>Imported</Body2>}
										/>
										<FormikCheckBox
											name='duty_paid'
											label={<Body2>Duty Paid</Body2>}
										/>
									</FlexBox>
								</Stack>
								<LiquidProperties name='liquid' />
							</Switch.Case>
							<Switch.Case condition={ASSET_TYPES.ibc}>
								<H5>IBC Properties</H5>
								<FormikTextField name='reference' label='Reference' fullWidth />
								<TagSelect
									name='ibc'
									type='ibc'
								/>
								<Stack className='section'>
									<H6 className='section-title'>Liquid Status</H6>
									<FlexBox justifyContent='space-between'>
										<FormikCheckBox
											name='imported'
											label={<Body2>Imported</Body2>}
										/>
										<FormikCheckBox
											name='duty_paid'
											label={<Body2>Duty Paid</Body2>}
										/>
									</FlexBox>
								</Stack>
								<LiquidProperties name='liquid' />
							</Switch.Case>
							<Switch.Case condition={ASSET_TYPES.pallet}>
								<H5>Pallet Properties</H5>
								<TagSelect
									name='pallet'
									type='pallet'
								/>
							</Switch.Case>
							<Switch.Case condition={ASSET_TYPES.tanker}>
								<H5>Tanker Properties</H5>
								<TagSelect
									name='tags'
									type='tags'
								/>
								<LiquidProperties name='liquid' useBulk={false} />
							</Switch.Case>
						</Switch>

						<Errors />
					</Stack>
					<Box className='action-buttons'>
						<TextButton size='small' handleClick={() => onCancel()} color='secondary' label='Cancel' />
						<FilledButton size='small' type='submit'>
							Save
						</FilledButton>
					</Box>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

function SingleAsset({ sku, onCancel, onConfirm, lastId, setLastId }) {
	const { properties, sku_id, sku_type, sku_name, sku_description } = sku ?? {};
	const { prefix, asset_type, tax_code } = properties ?? {};
	const [editAsset] = useState({ asset_type });

	const handleSaveAsset = useCallback(
		(asset) => {
			if (asset.rw_asset_id) {
				// This means we are editing this asset, so we can simply update as is
				onConfirm(asset);
			} else {
				const [px, pad] = parseIdPattern(prefix);

				const newAsset = {
					...asset,
					rw_asset_id: generateId(px, pad, lastId),
					asset_type,
					sku_id,
					sku: {
						sku_type,
						sku_name,
						sku_description,
						tax_code,
					},
				};

				onConfirm(newAsset);
				// Update the last id
				setLastId(lastId + 1);
			}
		},
		[
			onConfirm,
			prefix,
			lastId,
			asset_type,
			sku_id,
			sku_type,
			sku_name,
			sku_description,
			tax_code,
			setLastId,
		],
	);

	return (
		[
			ASSET_TYPES.cask,
			ASSET_TYPES.ibc,
			ASSET_TYPES.tanker,
			ASSET_TYPES.pallet,
		].includes(asset_type) && (
			<LiquidFormModal
				open
				asset={editAsset}
				upsert={handleSaveAsset}
				onCancel={onCancel}
			/>
		)
	);
}

function Generator({ sku, field, onCancel, onConfirm }) {
	const { values } = useContext(FormikContext);
	const { properties, sku_id, sku_type, sku_name, sku_description } = sku ?? {};
	const {
		last_number,
		prefix: sku_prefix,
		asset_type,
		tax_code,
	} = properties ?? {};

	const amount = values?.[field];

	const generateAssets = useCallback(() => {
		// Going to generate the assets and associate with the SKU
		// We're doing this on the front-end, when the request is saved
		// we will create the assets on the back-end in a pending state!
		const [prefix, pad] = parseIdPattern(sku_prefix);
		const st = Number(last_number ?? 0) + 1;
		const et = Number(last_number ?? 0) + 1 + Number(amount);
		const ids = generateIds(prefix, pad, st, et);
		const assets = ids.map((i) => ({
			rw_asset_id: i,
			asset_type,
			sku_id,
			sku: {
				sku_type,
				sku_name,
				sku_description,
				tax_code,
			},
			properties: {},
		}));
		onConfirm(assets);
	}, [
		amount,
		asset_type,
		last_number,
		onConfirm,
		sku_description,
		sku_id,
		sku_name,
		sku_prefix,
		sku_type,
		tax_code,
	]);

	return (
		<Stack spacing={1} className='section'>
			<H5 className='section-title'>Generate Assets</H5>
			<Instructions>
				Please check the details below before confirming
			</Instructions>
			<FlexBox sx={{ px: '1rem' }}>
				<Body1>Pattern</Body1>
				<Body1>
					<strong>{sku_prefix}</strong>
				</Body1>
			</FlexBox>
			<FlexBox sx={{ px: '1rem' }}>
				<Body1>Last Number</Body1>
				<Body1>
					<strong>{last_number}</strong>
				</Body1>
			</FlexBox>
			<FlexBox sx={{ px: '1rem' }}>
				<Body1>Assets</Body1>
				<Body1>
					<strong>{amount}</strong>
				</Body1>
			</FlexBox>

			<Box className='action-buttons'>
				<TextButton size='small' handleClick={onCancel} color='secondary'>
					Cancel
				</TextButton>
				<FilledButton
					size='small'
					disabled={Number.isNaN(amount) || Number(amount) <= 0}
					handleClick={generateAssets}
				>
					Confirm
				</FilledButton>
			</Box>
		</Stack>
	);
}

function Uploader({ sku, onCancel, onConfirm }) {
	return (
		<Stack spacing={1} className='form-section'>
			<Upload sku={sku} onCancel={onCancel} onConfirm={onConfirm} />
		</Stack>
	);
}

function TrackedSKUSetup({ field, sku, assets }) {
	const [assetList, setAssets, upsertAsset, removeAsset] = assets ?? [[]];
	const { properties } = sku ?? {};

	const { last_number = 0 } = properties ?? {};

	const [lastId, setLastId] = useState(Number(last_number) + 1);

	const [single, setSingle] = useState(false);
	const [gen, setGen] = useState(false);
	const [upload, setUpload] = useState(false);

	const handleSingle = () => {
		setSingle(true);
		setGen(false);
		setUpload(false);
	};

	const handleGenerate = () => {
		setGen(true);
		setUpload(false);
	};

	const handleBatch = () => {
		setGen(false);
		setUpload(true);
	};

	const handleSetAssets = useCallback(
		(a) => {
			setAssets(a);
			setGen(false);
			setUpload(false);
		},
		[setAssets],
	);

	const handleCancel = () => {
		setGen(false);
		setUpload(false);
	};

	const handleSingleAsset = (a) => {
		upsertAsset(a);
		setSingle(false);
	};

	return (
		<>
			<Divider />
			<Instructions>
				Generate new assets or upload from an existing sheet.
			</Instructions>
			<FlexBox justifyContent='center'>
				<TitledButton
					handleClick={handleSingle}
					variant='contained'
					color='primary'
					label='Items'
					sx={{ width: '72px', height: '55px' }}
				>
					<AddCircle height={24} width={24} />
				</TitledButton>
				<TitledButton
					handleClick={handleGenerate}
					variant='contained'
					color='primary'
					label='Generate'
					sx={{ width: '72px', height: '55px' }}
				>
					<ContentCopy height={24} width={24} />
				</TitledButton>
				<TitledButton
					handleClick={handleBatch}
					variant='contained'
					color='primary'
					label='Upload'
					sx={{ width: '72px', height: '55px' }}
				>
					<UploadFileIcon height={24} width={24} />
				</TitledButton>
			</FlexBox>

			{!!gen && (
				<Generator
					sku={sku}
					field={field}
					onCancel={handleCancel}
					onConfirm={handleSetAssets}
				/>
			)}
			{!!upload && (
				<Uploader
					sku={sku}
					field={field}
					onCancel={handleCancel}
					setAssets={setAssets}
					onConfirm={handleSetAssets}
				/>
			)}
			{assetList && assetList.length > 0 && (
				<AssetSummaryList items={assetList} onRemove={removeAsset} />
			)}
			{!!single && (
				<SingleAsset
					sku={sku}
					onCancel={() => setSingle(false)}
					onConfirm={handleSingleAsset}
					lastId={lastId}
					setLastId={setLastId}
				/>
			)}
		</>
	);
}

export default TrackedSKUSetup;
