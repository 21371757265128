import Add from '@mui/icons-material/Add';
import { Box, FilledInput, MenuItem } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// import { createKey } from '../nodes';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { H3 } from '@pw/components/Typography';
// import DraggableTile from './DraggableTile';
// import ToolBoxSection from './ToolBoxSection';
import LoadButton from '@pw/components/ProductionDesigner/Actions/LoadButton';
import DesignerContext from '@pw/context/DesignerContext';
import ToggleMenuButton from './ToggleMenuButton';
import styles from './ToolBox.styles';
// const selectStyles = {
// 	'ul, li': { width: '100%' },
// 	'.MuiButton-root': {
// 		width: 'calc(100% - 1rem)',
// 		margin: '0.5rem 0.5rem 0',
// 		backgroundColor: 'white',
// 	},
// };

const lockNodes = (nodes) =>
	nodes.map((n) => ({
		...n,
		// draggable: false, deletable: false, connectable: false,
	}));

const LHSSchedule = ({
	onLoad,
	loadedFromName,
	className,
	setIsLoading,
	showGlobalMenu,
	setShowGlobalMenu,
}) => {
	const [recipeId, setRecipeId] = useState();
	const label = 'Batch name';

	const { recipe, setBatch, batch } = useContext(DesignerContext);
	const [saveToName, setSaveToName] = useState('');
	const [itemName, setItemName] = useState('');

	const filterBatches = useMemo(
		() => (item) => item.recipe_id === recipeId,
		[recipeId],
	);

	const createBatch = useCallback(async () => {
		const now = new Date();
		const ts = now.getTime();
		const isoDate = now.toISOString().split('T')[0];
		const _name = `Untitled ${isoDate}`;
		setItemName(_name);
		const batch = {
			...recipe,
			name: _name,
			created: ts,
			updated: ts,
			entity_id: btoa(ts),
			recipe_id: recipeId,
			nodes: lockNodes(recipe.nodes),
			type: 'batch',
		};
		setBatch(batch);
	}, [recipe]);

	useEffect(() => {
		setItemName(batch.name);
	}, [batch]);

	useEffect(() => {
		console.log('LHS_Schedule', { recipeId });
		setBatch((prev) => ({ ...prev, name: saveToName }));
	}, [saveToName]);

	return (
		<Box className={`toolbox-root toolbar-root ${className || ''}`} sx={styles}>
			<Box className="toolbox_header">
				<ToggleMenuButton {...{ showGlobalMenu, setShowGlobalMenu }} />
				<H3 className='toolbox_heading'>Production Schedule</H3>

				<LoadButton
					type='recipe'
					onLoad={onLoad}
					loadedFromName={loadedFromName}
					value={loadedFromName}
					setValue={setRecipeId}
					valueKey='id'
					allowUpload
					setIsLoading={setIsLoading}
				/>

				<LoadButton
					type='batch'
					onLoad={onLoad}
					loadedFromName={loadedFromName}
					// value={loadedFromName}
					// filterFn={(item) => {
					//   console.log(item.recipeId, recipeId);
					//   return item.recipeId === recipeId;
					// }}
					filterFn={filterBatches}
					allowUpload
					setIsLoading={setIsLoading}
				>
					{recipeId !== 'label' && (
						<MenuItem
							value='create'
							onClick={() => {
								createBatch();
							}}
						>
							<Add />
							Create batch
						</MenuItem>
					)}
				</LoadButton>

				<Box className='name-field'>
					<FilledInput
						name='item_name'
						label={label}
						value={itemName}
						onChange={({ target: { value } }) => {
							setItemName(value);
						}}
						disableUnderline={true}
					/>
					<label>Batch name</label>
					<FilledButton
						size='small'
						handleClick={() => setSaveToName(itemName)}
						disabled={!(itemName && itemName !== saveToName)}
					>
						Save
					</FilledButton>
				</Box>
			</Box>
		</Box>
	);
};

export default LHSSchedule;
