import DesignerContext from "@pw/context/DesignerContext";
import { useAccount } from '@pw/redux/containers/User';
import { getDesign, getDesignBatch } from "@pw/services/production.service";
import { useCallback, useEffect, useState } from "react";
import { useEdgesState, useNodesState } from "reactflow";

function DesignerProvider({children, mode}) {
	if (!mode) {
		throw new Error("DesignContext: 'mode' is not provided");
	}
	const type = mode === 'design' ? 'recipe' : 'batch';

	const [isLoading, setIsLoading] = useState(false);
	const [batches, setBatches] = useState([]);
	const [skuId, setSkuId] = useState();
	const [recipe, setRecipe] = useState({});
	const [batch, setBatch] = useState({});
	const [batchId, setBatchId] = useState();
	const { account_username } = useAccount();
	const [refreshOptions, setRefreshOptions] = useState(0);

	const [document, setDocument] = type === 'recipe'
		? [recipe, setRecipe] : [batch, setBatch];

	const [nodes, setNodes, _onNodesChange] = useNodesState([]);
	const [edges, setEdges, _onEdgesChange] = useEdgesState([]);

	const loadRecipe = useCallback(async () => {
		if (!skuId) return;
		setIsLoading(true);
		try {
			const result = await getDesign(skuId);
			if (result) {
				setRecipe(result);
				setNodes(result.nodes);
				setEdges(result.edges);
			}
		} catch (e) {
			console.log(`couldn't load design`, e);
			const now = new Date().getTime();

			setRecipe({
				sku_id: skuId,
				entity_id: btoa(now),
				nodes,
				edges,
				username: account_username,
				created: now,
				updated: now,
				type,
			});
		}
		setIsLoading(false);
	}, [skuId]);

	useEffect(() => {
		loadRecipe()
	}, [skuId]);

	useEffect(() => {
		setRecipe((prev) => ({
			...prev,
			nodes,
			edges,
		}));
		setBatch((prev) => ({
			...prev,
			nodes,
			edges,
		}));
	}, [edges, nodes]);

	const loadBatch = useCallback(async () => {
		if (!batchId) return;

		setIsLoading(true);
		try {
			const chosen = await getDesignBatch(batchId);
			if (chosen) {
				setBatch(chosen);
				setNodes(chosen.nodes);
				setEdges(chosen.edges);
			}
		} catch (e) {
			const now = new Date();
			const ts = now.getTime();
			const isoDate = now.toISOString().split('T')[0];
			const _name = `Untitled ${isoDate}`;

			setBatch({
				...document,
				name: _name,
				created: ts,
				updated: ts,
				entity_id: btoa(ts),
				nodes: recipe.nodes?.map((n) => ({...n})),
				type: 'batch',
			});
		}
		setIsLoading(false);
	}, [batchId])

	useEffect(() => {
		loadBatch();
	}, [batchId]);


	return (
		<DesignerContext.Provider value={{
			isLoading, setIsLoading,
			batches, setBatches,
			batch, setBatch,
			recipe, setRecipe,
			skuId, setSkuId,
			document, setDocument,
			nodes, setNodes, _onNodesChange,
			edges, setEdges, _onEdgesChange,
			loadBatch, setBatchId,
			refreshOptions, setRefreshOptions,
			mode,
		}}>
			{children}
		</DesignerContext.Provider>
	);
}

export default DesignerProvider;
