import * as yup from 'yup';

export default function generalInformationFields(fields = {}) {
	const {
		REPORT_COVERS = '1',
		returnType = '1',
		Final = false,
		REPORTING_MONTH = '',
		PERIOD_YEAR = '',
		BUSINESS = '',
		DBA = '',
		ADDRESS = '',
		CITY = '',
		STATE = '',
		ZIP_CODE = '',
		plantNumber = '',
	} = fields;

	return {
		REPORT_COVERS: [
			REPORT_COVERS,
			yup
				.number()
				.required('Report covers is required!')
				.integer('Report covers must be an integer'),
		],
		returnType: [
			returnType,
			yup
				.number()
				.required('Return type is required!')
				.integer('Return type must be an integer'),
		],
		Final: [Final, yup.boolean().required('Final status is required!')],
		REPORTING_MONTH: [
			REPORTING_MONTH,
			yup
				.number()
				.required('Reporting month is required!')
				.integer('Reporting month must be an integer'),
		],
		PERIOD_YEAR: [
			PERIOD_YEAR,
			yup.string().required('Reporting period year is required!'),
		],
		BUSINESS: [BUSINESS, yup.string().required('Business name is required!')],
		DBA: [DBA, yup.string()],
		ADDRESS: [ADDRESS, yup.string().required('Address is required!')],
		CITY: [CITY, yup.string().required('City is required!')],
		STATE: [STATE, yup.string().required('State is required!')],
		ZIP_CODE: [ZIP_CODE, yup.string().required('Zip code is required!')],
		plantNumber: [
			plantNumber,
			yup.string().required('Plant number is required!'),
		],
	};
}
