import Cask from '../icons/Cask2';
import Grain from '../icons/Grain';
import Steep from '../icons/Steep';
import Water from '../icons/Water';
import Peat from '../icons/Peat';
import Yeast from '../icons/Yeast';
import KilnOven from '../icons/KilnOven';
import HammerMill from '../icons/HammerMill';
import ContinuousMill from '../icons/ContinuousMill';
import Mash from '../icons/Mash';
import Washback from '../icons/Washback';
import WashStill from '../icons/WashStill';
import SpiritStill from '../icons/SpiritStill';
import Receiver from '../icons/Receiver';
import ColumnStill from '../icons/ColumnStill';
import PotStill from '../icons/PotStill';
import Ph from '../icons/Ph';
import Temperature from '../icons/Temperature';
import Brix from '../icons/Brix';
import OG_SG from '../icons/OG_SG';
import Combine from '../icons/Combine';
import Split from '../icons/Split';
import Throttle from '../icons/Throttle';
import Start from '../icons/Start';
import Stop from '../icons/Stop';
import Route from '../icons/Route';

import { HelpCenter } from '@mui/icons-material';

// type measuredByType = 'weight' | 'volume' | 'unit';
export let materials = [];
export let processes = [];
export let observations = [];
export let operations = [];
export const createKey = ({ type, variant = null }) =>
  [type, variant || null].filter((v) => Boolean(v)).join('-') +
  `-${new Date().getTime()}`;

// const autoGenerateIntermediates = ({ nodes, edges }) => nodes.map((s) => )

export const iconFromName = (name) => {
  switch (name) {
    // Materials
    case 'Cask':
      return <Cask />;
    case 'Grain':
      return <Grain />;
    case 'Steep':
      return <Steep />;
    case 'Water':
      return <Water />;
    case 'Peat':
      return <Peat />;
    case 'Yeast':
      return <Yeast />;
    // Operations
    case 'KilnOven':
      return <KilnOven />;
    case 'HammerMill':
      return <HammerMill />;
    case 'ContinuousMill':
      return <ContinuousMill />;
    case 'Mash':
      return <Mash />;
    case 'Washback':
      return <Washback />;
    case 'WashStill':
      return <WashStill />;
    case 'SpiritStill':
      return <SpiritStill />;
    case 'Receiver':
      return <Receiver />;
    case 'ColumnStill':
      return <ColumnStill />;
    case 'PotStill':
      return <PotStill />;
    // Observations
    case 'Ph':
      return <Ph />;
    case 'Temperature':
      return <Temperature />;
    case 'Brix':
      return <Brix />;
    case 'OG_SG':
      return <OG_SG />;
    // Operations
    case 'Combine':
      return <Combine />;
    case 'Split':
      return <Split />;
    case 'Throttle':
      return <Throttle />;
    case 'Start':
      return <Start />;
    case 'Stop':
      return <Stop />;
    case 'Route':
      return <Route />;
    // Fallback
    default:
      return <HelpCenter />;
  }
};

materials = [
  // raw ingredients
  {
    category: 'material',
    icon: 'Grain',
    type: 'grain',
    label: 'Grain',
    measuredBy: 'weight',
    outputType: ['grain'],
    variants: [
      { label: 'Barley', type: 'barley' },
      { label: 'Corn', type: 'corn' },
      { label: 'Rhye', type: 'rhye' },
      { label: 'Wheat', type: 'wheat' },
    ],
  },
  {
    category: 'material',
    icon: 'Water',
    type: 'water',
    label: 'Water',
    measuredBy: 'volume',
    outputType: ['water'],
  },
  {
    category: 'material',
    icon: 'Yeast',
    type: 'yeast',
    label: 'Yeast',
    measuredBy: 'weight',
    outputType: ['yeast'],
    variants: [], //TODO ...
  },
  {
    category: 'material',
    icon: 'Peat',
    type: 'peat',
    label: 'Peat',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['peat'],
  },
  {
    category: 'material',
    icon: 'Enzymes',
    type: 'enzymes',
    label: 'Enzymes',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['enzymes'],
  },
  {
    category: 'material',
    icon: 'Defoamer',
    type: 'defoamer',
    label: 'Defoamer',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['defoamer'],
  },
  {
    category: 'material',
    icon: 'Nutrients',
    type: 'nutrients',
    label: 'Nutrients',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['nutrients'],
  },
  {
    category: 'material',
    icon: 'Anti-Microbial',
    type: 'anti-microbial',
    label: 'Anti-Microbial',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['anti-microbial'],
  },
  // derived states of materials
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'grain_milled',
  //   label: 'Milled Grain',
  //   measuredBy: 'weight',
  //   variants: materialLookup('grain')?.variants,
  //   outputType: ['milled_grain'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'grain_malted',
  //   label: 'Malted Grain',
  //   measuredBy: 'weight',
  //   variants: materialLookup('grain')?.variants,
  //   outputType: ['malted_grain'],
  // },

  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'wort',
  //   label: 'Wort',
  //   measuredBy: 'weight',
  //   outputType: ['wort'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'low_wine',
  //   label: 'Low Wine',
  //   measuredBy: 'volume',
  //   outputType: ['low_wine'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit',
  //   label: 'Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'distillant',
  //   label: 'Distillant',
  //   measuredBy: 'volume',
  //   outputType: ['distillant'],

  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_new',
  //   label: 'New Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_new'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_newmake',
  //   label: 'Newmake Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_newmake'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_casked',
  //   label: 'Casked Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_casked'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_aged',
  //   label: 'Aged Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_aged'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_blended',
  //   label: 'Blended Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_blended'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_bottled',
  //   label: 'Bottled Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_bottled'],
  // },
];

processes = [
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'milling',
  //   label: 'Milling Grain',
  //   inputType: ['grain'],
  //   outputType: ['grain_milled'],
  //   variants: materialLookup('grain')?.variants,
  //   equipment: ['mill'],
  //   transforms: [
  //     () => { },
  //   ]
  // },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'malting',
  //   label: 'Malting Grain',
  //   inputType: ['grain_milled', 'water'],
  //   outputType: ['grain_malted'],
  //   equipment: [
  //     'malting_room'
  //   ]
  // },
  {
    category: 'process',
    icon: 'Mash',
    type: 'mash_tun',
    label: 'Mash Tun',
    inputType: ['grain_milled', 'water'],
    outputType: ['wort'],
  },
  {
    category: 'process',
    icon: 'MashCooler',
    type: 'mash_cooler',
    label: 'Mash Cooler',
    inputType: ['wort'],
    outputType: ['wort_cooled'],
  },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'fermenting',
  //   label: 'Fermenting',
  //   inputType: ['wort', 'yeast'],
  //   outputType: ['low_wine'],
  //   equipment: [
  //     'washback'
  //   ]
  // },
  {
    type: 'still',
    category: 'process',
    icon: 'WashStill',
    label: 'Wash Still',
    variant: 'wash',
    inputType: ['low_wine'],
    outputType: ['spirit'],
  },
  {
    type: 'still',
    category: 'process',
    icon: 'SpiritStill',
    label: 'Spirit Still',
    variant: 'spirit',
    inputType: ['spirit'],
    outputType: ['distillant'],
  },
  {
    type: 'still',
    category: 'process',
    icon: 'PotStill',
    label: 'Pot Still',
    variant: 'pot',
    inputType: ['spirit'],
    outputType: ['distillant'],
  },
  {
    type: 'still',
    category: 'process',
    icon: 'ColumnStill',
    label: 'Column Still',
    variant: 'column',
    inputType: ['spirit'],
    outputType: ['distillant'],
  },
  {
    category: 'process',
    icon: 'Receiver',
    type: 'receiver',
    label: 'Receiver',
    inputType: ['distillant', 'water'],
    outputType: ['spirit_newmake'],
  },
  {
    category: 'process',
    icon: 'BeerWell',
    type: 'beer_well',
    label: 'Beer Well',
    inputType: ['distillant', 'water', 'spirit', 'low_wine'],
    outputType: ['spirit_newmake'],
  },
  // {
  // category: 'process',
  // icon: 'Cask',
  // type: 'dilution',
  //   label: 'Dilution',
  //   inputType: ['spirit_new', 'water'],
  //   outputType: ['spirit_newmake'],
  //   equipment: [
  //     'spirit_receiver'
  //   ]
  // },
  {
    category: 'process',
    icon: 'Cask',
    type: 'cask',
    label: 'Cask',
    inputType: ['spirit_newmake'],
    outputType: ['spirit_casked'],
  },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'maturation',
  //   label: 'Maturation',
  //   inputType: ['spirit_casked'],
  //   outputType: ['spirit_aged'],
  // },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'blending',
  //   label: 'Blending',
  //   inputType: ['spirit_aged'],
  //   outputType: ['spirit_blended'],
  //   equipment: [
  //     'tank'
  //   ]
  // },
  {
    category: 'process',
    icon: 'HammerMill',
    type: 'mill_hammer',
    label: 'Hammer Mill',
    inputType: ['_steep_out', 'peat'],
    outputType: ['_mill_hammer_out'],
  },
  {
    category: 'process',
    icon: 'ContinuousMill',
    type: 'mill_continous',
    label: 'Continuous Mill',
    inputType: ['_mill_hammer_out'],
    outputType: ['_mill_continous_out'],
  },
  //
  //
  //
  {
    category: 'process',
    icon: 'Steep',
    type: 'steep',
    label: 'Steeps',
    measuredBy: 'weight',
    outputType: ['_steep_out'],
  },
  {
    category: 'process',
    icon: 'KilnOven',
    type: 'kiln_oven',
    label: 'Kiln Oven',
    inputType: ['_steep_out', 'peat'],
    outputType: ['_kiln_oven_out'],
  },
  {
    category: 'process',
    icon: 'Washback',
    type: 'washback',
    label: 'Washback',
    inputType: ['wort', 'yeast'],
    outputType: ['_washback_out'],
  },
  {
    category: 'process',
    icon: 'Separator',
    type: 'separator',
    label: 'Liquid/Solid Separator',
    inputType: ['wort', 'mash', '_other'],
    outputType: ['_liquid', '_solid'],
  },
];

observations = [
  {
    category: 'observation',
    icon: 'Ph',
    type: 'ph',
    label: 'PH',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'Temperature',
    type: 'temperature',
    label: 'Temperature',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'Brix',
    type: 'brix',
    label: 'Brix',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'OG_SG',
    type: 'og_sg',
    label: 'OG/SG',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
];

operations = [
  {
    category: 'operation',
    icon: 'Combine',
    type: 'combine',
    label: 'Combine',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Split',
    type: 'split',
    label: 'Split',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Throttle',
    type: 'throttle',
    label: 'Throttle',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Start',
    type: 'start',
    label: 'Start',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Stop',
    type: 'stop',
    label: 'Stop',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Route',
    type: 'route',
    label: 'Route',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
];
