import { useState, createContext, useContext } from 'react';



const SelectedNode = createContext([]);

function SelectedNodeContextProvider({ selectedNode, setSelectedNode, children }) {
  // const [selectedNode, setSelectedNode] = useState(null);

  return (
    <SelectedNode.Provider value={[selectedNode, setSelectedNode]}>
      {children}
    </SelectedNode.Provider>
  );
}

export default SelectedNodeContextProvider;

export const useSelectedNode = () => useContext(SelectedNode);