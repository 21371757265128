import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';

export default function Signature({
	name,
	title = 'Signature',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<FormikTextField
						name='PROPRIETOR1'
						label='Proprietor'
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='TITLE'
						label='Title'
						required
						fullWidth
						disabled={disabled}
					/>
				</>
			)}
		</FormikProvider>
	);
}
