import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

export default function TransactionsReport({
	name,
	title = 'Tax Payment',
	disabled = false,
	specify = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Transaction'
				// required
				fullWidth
				disabled={!specify}
			/>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='b'
					label='Whiskey 160° and Under'
					// required
					fullWidth
					disabled={specify}
				/>
				<FormikTextField
					name='c'
					label='Whiskey Over 160°'
					// required
					fullWidth
					disabled={specify}
				/>
			</FlexBox>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='d'
					label='Brandy 170° and Under'
					// required
					fullWidth
					disabled={specify}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
