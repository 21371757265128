import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Overline } from '@pw/components/Typography';
import { usePageLoadingHook } from '@pw/redux/containers/App/hooks';

function TitledButton({
	type = 'button',
	label = '',
	size = 'medium',
	handleClick = undefined,
	onClick = undefined,
	loading = false,
	disabled = false,
	sx = {},
	className = '',
	variant = 'contained',
	color = 'primary',
	extraContent = null,
	children,
}) {
	const { loading: isLoading } = usePageLoadingHook();

	return (
		<>
			<Button
				variant={variant}
				sx={sx}
				disabled={loading || disabled || !!isLoading}
				onClick={onClick ?? handleClick}
				className={className}
				size={size}
				type={type}
				color={color}
			>
				<Stack
					spacing={1}
					sx={{ justifyContent: 'center', alignItems: 'center' }}
				>
					{children}
					{label && <Overline sx={{ fontWeight: 500 }}>{label}</Overline>}
				</Stack>
			</Button>
			{extraContent}
		</>
	);
}

export default TitledButton;
