const listStyles = {
	'.list': {
		position: 'relative',

		'.listItem': {
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'stretch',
			borderColor: 'rgba(0, 0, 0, 0.15)',
			borderLeftStyle: 'solid',
			borderRightStyle: 'solid',
			borderLeftWidth: '1px',
			borderRightWidth: '1px',
			borderBottomStyle: 'solid',
			borderBottomWidth: '1px',
			borderRadius: 0,
			paddingRight: '1rem',
			overflow: 'hidden',

			'.listSelected': {
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
				backgroundColor: 'green',
				color: '#fff',
				fill: '#fff',
				borderLeft: '0.2rem solid green',
				borderRight: '0.2rem solid green',
				'*': {
					color: 'inherit',
					fill: 'currentColor',
				},
			},

			'.listForm': {},

			'.listContent': {
				flexGrow: 1,
				paddingLeft: '1rem',
				paddingTop: '0.75rem',
				paddingBottom: '0.75rem',
			},

			'.listButtons': {
				alignItems: 'center',
				justifyContent: 'center',
				maxWidth: 'min-content',
				'*': {
					height: '32px',
					width: '32px',
					svg: {
						height: '24px',
						width: '24px',
					},
				},
			},
		},

		'.list &gt; .listItem:nth-of-type(even)': {
			backgroundColor: 'rgba(0, 0, 0, 0.02)',
		},

		'.list &gt; .listItem:nth-of-type(odd)': {
			backgroundColor: 'white',
		},

		'.list &gt; .listItem:first-of-type': {
			borderTopRightRadius: '5px',
			borderTopLeftRadius: '5px',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
		},
		'.list &gt; .listItem:last-child': {
			borderBottomRightRadius: '5px',
			borderBottomLeftRadius: '5px',
		},

		'.listCol': {
			flexDirection: 'column',
		},

		'.listExtended': {
			width: '100%',
		},
	},

	'.fixedList': {
		maxHeight: '130px',
		overflowY: 'scroll',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		borderRadius: '5px',
		border: '1px solid rgba(0, 0, 0, 0.15)',
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '0.75rem',
		paddingBottom: '0.75rem',
	},

	'.section': {
		padding: '0.5rem',
		border: '1px solid rgba(0, 0, 0, 0.15)',
		borderRadius: '5px',
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',

		'.section-title': {
			position: 'relative',
			marginTop: '-2ch',
			backgroundColor: '#fff',
			color: '#7c6c4f',
			maxWidth: 'max-content',
			paddingLeft: '1ch',
			paddingRight: '1ch',
		},

		'.section-header': {
			position: 'relative',
			marginTop: '-1.75ch',
			backgroundColor: '#fff',
			maxWidth: 'max-content',
			paddingLeft: '1ch',
			paddingRight: '1ch',
		},
	},

	'.inventory': {
		// border: '1px solid rgba(0, 0, 0, 0.15)',

		'.inventory-contents': {
			maxHeight: '250px',
			overflowY: 'auto',
			display: 'flex',
			position: 'relative',
			flexDirection: 'column',

			'&:before': {
				content: '" "',
				position: 'sticky',
				top: 0,
				left: 0,
				flexGrow: 1,
				minWidth: '100%',
				minHeight: '10px',
				zIndex: '100',
				'--shadow-color': '0deg 0% 75%',
				boxShadow: `
					inset 0px 0.5px 0.6px hsl(var(--shadow-color) / 0.23),
					inset 0px 1.3px 1.5px -0.8px hsl(var(--shadow-color) / 0.23),
					inset 0px 3.2px 3.6px -1.7px hsl(var(--shadow-color) / 0.23),
					inset 0px 4.7px 6.7px -2.5px hsl(var(--shadow-color) / 0.23)`,
			},
			'.inventory-section': {
				'.inventory-section-title': {
				},
			},
		},

		'.inventory-header': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: '0.5rem',
			padding: '0.5rem',
			marginLeft: '-0.5rem',
			marginRight: '-0.5rem',
			overflow: 'hidden',
		},

		'.filter-form': {
			display: 'flex',
			gap: '0.5rem',
			paddingRight: '1rem',
			alignItems: 'center',
			justifyContent: 'flex-end',
			paddingTop: '0.5rem',
			paddingBottom: '0.5rem',
			backgroundColor: 'rgba(0, 0, 0, 0.01)',
			borderTopRightRadius: '5px',
			borderTopLeftRadius: '5px',
			overflow: 'hidden',
		},

		'.inventory-footer': {
			padding: '0',
			paddingTop: '0.75rem',
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gridColumnGap: '5px',
			gridRowGap: '5px',
		},
	},

	'.search-results': {
		'.search-result-contents': {
			maxHeight: '250px',
			overflowY: 'auto',
			display: 'flex',
			position: 'relative',
			flexDirection: 'column',

			'&:before': {
				content: '" "',
				position: 'sticky',
				top: 0,
				left: 0,
				flexGrow: 1,
				minWidth: '100%',
				minHeight: '10px',
				zIndex: '100',
				'--shadow-color': '0deg 0% 75%',
				boxShadow: `
					inset 0px 0.5px 0.6px hsl(var(--shadow-color) / 0.23),
					inset 0px 1.3px 1.5px -0.8px hsl(var(--shadow-color) / 0.23),
					inset 0px 3.2px 3.6px -1.7px hsl(var(--shadow-color) / 0.23),
					inset 0px 4.7px 6.7px -2.5px hsl(var(--shadow-color) / 0.23)`,
			},

			'.search-section': {
				padding: {
					xs: '0.5rem',
					md: '1rem',
				},

				'.search-section-title': {
					marginBottom: '-4px',
					backgroundColor: 'rgba(0, 0, 0, 0.15)',
					display: 'flex',
					width: 'min-content',
					textAlign: 'center',
					minWidth: '100px',
					color: '#000',
					textColor: '#000',
					paddingTop: '0.35rem',
					paddingBottom: '0.35rem',
					paddingLeft: '0.5rem',
					paddingRight: '0.5rem',
					borderTopLeftRadius: '5px',
					borderTopRightRadius: '5px',
					overflow: 'hidden',
				},
			},
		},
	},
};

export default listStyles;
