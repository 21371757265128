import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Client from '@pw/components/Client';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { TIME_UNIT_OPTIONS } from '@pw/consts/units';
import styles from '@pw/styles/modal.styles';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as yup from 'yup';

function VendorModal({ title, item, open, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	const label = item?.vendor ? `Update ${title}` : `New ${title}`;

	// Properties from the vendor config..
	const {
		vendor = { enable: false },
		product,
		unit_price,
		lead_time,
	} = item ?? {};

	const changeSet = useMemo(
		() => ({
			vendor: [
				vendor,
				yup.object().shape({
					name: yup.string(),
					public_name: yup.string(),
					id: yup.string(),
					hash: yup.string(),
					type: yup.string(),
				}),
			],
			product: [product ?? '', yup.string()],
			unit_price: [
				unit_price ?? '',
				yup.string().required('Unit Price required!'),
			],
			lead_time: [
				lead_time,
				yup.object().shape({
					value: yup.number().required('Lead Time required!'),
					unit: yup.string(),
				}),
			],
		}),
		[vendor, product, unit_price, lead_time],
	);

	const handleSubmit = (vendor) => {
		try {
			console.log('Setting Vendor', vendor);
			onClose(vendor);
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{label}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={1}>
							<Client name='vendor' label='Partner' noCustomers={true} />

							<FormikTextField label='Product Code' name='product' fullWidth />
							<FlexBox>
								<FormikTextField
									label='Unit Price'
									name='unit_price'
									fullWidth
								/>
								<FormikUnitField
									label='Lead Time'
									name='lead_time'
									options={TIME_UNIT_OPTIONS}
								/>
							</FlexBox>

							<AlertView />

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small'>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default VendorModal;
