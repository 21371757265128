import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikMeasuresField } from '@pw/components/Forms/FormikForm';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo } from 'react';
import * as yup from 'yup';

function PurchaseSKU({ item, onClose }) {
	const converter = useConverter();

	// We track the "amount" we want at the SKU Item level
	const { amount = 0, properties = {} } = item ?? {};
	const { unit } = properties;

	const changeSet = useMemo(
		() => ({
			amount: [
				amount && unit ? converter.cx(amount, null, unit) : '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
		}),
		[amount],
	);

	const handleSubmit = (values) => {
		const sku = {
			...item,
			amount: unit ? converter.cx(values.amount, unit) : values.amount,
		};
		onClose(sku);
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={1}>
				<FormikMeasuresField label='Amount' name='amount' unit={unit} fullWidth />

				<Errors />

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default PurchaseSKU;
