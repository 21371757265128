export const ASSET_TYPES = {
	cask: '1',
	filling_tank: '20',
	ibc: '21',
	pallet: '22',
	tanker: '23',
	container: '24',
	// bottle: '2',
};

export const ASSET_OPTIONS = [
	{ label: 'Cask', value: '1' },
	{ label: 'IBC', value: '21' },
	{ label: 'Pallet', value: '22' },
	{ label: 'Tanker', value: '23' },
	{ label: 'Container', value: '24' },
];

export const ASSET_STATUS = {
	PENDING_CREATE: '0', // when created on pending table
	PENDING_DEPLOY: '1', // when approved and waiting for chain deploy (not used for now)
	ACTIVE: '2', // when asset is on main table (after approval)
	REJECT_CREATE: '3', // when asset was rejected (after approval)
	PENDING_UPDATE: '4', // when asset was updated and waiting for approval (pending + main)
	REJECT_UPDATE: '5', // when asset was rejected (after approval)
	PENDING_MOVE: '6', // when transferring assets and pending chain approval (asset migration)
	MOVED: '7', // when the asset is shipped to other company and chain approved (not used for now)
	PENDING_REMOVE: '8', // when the asset is shipped to other company awaiting chain approval(not used for now) simply we are deleting the record
	REMOVED: '9', // when the asset is shipped and chain approved(not used for now) simply we are deleting the record
	DEFECTED: '10', // when asset is defected
};

export const isAssetActive = (status) => status === ASSET_STATUS.ACTIVE;
export const isAssetPending = (status) =>
	[ASSET_STATUS.PENDING_CREATE, ASSET_STATUS.PENDING_UPDATE].includes(status);

export const ASSET_TYPES_REVERSE = {
	1: 'cask',
	20: 'filling_tank',
	21: 'ibc',
	22: 'pallet',
	23: 'tanker',
	24: 'container',
};

export const ASSET_NAMES = {
	1: 'Cask',
	20: 'Filling Tank',
	21: 'IBC',
	22: 'Pallet',
	23: 'Tanker',
	24: 'Container',
};

export const ASSET_CODE = {
	1: 'C',
	20: 'F',
	21: 'I',
	22: 'P',
	23: 'T',
};

export const ASSET_EDIT_URL = {
	1: '/app/cask',
	20: '/app/tank',
	21: '/app/ibc',
	22: '/app/pallet',
	23: '/app/tanker',
	24: '/app/container',
};

export const ASSET_ACCOUNT_TYPE = {
	BENEFICIAL_OWNER: 0,
	REGISTERED_OWNER: 1,
	REPRESENTATIVE_OWNER: 2,
};

export const DEFAULT_ASSET_ACCOUNTS = Object.values(ASSET_ACCOUNT_TYPE).map(
	(type) => [String(type), '', '', ''],
);

export const ASSET_ACCOUNT_TYPE_TO_LABEL = {
	[ASSET_ACCOUNT_TYPE.BENEFICIAL_OWNER]: 'Deed Holder',
	[ASSET_ACCOUNT_TYPE.REGISTERED_OWNER]: 'Managing Agent',
	[ASSET_ACCOUNT_TYPE.REPRESENTATIVE_OWNER]: 'Representative Agent',
};

export const ASSET_LIQUID_TAGS_MAPPING = {
	'12 year old malt': 'malt,12yr',
	'12 year old malt ex cherry': 'malt,12yr,ex-cherry',
	'gnd malt': 'gnd,malt',
	'19 year old powerscourt': 'powerscourt,19yr',
	grain: 'grain',
	'malt td': 'malt,td',
	'mitchers blend': 'mitchers,blend',
	'new make malt': 'malt,new-make',
	'pot still': 'pot-still',
	'dd malt': 'malt,dd',
	'19 year old malt': 'malt,19yr',
	'20 year old malt': 'malt,20yr',
	'cooley grain': 'cooley,grain',
	'new make irish whiskey': 'new-make,irish,whiskey',
	'pot still heavy': 'pot-still,heavy',
	'pot still light': 'pot-still,light',
};

export const ASSET_CASK_TAGS_MAPPING = {
	ab1: 'american,oak,bourbon,standard',
	american: 'american,oak,standard',
	bourbon: 'american,oak,bourbon,standard',
	'sherry oloroso': 'sherry,olorosso',
	french: 'french,oak',
	'heaven hill': 'american,oak,bourbon,heaven-hill,standard',
	'heaven hill b2': 'american,oak,bourbon,heaven-hill,b2,standard',
	'heaven hill b3': 'american,oak,bourbon,heaven-hill,b3,standard',
	'heavily charred b1': 'american,oak,heavy,char,b1',
	'hungarian oak': 'hungarian,oak',
	'jack daniels': 'american,oak,jack-daniels,standard',
	'jim beam': 'american,oak,jim-beam,standard',
	'kelvin cooperage': 'kelvin-cooperage',
	'pedro ximenez': 'px,sherry',
	'px sherry': 'px,sherry',
	sauternes: 'sauternes',
	speyside: 'speyside',
	'virgina hungarian oak': 'virgin,hungarian,oak',
	'western spirit b1': 'american,oak,bourbon,western-spirit,b1,standard',
	'wild turkey': 'american,oak,bourbon,wild-turkey,standard',
	mostcatel: 'moscatel,fortified,wine',
	banyuls: 'banyuls,fortified,wine',
	rivesaltes: 'riversaltes,fortified,wine',
	chestnutt: 'chestnut',
	cherry: 'cherry',
	'amontillado butt': 'butt,amontillado,sherry',
	'amontillado hogs': 'hogshead,amontillado,sherry',
	'apricot brandy': 'apricot,brandy',
	cavadlos: 'cavaldos,brandy',
	'cavadlos 2nd fill': 'cavaldos,brandy,re-fill,2nd',
	'cassis blackcurrant liquor': 'cassis,liqueur,blackcurrant',
	'cerise cherry brandy': 'cerise,brandy,cherry',
	champagne: 'champagne',
	'fine red wine': 'red,fine,wine',
	'fino hog': 'fino,hogshead',
	'four roses b1': 'american,oak,bourbon,four-roses,b1,standard',
	'kelvin wine': 'kelvin-wine',
	maderia: 'madeira,fortified,wine',
	'manzinalla hogs': 'manzanilla,sherry,hogshead',
	'maple bourbon': 'american,oak,bourbon,four-roses,b1,standard',
	'maple syrup': 'maple-syrup',
	marsella: 'marsella,fortified,wine',
	mezcal: 'mezcal,mescal',
	monbazillac: 'monbazillac,wine,sweet',
	neoc: 'neoc,french,oak',
	'neoc 2nd fill': 'neoc,french,oak,re-fill,2nd',
	'neoc 3rd fill': 'neoc,french,oak,re-fill,3rd',
	oloroso: 'sherry,olorosso',
	'oloroso butt': 'sherry,olorosso,butt',
	'oloroso hog': 'sherry,olorosso,hogshead',
	'orange liquor': 'orange,liqueur',
	'orange sherry': 'orange,sherry',
	'orange wine': 'orange,wine',
	'oz tyler b1': 'american,oak,bourbon,oz-tyler,b1',
	'pinot noir': 'pinot-noir',
	'red wine': 'red,wine',
	'ruby port': 'ruby,port',
	'robertson b1': 'robertson,whiskey,b1',
	run: 'rum',
	'select bourbon': 'american,oak,bourbon,select,standard',
	sherry: 'sherry',
	tequilla: 'tequila',
	'virgin oak': 'virgin,oak',
};

export const LIQUID_ACCOUNT_TYPE = {
	production: '1',
	storage: '2',
	processing: '3',
};

export const LIQUID_ACCOUNT_TYPE_OPTIONS = [
	{ label: 'Production', value: '1' },
	{ label: 'Storage', value: '2' },
	{ label: 'Processing', value: '3' },
];
