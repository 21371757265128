import { Add, Delete, Edit } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, H5, Overline } from '@pw/components/Typography';
import SensorCoverageModal, {
	generateFastestUUID,
} from '@pw/components/admin/Sensors/SensorCoverageModal';
import IDDisplay from '@pw/components/properties/IDDisplay';
import { upsertSensorThunk } from '@pw/redux/thunks/sensors';
import styles from '@pw/styles/modal.styles';
import toLocation from '@pw/utilities/adapters/toLocation';
import { COMP, ID } from '@pw/utilities/comp';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

// interface SensorUpsertRequest extends Sensor {
//     // id: companyPK(company_id);
//     // path: /sensor/0013A2004237EB6A
//     sensor_id?: string; //is available on on create
//     gateway_id: string; // /gateway/40:F5:20:BA:0F:90 TODO: we will need to make index here to be able to update sensor
//     sensorCoverage: Array<SensorCoverage>;
// }

// export interface SensorCoverage extends DynamoEntry {
//   // id: companyPK(company_id),
//   // path: /sn/uniqLocation
//   sensor_id: string; // /sensor/0013A2004237EB6A TODO: we will need to make index here to be able to update sensor location
//   location: UniqueLocationKey;
//   // date: number; ?
// }

function SensorCoverageItem({ item = {}, remove, edit }) {
	const {
		facility_id = '',
		bay = '',
		row = '',
		level = '',
	} = toLocation(item?.path?.replace('/sn/', ''));

	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<Stack spacing={0.5}>
					<IDDisplay value={facility_id.split('/')[1]} />
					<Stack className='listForm' direction='row' spacing={2}>
						<Stack alignItems='center'>
							<Body1>
								<strong>{bay}</strong>
							</Body1>
							<Overline>Bay</Overline>
						</Stack>
						<Stack alignItems='center'>
							<Body1>
								<strong>{row || '•'}</strong>
							</Body1>
							<Overline>Row</Overline>
						</Stack>
						<Stack alignItems='center'>
							<Body1>
								<strong>{level || '•'}</strong>
							</Body1>
							<Overline>Level</Overline>
						</Stack>
					</Stack>
				</Stack>
			</Box>

			<Stack className='listButtons'>
				<IconButton edge='end' aria-label='edit'>
					<IconCircleButton onClick={edit}>
						<Edit />
					</IconCircleButton>
				</IconButton>
				<IconButton edge='end' aria-label='delete'>
					<IconCircleButton onClick={remove}>
						<Delete />
					</IconCircleButton>
				</IconButton>
			</Stack>
		</Box>
	);
	// return (
	//   <Stack className="listItem">
	//     <Box className="listContent">
	//       <Stack className="listForm" direction="row" spacing="3rem">
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{facility_id.split('/')[1]}</Body2>
	//           <Overline>Facility</Overline>
	//         </Stack>
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{bay}</Body2>
	//           <Overline>Bay</Overline>
	//         </Stack>
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{row || '•'}</Body2>
	//           <Overline>Row</Overline>
	//         </Stack>
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{level || '•'}</Body2>
	//           <Overline>Level</Overline>
	//         </Stack>
	//       </Stack>
	//     </Box>

	//     <Stack className="listButton" direction="row" spacing="0.25rem">
	//       <IconCircleButton onClick={edit}><Edit /></IconCircleButton>
	//       <IconCircleButton onClick={remove}><Delete /></IconCircleButton>
	//     </Stack>
	//   </Stack>
	// );
}

function SensorModal({ entity, open, upsert, onClose, gateway_id }) {
	const dispatch = useDispatch();

	const editing = useMemo(() => !!entity?.path, [entity]);
	const title = useMemo(() => (editing ? 'Edit Sensor' : 'New Sensor'), [editing]);

	const withId = useMemo(
		() =>
			(entity?.sensorCoverage ?? []).map((item) => ({
				...item,
				id: generateFastestUUID(),
			})),
		[entity],
	);

	const [sensorCoverageState, , , upsertSensorCoverage, removeSensorCoverage] =
		useItemListManager(ID.coverageLocation, COMP.coverageLocation, withId);

	const [location, setLocation] = useState(null);

	const changeSet = {
		path: [
			entity?.path?.replace('/sensor/', '') ?? '',
			yup.string().min(1).required('Sensor address is required!'),
		],
	};

	const handleSubmit = useCallback((values) => {
			const payload = {
				...entity,
					...values,
					gateway_id,
					sensorCoverage: sensorCoverageState,
				};
			dispatch(upsertSensorThunk(payload))
				.unwrap()
					.then((res) => {
					upsert({ ...payload, ...res, sensorCoverage: sensorCoverageState });
					onClose();
				});
		},
		[entity, gateway_id, sensorCoverageState],
	);

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Stack sx={styles} className='root' spacing={2}>
					<FlexBox>
						<H5>{title}</H5>
						<IconButton
							onClick={() => onClose()}
							className='close-btn'
							aria-label='Close'
						>
							<CloseIcon />
						</IconButton>
					</FlexBox>
					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={1}>
							<FlexBox alignItems='top'>
								<FormikTextField
									disabled={editing}
									label='Sensor address'
									name='path'
									fullWidth
								/>
							</FlexBox>
							<Divider className='divider' />
							<FlexBox>
								<H5>Coverage Locations</H5>
								<Stack direction='row' justifyContent='flex-end'>
									<IconCircleButton onClick={() => setLocation({})}>
										<Add />
									</IconCircleButton>
								</Stack>
							</FlexBox>

							<Stack spacing={0} className='list'>
								{sensorCoverageState.length === 0 && (
									<Alert severity='warning'>
										No coverage locations configured.
									</Alert>
								)}
								{sensorCoverageState.map(
									(item, index) =>
										!location && (
											<SensorCoverageItem
												item={item}
												edit={() => setLocation(item)}
												remove={() => removeSensorCoverage(item)}
												key={`${item?.path ?? index}`}
											/>
										),
								)}
							</Stack>

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
									label='Cancel'
								/>
								<FilledButton type='submit' size='small'>
									{editing ? 'Update' : 'Add'}
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Stack>
			</Modal>
			{!!location && (
				<SensorCoverageModal
					open={!!location}
					entity={location}
					upsert={upsertSensorCoverage}
					onClose={() => setLocation(null)}
				/>
			)}
		</>
	);
}

export default SensorModal;
