const styles = {
  '.modal-content': {
    width: '100%',
    maxWidth: '20rem',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    // margin: 'auto',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1.5rem',
  },
  '.header': {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
    columnGap: '1rem',
  },

  '.icon-container': {
    fontSize: '3rem',
  },
  svg: {
    display: 'block',
    color: 'inherit',
    fill: 'currentColor'
  },
  '.icon-process': { color: '#00A89D' },
  '.icon-observation': { color: '#C753A8' },

  '.title': {
    lineHeight: '1.25',
    textTransform: 'uppercase',
    color: '#393C3C',
  },
  '.form-root': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
  '.input-root': {
    position: 'relative',
    // width: '100%',
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'stretch',
    '> *': { width: '100%', }
  },
  label: {
    position: 'absolute',
    zIndex: 2,
    top: '4px',
    left: '0.75rem',
    fontSize: `${14 / 16}rem`
  },
  '.MuiInputBase-input': {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '4px',
    lineHeight: 1,
    backgroundColor: '#F0F0F0',
  },
  '.save-button': {
    width: 'fit-content',
    alignSelf: 'flex-end',
  },
}
export default styles;
