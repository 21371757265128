import isFunction from 'lodash.isfunction';
/**
 * Matches an action to a corresponding case function, or executes a default case if no match is found.
 *
 * @param {string} action - The action to match.
 * @param {Object} cases - An object containing case functions, where the keys are the action names and the values are the corresponding functions.
 * @returns {*} The result of executing the matched case function, or the default case function if no match is found.
 */
function match(action, cases) {
	if (!cases[action] || !cases['_'])
		throw new Error('No matching brach found for ' + action);
	const branch = cases[action] ? cases[action] : cases['_'];
	return isFunction(branch) ? branch(action) : branch;
}

export default match;
