import { H4 } from '@pw/components/Typography';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};
/* eslint-disable react/prop-types */
export default function Part2Report({
	name,
	title = 'On Hand First of Month',
	specify = false,
	wine = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Transaction'
				fullWidth
				sx={{ ...(!specify && styles.transaction) }}
			/>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='b'
					label='Bottled (Proof gallons)'
					fullWidth
					sx={{ ...((specify || wine) && styles.transaction) }}
				/>
				<FormikTextField
					name='c'
					label='Packaged (Proof gallons)'
					fullWidth
					sx={{ ...(specify && styles.transaction) }}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
