import part2ReportFields from './part2ReportFields';

export default function part2Fields(fields = {}) {
	const {
		On_hand_first_of_month = '',
		Deposited_in_bulk_storage = '',
		Received_from_customs_custody = '',
		Returned_to_bulk_storage = '',
		Taxpaid = '',
		Use_of_the_United_States = '',
		Hospital_scientific_educational_use = '',
		Export = '',
		Transfer_to_foreign_trade_zone = '',
		Transfer_to_cmbw = '',
		Use_as_supplies_on_vessels_and_aircraft = '',
		Transfer_to_bonded_winery = '',
		Transfer_to_cbw = '',
		Research_development_or_testing = '',
		Transferred_to_processing_account = '',
		Transferred_to_production_account = '',
		Transferred_to_other_bonded_premises = '',
		Destroyed = '',
		Other_losses = '',
		On_hand_end_of_month = '',
	} = fields;

	return {
		On_hand_first_of_month: part2ReportFields(On_hand_first_of_month),
		Deposited_in_bulk_storage: part2ReportFields(Deposited_in_bulk_storage),
		Received_from_customs_custody: part2ReportFields(
			Received_from_customs_custody,
		),
		Returned_to_bulk_storage: part2ReportFields(Returned_to_bulk_storage),
		Taxpaid: part2ReportFields(Taxpaid),
		Use_of_the_United_States: part2ReportFields(Use_of_the_United_States),
		Hospital_scientific_educational_use: part2ReportFields(
			Hospital_scientific_educational_use,
		),
		Export: part2ReportFields(Export),
		Transfer_to_foreign_trade_zone: part2ReportFields(
			Transfer_to_foreign_trade_zone,
		),
		Transfer_to_cmbw: part2ReportFields(Transfer_to_cmbw),
		Use_as_supplies_on_vessels_and_aircraft: part2ReportFields(
			Use_as_supplies_on_vessels_and_aircraft,
		),
		Transfer_to_bonded_winery: part2ReportFields(Transfer_to_bonded_winery),
		Transfer_to_cbw: part2ReportFields(Transfer_to_cbw),
		Research_development_or_testing: part2ReportFields(
			Research_development_or_testing,
		),
		Transferred_to_processing_account: part2ReportFields(
			Transferred_to_processing_account,
		),
		Transferred_to_production_account: part2ReportFields(
			Transferred_to_production_account,
		),
		Transferred_to_other_bonded_premises: part2ReportFields(
			Transferred_to_other_bonded_premises,
		),
		Destroyed: part2ReportFields(Destroyed),
		Other_losses: part2ReportFields(Other_losses),
		On_hand_end_of_month: part2ReportFields(On_hand_end_of_month),
	};
}
