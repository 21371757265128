/* eslint-disable react-hooks/rules-of-hooks */
import { Close } from '@mui/icons-material';
import { Box, Collapse, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { LineChart, PieChart } from '@mui/x-charts';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import Filter from '@pw/components/icons/Filter';
import { SKU_TYPES } from '@pw/consts/sku';
import { LIQUID_UNITS, UNIT } from '@pw/consts/units';
import { useCompanyCountry } from '@pw/redux/containers/User';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useSkuStatsQuery from '@pw/utilities/hooks/service/useSkuStatsQuery';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SKUItemInfo from '../SKUSelector/items/SKUItemInfo';

const getDateOnly = (timestamp) => {
	const date = new Date(timestamp);
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
	).getTime();
};

const getFirstDayOfCurrentMonth = () => {
	const date = new Date();
	date.setDate(1);
	return date.getTime();
};

const getCurrency = (cc) => {
	switch (cc?.toLowerCase()) {
		case 'gb':
		case 'uk':
		case 'gb-sct':
		case 'gb-eng':
		case 'gb-nir':
			return '£';
		case 'us':
		case 'ca':
		case 'sg':
			return '$';
		default:
			return '€';
	}
};

function LiquidCharts({ data = {}, isLoading }) {
	const { t } = useTranslation();

	const { price_curve = [], data: liquid_data = [] } = data;
	const converter = useConverter();
	const cc = useCompanyCountry();

	const currency = useMemo(() => getCurrency(cc), [cc]);

	const converted_prices = useMemo(() => {
		return price_curve?.map(({ price, unit, date }) => {
			const cp = converter.cx(price, LIQUID_UNITS.liters, unit);
			return { price: cp, unit, date };
		});
	}, [price_curve]);

	const prices = useMemo(() => {
		return (converted_prices ?? []).reduce((acc, { date, price }) => {
			acc[getDateOnly(date)] = price;
			return acc;
		}, {});
	}, [converted_prices]);

	const priceDates = useMemo(() => {
		return Object.keys(prices).sort((a, b) => parseInt(b) - parseInt(a));
	}, [prices]);

	//TODO: need abv/proof here
	const parsed = useMemo(() => {
		return (liquid_data ?? []).reduce(
			(acc, item) => {
				const timestamp = new Date(item?.binned_timestamp).getTime();
				acc.bl.push(converter.to(item?.total_bl ?? 0, UNIT.BL));
				acc.la.push(converter.to(item?.total_la ?? 0, UNIT.LA));
				acc.dates.push(new Date(item.binned_timestamp));

				let price = null;
				for (let date of priceDates) {
					if (timestamp >= parseInt(date)) {
						price = prices[date];
						break;
					}
				}
				acc.price.push(price);
				return acc;
			},
			{ bl: [], la: [], price: [], dates: [] },
		);
	}, [liquid_data, priceDates, prices]);

	if (isLoading) {
		return (
			<>
				<Skeleton variant='rectangular' width='100%' height={300} />
				<H5 className='content-section-title'>Liquids</H5>
				<Skeleton variant='rectangular' width='100%' height={300} />
			</>
		);
	}

	return (
		<>
			<LineChart
				xAxis={[
					{
						id: 'Dates',
						data: (converted_prices ?? []).map(({ date }) => new Date(date)),
						scaleType: 'point',
						valueFormatter: (date) => date.toLocaleDateString(),
					},
				]}
				series={[
					{
						data: (converted_prices ?? []).map(({ price }) => price),
						area: true,
						label: currency,
						color: '#F0AA29',
						showMark: true,
					},
				]}
				height={300}
				title='Price Curve'
			/>

			<H5 className='content-section-title'>Liquids</H5>
			<LineChart
				xAxis={[
					{
						id: 'Dates',
						data: parsed?.dates ?? [],
						scaleType: 'point',
						valueFormatter: (date) => date.toLocaleDateString(),
					},
				]}
				series={[
					{
						data: parsed?.bl ?? [],
						area: true,
						label: t('bulk'),
						color: '#F0AA29',
						showMark: true,
					},
					{
						data: parsed?.la ?? [],
						area: true,
						label: t('alcohol'),
						color: '#1E1C1C',
						showMark: true,
					},
					{
						data: parsed?.price ?? [],
						area: false,
						label: `Price (${currency})`,
						showMark: true,
					},
				]}
				height={300}
				title='Liquid Stats'
			/>
		</>
	);
}

function StatsChart({ data = {}, isLoading }) {
	if (isLoading) {
		return (
			<FlexBox justifyContent='center'>
				<Skeleton variant='circular' width={180} height={180} />
				<Box>
					<Skeleton
						variant='rectangular'
						sx={{ mb: 1 }}
						width={100}
						height='1em'
					/>
					<Skeleton
						variant='rectangular'
						sx={{ mb: 1 }}
						width={100}
						height='1em'
					/>
					<Skeleton
						variant='rectangular'
						sx={{ mb: 1 }}
						width={100}
						height='1em'
					/>
					<Skeleton
						variant='rectangular'
						sx={{ mb: 1 }}
						width={100}
						height='1em'
					/>
				</Box>
			</FlexBox>
		);
	}
	const cc = useCompanyCountry();
	const currency = useMemo(() => getCurrency(cc), [cc]);
	const { data: stats = [] } = data;
	const piaChartData = useMemo(() => {
		const totals = (stats ?? []).reduce(
			(acc, item) => {
				acc.available += Number(item?.total_sku_available ?? 0);
				acc.delivered += Number(item?.total_sku_delivered ?? 0);
				acc.reserved += Number(item?.total_sku_reserved ?? 0);
				return acc;
			},
			{
				available: 0,
				delivered: 0,
				reserved: 0,
			},
		);
		const used = totals?.available - totals?.delivered;
		return [
			{ id: 0, label: 'Available', value: totals?.available, color: '#41424c' },
			{ id: 1, label: 'Delivered', value: totals?.delivered, color: '#F0AA29' },
			{ id: 2, label: 'Reserved', value: totals?.reserved, color: '#FF6F61' },
			{ id: 3, label: 'Used', value: used, color: '#A2D9CE' },
		];
	}, [stats]);

	const lineChartData = useMemo(() => {
		return (stats ?? []).reduce(
			(acc, item) => {
				const total_sku_delivered = item?.total_sku_delivered ?? 0;
				const total_cost = item?.total_cost ?? 0;
				const price = Number(total_cost) / Number(total_sku_delivered);
				acc.prices.push(price);
				acc.items.push(total_sku_delivered);
				acc.dates.push(new Date(item.binned_timestamp));
				return acc;
			},
			{ prices: [], items: [], dates: [] },
		);
	}, [stats]);

	return (
		<>
			<PieChart
				series={[
					{
						data: piaChartData,
						innerRadius: 55,
						outerRadius: 95,
						paddingAngle: 5,
						cornerRadius: 5,
						startAngle: -225,
						endAngle: 45,
						cx: 90,
						cy: 100,
						highlightScope: { faded: 'global', highlighted: 'item' },
						faded: {
							innerRadius: 30,
							additionalRadius: -30,
							color: 'gray',
						},
					},
				]}
				width={300}
				height={220}
			/>
			<H5 className='content-section-title'>Prices</H5>
			<LineChart
				xAxis={[
					{
						id: 'Dates',
						data: lineChartData?.dates ?? [],
						scaleType: 'point',
						valueFormatter: (date) => date.toLocaleDateString(),
					},
				]}
				series={[
					{
						data: lineChartData?.prices ?? [],
						// area: true,
						label: `Avarage Price (${currency})`,
						color: '#F0AA29',
						showMark: true,
					},
					{
						data: lineChartData?.items ?? [],
						// area: true,
						label: 'Delivered',
						color: '#41424c',
						showMark: true,
					},
				]}
				height={300}
				title='Costs'
			/>
		</>
	);
}

function SKUStats({ entity }) {
	const { path, sku_id, sku_type } = entity;
	const {
		refetch,
		data,
		isLoading = false,
	} = useSkuStatsQuery({ sku_id, path }, { skip: !sku_id });
	const [isVisibleFilter, setIsVisibleFilter] = useState(false);
	const changeSet = useMemo(
		() => ({
			start_date: [getFirstDayOfCurrentMonth(), yup.number()],
			end_date: [Date.now(), yup.number()],
		}),
		[],
	);

	const onFilter = useCallback(
		(values) => {
			refetch({ ...values, sku_id, path });
		},
		[refetch, sku_id, path],
	);

	return (
		<Stack spacing={2}>
			<FlexBox>
				<H5 className='content-section-title'>
					{sku_type === SKU_TYPES.LIQUID ? 'Price Curves' : 'Stats'}
				</H5>
				<IconCircleButton onClick={() => setIsVisibleFilter(!isVisibleFilter)}>
					{isVisibleFilter ? <Close /> : <Filter />}
				</IconCircleButton>
			</FlexBox>
			<Collapse in={isVisibleFilter} timeout={250}>
				<Box paddingTop={2} paddingBottom={2}>
					<FormikForm onSubmit={onFilter} changeSet={changeSet}>
						<Stack direction='row' spacing={2} alignItems='center'>
							<FormikDatePicker name='start_date' label='Start Date' />
							<FormikDatePicker name='end_date' label='End Date' />
							<FilledButton type='submit' size='small'>
								Apply
							</FilledButton>
						</Stack>
					</FormikForm>
				</Box>
			</Collapse>
			{sku_type === SKU_TYPES.LIQUID ? (
				<LiquidCharts data={data} isLoading={isLoading} />
			) : (
				<StatsChart data={data} isLoading={isLoading} />
			)}
			{!!data?.entries && !!data?.entries?.length && (
				<Stack>
					<H5>SKU Entries</H5>
					<SKUItemInfo sku={{ ...entity, entries: data?.entries ?? [] }} />
				</Stack>
			)}
		</Stack>
	);
}

export default SKUStats;
