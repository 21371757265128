import * as yup from 'yup';

export default function part1Fields(fields = {}) {
	const {
		Report_Year = '',
		typeOfPlant = 1,
		Proprietor_Name = '',
		address1 = '',
		address2 = '',
		city = '',
		state = '',
		zipCode = '',
	} = fields;

	return {
		Report_Year: [
			Report_Year,
			yup.string().required('Report year is required!'),
		],
		typeOfPlant: [
			typeOfPlant,
			yup.number().required('Type of plant is required!'),
		],
		Proprietor_Name: [
			Proprietor_Name,
			yup.string().required('Proprietor name is required!'),
		],
		address1: [address1, yup.string().required('Address 1 is required!')],
		address2: [address2, yup.string().nullable()],
		city: [city, yup.string().required('City is required!')],
		state: [state, yup.string().required('State is required!')],
		zipCode: [zipCode, yup.string().required('Zip code is required!')],
	};
}
