import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import templateFields from '@pw/components/TemplateHandler/templateFields';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { FormikProvider } from '@pw/providers/FormikProvider';
import processLiquid from '@pw/utilities/adapters/processLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}, converter) {
	const { tags = [], liquid = {} } = fields;

	return {
		tags: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		liquid: liquidPropertyFields(liquid, converter, true, true),
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Filling Tank Properties</H5>
				<TagSelect
					name='tags'
					type='fillingTank'
				/>
			</Stack>
			<LiquidProperties
				name='liquid'
				useWeight
				useBulk={false}
				useLiquidAccount
			/>
		</FormikProvider>
	);
}

function TankForm(props) {
	const converter = useConverter();

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			properties: propertyFields(initialValues.properties, converter),
		}),
		[converter],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
		};
	};

	return (
		<AssetForm
			assetLabel='Filling Tank'
			assetType={ASSET_TYPES.filling_tank}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</AssetForm>
	);
}

export default withAppLayout(TankForm, { title: 'Filling Tank Management' });
