export const localStorageKeyRecipe = 'pw_recipes';
export const localStorageKeyBatch = 'pw_batches';

export const isBatch = (item) => Boolean(item.recipe_id);

export const localStorageKey = (item) => isBatch(item)
  ? localStorageKeyBatch
  : localStorageKeyRecipe;

const getNameKey = (item) => isBatch(item) ? 'batchName' : 'recipeName';
export const getItemName = (item) => item[getNameKey(item)];


export const saveToLS = (item) => {
  const lsKey = localStorageKey(item);
  const itemName = getItemName(item);
  console.log('saveToLS', itemName, item);

  const _items = JSON.parse(localStorage.getItem(lsKey) || '[]');
  // remove any previous entry with same id
  const items = _items.filter((r) => (r.entity_id) !== item.entity_id);
  // add this one in its place
  items.push(item);
  localStorage.setItem(lsKey, JSON.stringify(items))
}

export const getFromLS = (type) => {
  const lsKey = type === 'batch' ? localStorageKeyBatch : localStorageKeyRecipe;
  const items = JSON.parse(localStorage.getItem(lsKey) || '[]');
  console.log(`get:${type} => ${items.length}`);
  return items;
}
