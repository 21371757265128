import generalInformationFields from './generalInformationFields';
import section1Fields from './section1Fields';
import section2Fields from './section2Fields';
import section3Fields from './section3Fields';
import signatureFields from './signatureFields';

export default function TTBReport_43Fields(fields = {}) {
	const { general_information, section1, section2, section3, signature } =
		fields;

	return {
		general_information: generalInformationFields(general_information),
		section1: section1Fields(section1),
		section2: section2Fields(section2),
		section3: section3Fields(section3),
		signature: signatureFields(signature),
	};
}
