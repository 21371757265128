import { Close } from '@mui/icons-material';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
} from '@mui/material';
import QRImage from '@pw/components/QRImage';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useCompanyId } from '@pw/redux/containers/User/hooks';
import { useCallback, useMemo, useRef } from 'react';

export function ShareWithPartnerDialogQR({ open, onClose, partnerId }) {
	const printRef = useRef();
	const companyId = useCompanyId();
	const qq = btoa(companyId);
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const qrUrl = `${origin}/admin/partners?qq=${qq}&q=${btoa(partnerId)}`;

	const handleOnClose = useCallback(() => onClose(), [onClose]);

	return (
		<Dialog open={open} onClose={handleOnClose} maxWidth='xs' scroll='paper'>
			<IconButton
				aria-label='close'
				onClick={handleOnClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<DialogTitle>Share your company QR Code</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Stack alignItems={'center'} spacing={1}>
						<Box ref={printRef} className='qr'>
							<QRImage size={600} boxSize={400} value={qrUrl} />
						</Box>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
