import * as yup from 'yup';

export function locationFields(fields = {}) {
	const {
		enable = true,
		facility_id = '',
		bay = '',
		row = '',
		level = '',
	} = fields;

	return {
		enable: [enable, yup.bool()],
		facility_id: [
			facility_id,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Facility is required!'),
				otherwise: (schema) => schema,
			}),
		],
		bay: [
			bay,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Bay is required!'),
				otherwise: (schema) => schema,
			}),
		],
		row: [row, yup.string()],
		level: [level, yup.string()],
	};
}
