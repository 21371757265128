import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';
import toLocation from '@pw/utilities/adapters/toLocation';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import useAlertView from '@pw/utilities/hooks/components/useAlertView';
import { useCallback, useEffect, useMemo } from 'react';

// export interface SensorCoverage extends DynamoEntry {
//   // id: companyPK(company_id),
//   // path: /sn/uniqLocation
//   location: UniqueLocationKey;
//   // date: number; ?
// }

export const generateFastestUUID = () => {
	let date = new Date().getTime();
	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		// eslint-disable-next-line no-bitwise
		const r = (date + Math.random() * 16) % 16 | 0;
		date = Math.floor(date / 16);
		// eslint-disable-next-line no-bitwise, no-mixed-operators
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};

function SensorCoverageModal({ entity, open, upsert, onClose }) {
	const [AlertView, { setAlert }] = useAlertView();
	const editing = useMemo(() => !!entity?.path, [entity]);
	const title = useMemo(
		() => (editing ? 'Edit Coverage Location' : 'New Coverage Location'),
		[editing],
	);

	const changeSet = {
		location: locationFields({
			enable: true,
			...toLocation(entity?.path?.replace('/sn/', '')),
		}),
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setAlert(null), []);

	const handleSubmit = useCallback(
		async (values) => {
			setAlert(null);
			try {
				upsert({
					...entity,
					id: entity?.id ?? generateFastestUUID(),
					path: `/sn/${toUniqueLocation(values.location)}`,
				});
				onClose();
			} catch (err) {
				setAlert({
					severity: 'error',
					title: editing ? 'Failed to Update' : 'Failed to Add',
					content: err.message,
				});
			}
		},
		[editing, entity, onClose, setAlert, upsert],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{title}</H5>
					<IconButton onClick={() => onClose()} className='close-btn' aria-label='Close'>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<FormikForm changeSet={changeSet} onSubmit={handleSubmit} enableReinitialize>
					<Stack spacing={1}>
						<Location hide={false} name='location' showCheckBox={false} />
						<AlertView />
						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								{editing ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

export default SensorCoverageModal;
