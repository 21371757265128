import Box from '@mui/material/Box';
import { useRef } from 'react';
import { QrReader } from './react-qr-reader';

const QRCodeScanner = ({
	className,
	onSuccess,
	facingMode = 'environment',
	onError,
	...rest
}) => {
	const cacheList = useRef([]);

	const onResult = (data, error) => {
		if (data && !cacheList.current.includes(data?.text)) {
			cacheList.current.push(data?.text);
			if (typeof onSuccess === 'function') onSuccess(data);
			// will fire continuously until recognises a QRCode
		} else if (typeof onError === 'function') onError(data, error);
	};

	return (
		<Box className={className}>
			<QrReader
				key={facingMode}
				constraints={{
					facingMode,
					video: {
						pan: true,
						tilt: true,
						zoom: true,
					},
				}}
				delay={300}
				onResult={onResult}
				style={{ position: 'absolute', height: '100%', width: '100%' }}
				{...rest}
			/>
		</Box>
	);
};

export default QRCodeScanner;
