import {
	Autocomplete,
	Collapse,
	createFilterOptions,
	Stack,
} from '@mui/material';
import {
	FormikCheckBox,
	FormikCountrySelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import usePartnerSearch from '@pw/utilities/hooks/logic/usePartnerSearch';
import { useCallback, useContext } from 'react';

const filter = createFilterOptions();

function CompanyAddressSearchSelection({ name, label, ...props }) {
	const { values, setFieldValue } = useContext(FormikContext);
	const [options, debouncedPartnerSearch] = usePartnerSearch();
	const handleOnInputChangePartner = useCallback(
		(_event, newInputValue) => debouncedPartnerSearch(newInputValue),
		[debouncedPartnerSearch],
	);

	const handleOnChange = useCallback(
		(event, value) => {
			console.log('handleOnChange', value);
			value?.['excise_id'] && setFieldValue('exciseId', value['excise_id']);
			value?.['address_1'] && setFieldValue('address_1', value['address_1']);
			value?.['country'] && setFieldValue('country', value['country']);
			value?.['address_3'] && setFieldValue('address_3', value['address_3']);
			value?.['address_2'] && setFieldValue('address_2', value['address_2']);
			value?.['state'] && setFieldValue('state', value['state']);
			value?.['city'] && setFieldValue('city', value['city']);
			value?.['post_code'] && setFieldValue('post_code', value['post_code']);
			value?.['company_name'] && setFieldValue('name', value['company_name']);
		},
		[setFieldValue],
	);

	const onFilterOptions = useCallback((options, params) => {
		const filtered = filter(options, params);
		const { inputValue } = params;
		// Suggest the creation of a new value
		const isExisting = options.some(
			(option) => inputValue === option.company_name,
		);
		if (inputValue !== '' && !isExisting) {
			filtered.push({
				company_name: inputValue,
				title: `Use "${inputValue}" as company name`,
			});
		}
		return filtered;
	}, []);

	return (
		<Autocomplete
			// getOptionLabel={(option) => option?.company_name ?? ''}
			getOptionLabel={(option) =>
				typeof option === 'string'
					? option
					: (option?.title ?? option?.company_name)
			}
			renderInput={(params) => (
				<FormikTextField {...params} variant='filled' label={label} />
			)}
			value={values?.[name]}
			onChange={handleOnChange}
			onInputChange={handleOnInputChangePartner}
			noOptionsText={'Type to search partner'}
			options={options}
			// filterOptions={(x) => x}
			filterOptions={onFilterOptions}
			filterSelectedOptions
			autoComplete
			{...props}
		/>
	);
}

function AddressImpl({
	label,
	isOptional,
	withCompany,
	countryValueField = 'key',
	disabled = false,
}) {
	const { values } = useContext(FormikContext);

	return (
		<Stack>
			{isOptional && <FormikCheckBox name='enable' label={<H5>{label}</H5>} />}

			<Collapse in={!!values?.enable}>
				<Stack spacing={2}>
					{withCompany && (
						<>
							<CompanyAddressSearchSelection
								title='name'
								name='name'
								label='Company Name'
								fullWidth
								required
							/>
							<FlexBox alignItems='top'>
								<FormikTextField
									title='exciseId'
									name='exciseId'
									label='Excise ID'
									fullWidth
								/>
								<FormikTextField
									title='warehouseId'
									name='warehouseId'
									label='Warehouse ID'
									fullWidth
								/>
							</FlexBox>
						</>
					)}

					<FormikTextField
						disabled={disabled}
						title='address_2'
						name='address_2'
						label='Building'
						fullWidth
					/>
					<FormikTextField
						disabled={disabled}
						title='address_1'
						name='address_1'
						label='Street/Number'
						fullWidth
						required
					/>

					<FlexBox alignItems='top'>
						<FormikTextField
							disabled={disabled}
							title='address_3'
							name='address_3'
							label='Town'
							fullWidth
						/>
						<FormikTextField
							disabled={disabled}
							title='city'
							name='city'
							label='City'
							fullWidth
							required
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							disabled={disabled}
							title='state'
							name='state'
							label='State'
							fullWidth
						/>
						<FormikTextField
							disabled={disabled}
							title='postCode'
							name='post_code'
							label='Postal Code'
							fullWidth
							required
						/>
					</FlexBox>

					<FormikCountrySelect
						disabled={disabled}
						name='country'
						label='Country'
						valueField={countryValueField}
						fullWidth
						required
					/>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function Address({
	name,
	label,
	isOptional = true,
	withCompany = true,
	countryValueField = 'key',
	disabled,
}) {
	return (
		<FormikProvider path={name}>
			<AddressImpl
				label={label}
				isOptional={isOptional}
				withCompany={withCompany}
				countryValueField={countryValueField}
				disabled={disabled}
			/>
		</FormikProvider>
	);
}
