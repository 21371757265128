import reverseKeyValue from "@pw/utilities/reverseKeyValues";

export const UNIT = {
	ALC: 'alc',
	LIQ: 'liq',
	MAS: 'mas',
	TIM: 'tim',
	TMP: 'tmp',
	BL: 'bl',				// bulk or wine gallon
	LA: 'la',				// liters of alcohol or proof gallons
};

export const UNITS = {
	// liquid
	milliliters: 'ml',
	fluid_ounces: 'fl oz',
	liters: 'l',
	gallons: 'gal',
	proof_gallons: 'pg',
	// mass
	grams: 'g',
	ounces: 'oz',
	kilograms: 'kg',
	pounds: 'lb',
	stones: 'st',
	tonnes: 't',
	short_tons: 't [US]', // needs mapping
	long_tons: 't [I]', 	// needs mapping
	// alcohol
	abv: '%',
	proof: '°',
	// count
	percentage: 'perc',
	count: 'count',
	// Time
	days: 'd',
	weeks: 'wk',
	months: 'mo',
	// temperature
	celsius: 'C',
	fahrenheit: 'F',
	kelvin: 'K',
};

export const UNITS_REVERSE = reverseKeyValue(UNITS);

export const UNIT_RANGE = {
	[UNITS.abv]: [0, 100],
	[UNITS.proof]: [2, 200],
};

// Need to map to standard units
export const UNIT_MAP = {
	't [US]': 'US tons',
	't [I]': 'Imperial ton',
	'pg': 'gal',
};

// // Conversion factors
// export const UNITS_CONVERSIONS = {
// 	// Liquid conversions
// 	'ml to l': 1 / 1000,
// 	'fl oz to l': 1 / 33.814,
// 	'gal to l': 3.78541,
// 	'l to ml': 1000,
// 	'l to fl oz': 33.814,
// 	'l to gal': 1 / 3.78541,

// 	// Weight conversions
// 	'g to kg': 1 / 1000,
// 	'oz to kg': 1 / 35.274,
// 	'lb to kg': 1 / 2.20462,
// 	't to kg': 1000,
// 	't[us] to kg': 907.185,
// 	'kg to g': 1000,
// 	'kg to oz': 35.274,
// 	'kg to lb': 2.20462,
// 	'kg to t': 1 / 1000,
// 	'kg to t[us]': 1 / 907.185,

// 	// Alcohol conversions
// 	'% to °': 2,
// 	'° to %': 1 / 2,

// 	// Conversions for time
// 	'd to w': 1 / 7,
// 	'd to m': 1 / 30,
// 	'w to d': 7,
// 	'w to m': 1 / 4,
// 	'm to d': 30,

// 	// Temperature conversions
// 	'°C to °F': (c) => (c * 9) / 5 + 32,
// 	'°F to °C': (f) => ((f - 32) * 5) / 9,
// 	'°C to K': (c) => c + 273.15,
// 	'K to °C': (k) => k - 273.15,
// 	'°F to K': (f) => ((f - 32) * 5) / 9 + 273.15,
// 	'K to °F': (k) => ((k - 273.15) * 9) / 5 + 32,
// };

export const ABV_UNITS = {
	abv: UNITS.abv,
	proof: UNITS.proof,
};

export const ABV_UNIT_OPTIONS = [
	{ label: 'ABV', value: UNITS.abv },
	{ label: 'Proof', value: UNITS.proof },
];

export const LIQUID_UNITS = {
	milliliters: UNITS.milliliters,
	fluidOunces: UNITS.fluidOunces,
	liters: UNITS.liters,
	gallons: UNITS.gallons,
	proof_gallons: UNITS.proof_gallons,
};

export const LIQUID_UNIT_OPTIONS = [
	{ label: 'Milliliters', value: UNITS.milliliters },
	{ label: 'Fluid Ounces', value: UNITS.fluidOunces },
	{ label: 'Liters', value: UNITS.liters },
	{ label: 'Gallons', value: UNITS.gallons },
	{ label: 'Proof Gallons', value: UNITS.proof_gallons },
];

export const MASS_UNITS = {
	grams: UNITS.grams,
	ounces: UNITS.ounces,
	kilograms: UNITS.kilograms,
	pounds: UNITS.pounds,
	tonnes: UNITS.tonnes,
	tons: UNITS.tons,
};

export const MASS_UNIT_OPTIONS = [
	{ label: 'Grams', value: UNITS.grams },
	{ label: 'Ounces', value: UNITS.ounces },
	{ label: 'Kilograms', value: UNITS.kilograms },
	{ label: 'Pounds', value: UNITS.pounds },
	{ label: 'Stones', value: UNITS.stones },
	{ label: 'Tonnes', value: UNITS.tonnes },
	{ label: 'Tons [US]', value: UNITS.short_tons },
	{ label: 'Tons [I]', value: UNITS.long_tons },
];

export const TIME_UNITS = {
	days: UNITS.days,
	weeks: UNITS.weeks,
	months: UNITS.months,
};

export const TIME_UNIT_OPTIONS = [
	{ label: 'Days', value: UNITS.days },
	{ label: 'Weeks', value: UNITS.weeks },
	{ label: 'Months', value: UNITS.months },
];

export const TEMPERATURE_UNITS = {
	celsius: UNITS.celsius,
	fahrenheit: UNITS.fahrenheit,
	kelvin: UNITS.kelvin,
};

export const TEMPERATURE_UNIT_OPTIONS = [
	{ label: 'Celsius', value: UNITS.celsius },
	{ label: 'Fahrenheit', value: UNITS.fahrenheit },
	{ label: 'Kelvin', value: UNITS.kelvin },
];

export const UNITS_OPTIONS = [
	...ABV_UNIT_OPTIONS,
	...LIQUID_UNIT_OPTIONS,
	...MASS_UNIT_OPTIONS,
	...TIME_UNIT_OPTIONS,
	...TEMPERATURE_UNIT_OPTIONS,
	{ label: 'Count', value: UNITS.count },
	{ label: 'Percentage', value: UNITS.percentage },
];

