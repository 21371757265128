import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { H4 } from '@pw/components/Typography';
import { memo, useCallback, useEffect, useState } from 'react';
import { iconFromName } from '../nodes';
import styles from './NodeModal.styles';

import FormValuesContext, {
	useFormValuesContext,
} from '@pw/context/FormValuesContext';
import LookupSKU from './LookupSKU';

const Field = memo(({ fname, label, type = 'text', node, ...rest }) => {
	const { values, setValues, setField, getField, setRef } =
		useFormValuesContext();
	// console.log({ values, setValues, setField });

	// useEffect(() => {
	//   console.log({ values });
	// }, [values]);

	const defaultValue = getField(fname);
	console.log({ defaultValue });

	return (
		fname && (
			<Box className='input-root'>
				<InputLabel for={fname}>{label}</InputLabel>

				<TextField
					key={fname}
					id={fname}
					type={type}
					name={fname}
					onChange={({ target }) => {
						setField(fname, target.value);
					}}
					defaultValue={defaultValue || ''}
					// defaultValue={node?.data?.[name] || ''}
					// value={/* values?.[fname] || */ node?.data?.[fname] || ''}
					// {...rest}
				/>
			</Box>
		)
	);
});
Field.displayName = 'Field';

const NodeModal = ({ node, updateNode, isOpen, setSelectedNode }) => {
	const [nodeData, setNodeData] = useState(node?.data || {});

	useEffect(() => {
		console.log({ nodeData });
	}, [nodeData]);

	useEffect(() => {
		if (!node?.data) return;
		setNodeData(node.data);
	}, [node?.data]);

	const saveHandler = useCallback(() => {
		console.log('saving', nodeData);
		updateNode(node.id, nodeData);
		setNodeData({});
		setSelectedNode(null);
	}, [nodeData, node?.id]);

	// const [refs, setRefs] = useState();

	return (
		node && (
			<Modal
				disableAutoFocus={true}
				disableRestoreFocus={true}
				open={isOpen}
				sx={styles}
				onClose={() => setSelectedNode(null)}
				slotProps={{
					backdrop: {},
				}}
			>
				<Box className='modal-content'>
					<Box className='header'>
						<Box
							className={`icon-container 
          icon-${node.data.category}`}
						>
							{iconFromName(node.data.icon)}
						</Box>
						<Box className=''>
							<H4 className='title'>
								{node.data.label}
								{node.data.category === 'process' && ' Settings'}
							</H4>
						</Box>
					</Box>
					{/* {children} */}
					<Box className='form-root'>
						{/* obvs we'll defined a bunch of fields and schema to map them to process nodes, but for now, some add-hock fields to demo updating a node's data */}

						<FormValuesContext
							externalState={nodeData}
							externalSetState={setNodeData}
							// externalRefs={refs} externalSetRefs={setRefs}
						>
							{(node.data.type === 'grain' || node.data.type === 'yeast') && (
								<LookupSKU type={node.data.type} />
							)}

							{node.data.type.indexOf('mill') !== -1 && (
								<>
									{/* <TextField
                type="number"
                name="batch_qty"
                onChange={(e) => {
                  setNodeData((prev) => ({
                    ...prev,
                    batch_qty: e.target.value
                  }))
                }}
                defaultValue={node.data.batch_qty || ''}
              /> */}
									<Field
										// type='number'
										fname='batch_qty'
										label='Batch Quantity (kg)'
									/>
									{/* <TextField
                type="number"
                name="period"
                onChange={(e) => {
                  setNodeData((prev) => ({
                    ...prev,
                    period: e.target.value
                  }))
                }}
                defaultValue={node.data.period || ''}
              /> */}
									<Field
										// type='number'
										fname='period'
										label='Period (hr)'
									/>
									<Box className='input-root'>
										<InputLabel for='grain_size'>Grain Size</InputLabel>
										<Select
											id='grain_size'
											onChange={({ target }) => {
												setNodeData((prev) => ({
													...prev,
													grain_size: target.value,
												}));
											}}
											value={node.data?.grain_size}
										>
											<MenuItem value='finest'>Finest</MenuItem>
											<MenuItem value='fine'>Fine</MenuItem>
											<MenuItem value='medium'>Medium</MenuItem>
											<MenuItem value='coarse'>Coarse</MenuItem>
										</Select>
									</Box>
								</>
							)}

							{node.data.type.indexOf('water') !== -1 && (
								<>
									<Field
										// type='number'
										fname='amount'
										label='Amount (L)'
									/>

									<Field
										// type='number'
										fname='temperature'
										label='Temperature (&deg;C)'
									/>

									<Field
										// type='number'
										fname='duration'
										label='Duration (hr)'
									/>
								</>
							)}
						</FormValuesContext>

						<FilledButton
							size='small'
							className='save-button'
							handleClick={saveHandler}
							disabled={!Object.keys(nodeData || {}).length}
						>
							Save
						</FilledButton>
					</Box>
				</Box>
			</Modal>
		)
	);
};

export default NodeModal;
