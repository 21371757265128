import { Box } from '@mui/material';
import AssetID from '@pw/components/AssetID';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import StorageChargeCodeProperties from '@pw/components/ChargeCode/StorageChargeCode';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { UNIT } from '@pw/consts/units';
import {
	useAccountChain,
	useAccountPlatform
} from '@pw/redux/containers/User';
import { upsertAssetThunk } from '@pw/redux/thunks/asset';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import convertProperties from '../../utilities/convertProperties';

function AssetForm({
	id,
	assetType,
	entity,
	changeSet,
	readonly = false,
	edit = false,
	onCancel = () => {},
	onBeforeSave = () => {},
	onSave = () => {},
	children,
}) {
	const dispatch = useDispatch();
	const converter = useConverter();

	const platform = useAccountPlatform();
	const chain = useAccountChain();

	const isAssetDefected = useMemo(
		() => entity?.asset_status === ASSET_STATUS.DEFECTED,
		[entity],
	);

	const handleSubmit = (values) => {
		const updated = onBeforeSave ? onBeforeSave(values) : values;
		console.log('Submitting', values, updated);
		const properties = convertProperties(updated?.properties ?? {}, converter);

		dispatch(upsertAssetThunk({
			...entity,
			...updated,
			properties,
			asset_type: assetType,
			platform,
			chain,
		}))
			.unwrap()
			.then((response) => onSave(response));
	};

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			edit={edit}
			readonly={readonly}
		>
			<FormWrapper>
				<AssetID type={assetType} label='ID.' />

				{children}

				{!readonly &&
					[ASSET_TYPES.cask, ASSET_TYPES.ibc].includes(assetType) && (
						<StorageChargeCodeProperties name='charge_code' />
					)}

				{!readonly && !isAssetDefected && (
					<>
						<Errors />
						<Box className='action-buttons'>
							{onCancel && (
								<TextButton
									size='small'
									handleClick={onCancel}
									color='secondary'
								>
									Cancel
								</TextButton>
							)}
							<FilledButton type='submit' size='small'>
								{id?.length ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</>
				)}
			</FormWrapper>
		</FormikForm>
	);
}

export default AssetForm;
