import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import {
	FormikCheckBox,
	FormikRadioGroup,
	FormikSelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext, useState } from 'react';
import { FlexBox } from '../Layout/FlexBox';
import controlStates from '@pw/consts/controlStates.js';
import FormikContext from '@pw/context/FormikContext';

const years = Array.from({ length: 30 }, (_, i) => 2021 + i);
const months = [
	{ value: '1', label: 'January' },
	{ value: '2', label: 'February' },
	{ value: '3', label: 'March' },
	{ value: '4', label: 'April' },
	{ value: '5', label: 'May' },
	{ value: '6', label: 'June' },
	{ value: '7', label: 'July' },
	{ value: '8', label: 'August' },
	{ value: '9', label: 'September' },
	{ value: '10', label: 'October' },
	{ value: '11', label: 'November' },
	{ value: '12', label: 'December' },
];
const returnTypes = [
	{ value: '1', label: 'Original Return' },
	{ value: '2', label: 'Amended Return' },
];

export default function GeneralInformation({
	name,
	title = 'General Information',
	disabled = false,
}) {
	const { values } = useContext(FormikContext);
	const [expanded, setExpanded] = useState(true);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<FormikTextField
						name='plantNumber'
						label='Plant Number'
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='nameOfProprietor'
						label='Name of Proprietor'
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='doingBusinessAs'
						label='Doing Business As'
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='streetAddress'
						label='Street Address'
						required
						fullWidth
						disabled={disabled}
					/>
					<FlexBox spacing={2} alignItems='top'>
						<FormikTextField
							name='city'
							label='City'
							required
							fullWidth
							disabled={disabled}
						/>
						<FormikSelect
							name='state'
							label='State'
							options={controlStates}
							value={values.general_information?.state || ''}
							required
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='zipCode'
							label='Zip Code'
							required
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FormikRadioGroup
						name='reportType'
						label='Report Type'
						options={returnTypes}
						required
						disabled={disabled}
					/>
					<Stack direction='row' alignItems='center'>
						<FormikCheckBox
							name='isFinal'
							label={'Final'}
							disabled={disabled}
						/>
					</Stack>
					<FlexBox spacing={2} alignItems='top'>
						<FormikSelect
							name='reportingPeriodYear'
							label='Reporting Period Year'
							options={years.map((year) => ({ value: year, label: year }))}
							required
							fullWidth
							disabled={disabled}
						/>
						<FormikSelect
							name='reportingPeriodMonth'
							label='Reporting Period Month'
							options={months}
							required
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
				</>
			)}
		</FormikProvider>
	);
}
