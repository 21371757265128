import * as yup from 'yup';

export default function signatureFields(fields = {}) {
	const { PROPRIETOR1 = '', TITLE = '' } = fields;

	return {
		PROPRIETOR1: [
			PROPRIETOR1,
			yup.string().required('PROPRIETOR1 is required!'),
		],
		TITLE: [TITLE, yup.string().required('TITLE is required!')],
	};
}
