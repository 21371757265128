import AssetSelectorModal from '@pw/components/InventorySelector/AssetSelectorModal';
import { InventorySelectorV2 } from '@pw/components/InventorySelector/index';
import AssetInfo from '@pw/components/SKUSelector/items/AssetInfo';
import SKUItemInfo from '@pw/components/SKUSelector/items/SKUItemInfo';
import { Body1 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import {
	ASSET_PROCESSED_STATUS,
	REQUEST_STATUS,
	REQUEST_TYPES,
} from '@pw/consts/requests';
import { setActiveListItem } from '@pw/redux/containers/App';
import { useCompanyId } from '@pw/redux/containers/User/hooks';
import toSKUItem from '@pw/utilities/adapters/toSKUItem';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import handleOnQrRead from '@pw/utilities/handleOnQrRead';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useAssetApproveHook from '@pw/utilities/hooks/logic/useAssetApproveHook';
import useAssetConfirmHook from '@pw/utilities/hooks/logic/useAssetConfirmHook';
import { useIsSameCompany } from '@pw/utilities/hooks/logic/useCheckCompany';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useParentAssetHook from '@pw/utilities/hooks/logic/useParentAssetHook';
import useSkuApproveHook from '@pw/utilities/hooks/logic/useSkuApproveHook';
import useSkuAssetPick from '@pw/utilities/hooks/logic/useSkuAssetPick';
import useSkuConfirmHook from '@pw/utilities/hooks/logic/useSkuConfirmHook';
import { useToggleState } from '@pw/utilities/hooks/logic/useToggleState';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useRequestLazyQuery } from '@pw/utilities/hooks/service/useRequestQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import UploadAssetsModal from './UploadAssetsModal';
import AssetFillModal from './AssetFillModal';

function useInventorySelectorHook(props) {
	const {
		title,
		filter = {},
		assetFilter,
		sku_filter = [],
		single = false,
		assetAdapter = toTaggedAsset,
		assetConverter = (i, s) => toTaggedAsset(i, s),
		extendedSummary = false,
		dutyPaidSummary = false,
		initialSkus = [],
		initialAssets = [],
		displaySku = (value) => <SKUItemInfo sku={value} />,
		displayAsset = (value) => <AssetInfo item={value} />,
		submitForm,
		SKUModal,
		skuModalProps = {},
		AssetModal,
		assetModalProps = {},
		parentAssetSupport = false,
		onSKUAdded,
		onSKURemove,
		allowAssetSelector = true,
	} = props;

	const { enqueueSnackbar } = useSnackbar();
	const isSameCompany = useIsSameCompany();

	const confirm = useConfirm();
	const dispatch = useDispatch();

	const [fetchAsset, { isLoading: assetLoading }] = useAssetLazyQuery();
	const [fetchRequest, { isLoading: requestLoading }] = useRequestLazyQuery();

	const companyId = useCompanyId();

	const [autoSubmit, setAutoSubmit] = useState(true);
	const [enable, setEnable] = useState(true);
	const [reports, setReports] = useState(false);
	const [fill, toggleFill] = useToggleState(false);
	const [upload, toggleUpload] = useToggleState(false);
	const [currentRequest, setCurrentRequest] = useState({});

	const [skus, initSkus, , upsertSkus, removeSkus] = useItemListManager(
		ID.sku,
		COMP.sku,
		initialSkus ?? [],
	);

	const [assets, initAssets, , upsertAssets, removeAssets] = useItemListManager(
		ID.asset,
		COMP.asset,
		initialAssets ?? [],
	);

	const caskExists = useMemo(() => {
		return !!assets?.filter((i) => i.asset_type === ASSET_TYPES.cask)?.length;
	}, [assets]);

	const [applyParentAsset, changeParentAsset, closeParentAsset] =
		useParentAssetHook(parentAssetSupport, assets, upsertAssets);

	// This will make sure any picked assets match the skus
	useSkuAssetPick(assets, skus, upsertSkus);

	// Used to confirm skus/assets based on a scan
	const skuConfirmHook = useSkuConfirmHook(skus, upsertSkus);
	const assetConfirmHook = useAssetConfirmHook(assets, upsertAssets);

	// This is used to approve all the skus/assets
	const approveAllSkus = useSkuApproveHook(skus, upsertSkus);
	const approveAllAssets = useAssetApproveHook(assets, upsertAssets);

	// Currently selected sku
	const [selectedSku, setSelectedSkuImpl] = useState(null);

	// Currently selected asset
	const [selectedAsset, setSelectedAssetImpl] = useState(null);

	// This is used on first load of a pallet to select the assets from the pallet
	const [assetSelector, setAssetSelector] = useState(null);

	const setSelectedAsset = useCallback(
		(item) => {
			setSelectedAssetImpl(item);
			if (item) {
				dispatch(setActiveListItem(item));
			}
		},
		[dispatch],
	);

	const setSelectedSku = (item) => {
		setSelectedSkuImpl(item);
		if (item) {
			dispatch(setActiveListItem(item));
		}
	};

	const addNewSku = (sku) => {
		if (SKUModal) {
			setSelectedSku(sku);
		} else {
			upsertSkus(sku);
			if (onSKUAdded) {
				onSKUAdded(sku);
			}
		}
	};

	const addNewAsset = useCallback(
		(asset, scan) => {
			console.log(
				'>>addNewAsset',
				scan,
				asset.rw_asset_id,
				asset.path,
				asset.asset_id,
				asset.asset_type,
			);
			const convertedAsset = assetConverter(asset, scan);
			if (AssetModal && (!assetFilter || assetFilter(asset))) {
				setSelectedAsset(convertedAsset);
			} else {
				upsertAssets(convertedAsset);
			}
		},
		[AssetModal, assetConverter, assetFilter, setSelectedAsset, upsertAssets],
	);

	const init = useCallback(
		(entity) => {
			debounce(() => {
				const {
					assets = [],
					skus = [],
					request_status = REQUEST_STATUS.PENDING,
					company_id,
				} = entity;
				console.log('>>init', title, company_id, request_status);
				setCurrentRequest({ ...entity });
				if (!isSameCompany(company_id)) {
					setEnable(false);
				} else {
					if (
						[
							REQUEST_STATUS.DONE,
							REQUEST_STATUS.CANCELLED,
							REQUEST_STATUS.REJECTED,
							REQUEST_STATUS.ARCHIVED,
						].includes(request_status)
					) {
						setEnable(false);
					}
					if (![REQUEST_STATUS.PENDING].includes(request_status)) {
						setReports(true);
					}
				}
				initSkus(skus);
				initAssets(assets);
			}, 50);
		},
		[setEnable, setReports, isSameCompany, initSkus, initAssets],
	);

	/**
	 * On editing a SKU, remove any linked assets
	 * @type {(function(*): void)|*}
	 */
	const removeLinkedAssets = useCallback(
		(sku) => {
			if (sku.include_holder) {
				// Get the entries and storage within that
				const entries = sku.entries ?? [];
				const assetIds = [];
				entries.forEach((e) => {
					const { storage = [] } = e;
					storage.forEach((s) => {
						assetIds.push(s.asset_id);
					});
				});

				const remainingAssets = assets.filter(
					(a) => !assetIds.includes(a.asset_id),
				);
				debounce(() => initAssets(remainingAssets), 50);
			}
		},
		[assets, initAssets],
	);

	/**
	 * Load the asset details from the back-end
	 * @type {(function(*): void)|*}
	 */
	const loadAsset = useCallback(
		(assetId, scan = false) => {
			console.log('>>loadAsset', assetId, scan);
			fetchAsset(assetId)
				.then((res) => {
					const { child_assets = [] } = res;
					console.log(
						' --> loaded asset',
						res.rw_asset_id,
						child_assets.length,
					);

					if (allowAssetSelector && child_assets.length > 0) {
						setAssetSelector({
							...res,
							processed: scan
								? ASSET_PROCESSED_STATUS.CONFIRMED
								: ASSET_PROCESSED_STATUS.PENDING,
						});
					} else {
						const adaptedAsset = assetAdapter(res);
						if (adaptedAsset) {
							const updatedAsset = changeParentAsset(adaptedAsset);
							addNewAsset(updatedAsset, scan);
						} else {
							enqueueSnackbar(`Failed to add asset!`, { variant: 'error' });
						}
					}

				})
				.catch((e) => {
					console.log('Get asset', e);
					enqueueSnackbar(`Failed to find asset!`, { variant: 'error' });
					throw e;
				});
		},
		[
			fetchAsset,
			setAssetSelector,
			setSelectedAsset,
			upsertAssets,
			enqueueSnackbar,
			assetAdapter,
			changeParentAsset,
		],
	);

	/**
	 * Conditionally add an asset
	 * @type {(function(*): void)|*}
	 */
	const addAsset = useCallback(
		(asset) => {
			// Now, we know at this stage, this asset doesn't exist in our list
			loadAsset(asset.path);
		},
		[loadAsset],
	);

	/**
	 * Conditionally add a request
	 * @type {(function(*): void)|*}
	 */
	const addRequest = useCallback(
		(request) => {
			// Limited set of requests can be injected here..
			if (
				[REQUEST_TYPES.pick, REQUEST_TYPES.change_ownership].includes(
					request.request_type,
				)
			) {
				console.log('>>addRequest', request.rw_request_id);
				fetchRequest(request.path)
					.then((r) => {
						// From the pick, extract the skus and assets, and add them all..
						const { sources = [], sku_sources = [] } = r;
						console.log(
							' -> sources in request',
							sources.length,
							sku_sources.length,
						);
						let count = 0;
						if (sources.length > 0) {
							const added = sources.map((item) =>
								assetConverter({
									...item,
									id: '/t/',
									path: item?.asset_id,
								}),
							);
							console.log(' --> mapped assets', added.length);
							if (added.length > 0) {
								debounce(() => upsertAssets(added), 25);
							}
							count += added.length;
						}

						if (sku_sources.length > 0) {
							const added = sku_sources
								.filter(
									(item) =>
										sku_filter.length === 0 ||
										sku_filter.includes(item.sku_type),
								)
								.map((item) =>
									toSKUItem({
										...item,
										id: `/c/${companyId}`.toLowerCase(),
										path: `/s/${item.sku_name}`.toLowerCase(),
									}),
								);
							if (added.length > 0) {
								debounce(() => upsertSkus(added), 25);
								added.map((s) => {
									if (onSKUAdded) {
										onSKUAdded(s);
									}
								});
							}
							count += added.length;
						}

						if (count === 0) {
							enqueueSnackbar(
								`Nothing could be added from ${request.rw_request_id}!`,
								{ variant: 'warning' },
							);
						}
					})
					.catch((e) => {
						console.log('Get request', e);
						enqueueSnackbar(
							`Failed to get inventory from ${request.rw_request_id}!`,
							{ variant: 'error' },
						);
					});
			} else {
				enqueueSnackbar(
					`Request ${request.rw_request_id} cannot be used here!`,
					{ variant: 'error' },
				);
			}
		},
		[assets],
	);

	/**
	 * Add an item after selecting from the search results
	 * @type {(function(*): void)|*}
	 */
	const handleAdd = useCallback(
		(item) => {
			console.log(
				'>>handleAdd',
				item?.token_name,
				item?.rw_request_id,
				item?.sku_id,
			);

			const { token_name, rw_request_id, sku_id } = item;

			if (!rw_request_id && token_name) {
				addAsset(item);
				return;
			}

			if (rw_request_id) {
				addRequest(item);
				return;
			}

			// Adding a SKU, so allow the user to select the properties of the sku
			if (sku_id) {
				let entries = [];
				if (
					![REQUEST_TYPES.purchase_order].includes(currentRequest.request_type)
				) {
					entries = [{ amount: 0 }];
				}
				setSelectedSku({ ...toSKUItem(item), entries });
			}
		},
		[addAsset, addRequest],
	);

	/**
	 * Scan an item
	 * @type {(function(*): void)|*}
	 */
	const handleScan = useCallback(
		async (assetId) => {
			console.log('>>handleScan', assetId);

			// First - check if this asset is within the SKU
			const assetFound = skuConfirmHook(assetId);
			// If this asset is in the SKU, then potentially also confirm the asset and then we're done
			if (assetFound) {
				assetConfirmHook(assetId);
				if (submitForm && autoSubmit) {
					submitForm();
				}
				return;
			}

			// Find this asset first and confirm it..
			if (AssetModal) {
				// See if we have this asset
				const asset = assets.find((a) => a.asset_id === assetId);
				console.log('Found asset', assetId, asset);
				if (asset) {
					const updatedAsset = changeParentAsset(asset);
					if (!assetFilter || assetFilter(asset)) {
						setSelectedAsset(updatedAsset);
					} else {
						const result = assetConfirmHook(asset.asset_id, updatedAsset);
						if (!result) {
							upsertAssets(updatedAsset);
						}
						if (submitForm && autoSubmit) {
							submitForm();
						}
					}
				} else {
					// Fetch the asset first...
					loadAsset(assetId, true);
				}
			} else {
				const result = assetConfirmHook(assetId, {});
				if (!result) {
					loadAsset(assetId, true);
				} else {
					if (submitForm && autoSubmit) {
						submitForm();
					}
				}
			}
		},
		[
			skuConfirmHook,
			AssetModal,
			assetConfirmHook,
			submitForm,
			autoSubmit,
			assets,
			assetFilter,
			setSelectedAsset,
			changeParentAsset,
			upsertAssets,
			loadAsset,
		],
	);

	const handleRemoveSku = (sku) => (removeFunc) => {
		confirm({
			title: 'Remove SKU',
			content: <Body1>{`Remove ${sku?.sku_name}?`}</Body1>,
		})
			.then(() => removeFunc(sku))
			.catch(() => {});
	};

	const handleRemoveAsset = (asset) => (removeFunc) => {
		confirm({
			title: 'Remove Asset',
			content: <Body1>{`Remove ${asset?.rw_asset_id}?`}</Body1>,
		})
			.then(() => removeFunc(asset))
			.catch(() => {});
	};

	function InventoryComponent({ enableFill = false, enableUpload = false }) {
		const editAsset = useCallback(
			(asset) => {
				if (assetFilter && assetFilter(asset)) {
					setSelectedAsset(asset);
				}
			},
			[assetFilter, setSelectedAsset],
		);

		const handleSKUEdit = useCallback(
			(sku) => {
				// Remove any linked assets
				removeLinkedAssets(sku);
				setSelectedSku(sku);
			},
			[removeLinkedAssets, setSelectedSku],
		);

		const removeSelectedSku = useCallback(
			(sku) => {
				// Remove any linked assets
				removeLinkedAssets(sku);
				removeSkus(sku);
				onSKURemove(sku);
			},
			[removeLinkedAssets, removeSkus, onSKURemove],
		);

		return (
			<>
				<InventorySelectorV2
					title={title}
					types={filter}
					onAdd={handleAdd}
					onQrScan={(params) => handleOnQrRead(params)(handleScan)}
					onProcessAll={() => {
						approveAllAssets();
						approveAllSkus();
					}}
					onFill={caskExists && enableFill ? toggleFill : null}
					onUpload={enableUpload ? toggleUpload : null}
					skus={[
						skus,
						SKUModal ? handleSKUEdit : null,
						(s) => handleRemoveSku(s)(removeSelectedSku),
						displaySku,
					]}
					assets={[
						assets,
						AssetModal ? editAsset : null,
						(a) => handleRemoveAsset(a)(removeAssets),
						displayAsset,
					]}
					report={reports}
					enable={enable}
					loading={assetLoading || requestLoading}
					single={single}
					extendedSummary={extendedSummary}
					dutyPaidSummary={dutyPaidSummary}
					autoSubmit={[autoSubmit, setAutoSubmit]}
				/>
			</>
		);
	}

	function ModalComponents() {
		const addSelectedAssets = useCallback(
			async (asset) => {
				console.log(
					'>>addSelectedAssets',
					asset?.asset_id,
					asset?.rw_asset_id,
					asset?.path,
					asset?.asset_type,
					asset?.children?.length,
				);

				if (asset) {
					// Reset the parent asset
					closeParentAsset(asset);

					// Add all the selected assets
					const update = [asset, ...(asset.children ?? [])];

					debounce(
						() => upsertAssets(update.map(assetAdapter).filter((a) => a)),
						25,
					);

					// Hide the asset selector
					setAssetSelector(null);

					// // Flip to the asset modal for specific properties...
					// if (!assetFilter || assetFilter(asset)) {
					// 	const adaptedAsset = assetAdapter(asset);
					// 	if (adaptedAsset) {
					// 		setSelectedAsset(asset);
					// 	} else {
					// 		enqueueSnackbar(`Cannot add ${asset.rw_asset_id}!`, {
					// 			variant: 'error',
					// 		});
					// 	}
					// }
				} else {
					// close was triggered
					setAssetSelector(null);
				}
			},
			[
				closeParentAsset,
				upsertAssets,
				assetAdapter,
				assetFilter,
				setSelectedAsset,
			],
		);

		const handleSkuUpdate = useCallback(
			(sku) => {
				console.log(
					'>>handleSkuUpdate',
					sku?.sku_id,
					sku?.sku_name,
					sku?.sku_type,
					sku,
				);

				if (sku) {
					if (sku.include_holder) {
						// Get the entries and storage within that
						const entries = sku.entries ?? [];
						const assetIds = {};
						entries.forEach((e) => {
							const { storage = [] } = e;
							storage.forEach((s) => {
								let v = true;
								// eslint-disable-next-line no-prototype-builtins
								if (assetIds.hasOwnProperty(s.asset_id)) {
									v = assetIds[s.asset_id];
								}
								console.log(
									'Storage state',
									s.asset_id,
									v,
									s.amount,
									s.available_quantity,
								);
								assetIds[s.asset_id] = v && s.amount >= s.available_quantity;
							});
						});
						const existingAssetIds = assets.map((a) => a.asset_id);
						const newAssetsIds = Object.entries(assetIds)
							.filter(([k, v]) => v && !existingAssetIds.includes(k))
							.map(([k]) => k);
						if (newAssetsIds.length > 0) {
							console.log('Need to load these assets', newAssetsIds);
							newAssetsIds.forEach((id) =>
								fetchAsset(id).then((r) =>
									debounce(() => upsertAssets(assetConverter(r)), 25),
								),
							);
						}
					}
					upsertSkus(sku);
					if (onSKUAdded) {
						onSKUAdded(sku);
					}
					if (submitForm && autoSubmit) {
						submitForm();
					}
				}
				setSelectedSku(null);
			},
			[upsertSkus, upsertAssets, setSelectedSku, onSKUAdded],
		);

		const handleAssetUpdate = useCallback(
			async (asset) => {
				console.log(
					'>>handleAssetUpdate',
					asset?.asset_id,
					asset?.rw_asset_id,
					asset?.path,
					asset?.asset_type,
					asset?.children?.length,
				);
				if (asset) {
					const updatedAsset = applyParentAsset(asset);
					const result = assetConfirmHook(asset.asset_id, updatedAsset);
					if (!result) {
						upsertAssets(updatedAsset);
					}
					if (submitForm && autoSubmit) {
						submitForm();
					}
				}
				setSelectedAsset(null);
			},
			[applyParentAsset, assetConfirmHook, upsertAssets, setSelectedAsset],
		);

		const handleAssetUpload = useCallback((assets) => {
			console.log(
				'>>handleAssetUpload',
				assets?.length,
			);
			if (assets && assets.length > 0) {
				upsertAssets(assets);
			}
			toggleUpload();
		}, [upsertAssets, toggleUpload]);

		return (
			<>
				{AssetModal && !!selectedAsset && (
					<AssetModal
						open={!!selectedAsset}
						item={selectedAsset}
						items={assets}
						onClose={handleAssetUpdate}
						{...assetModalProps}
					/>
				)}

				{SKUModal && !!selectedSku && (
					<SKUModal
						open={!!selectedSku}
						item={selectedSku}
						onClose={handleSkuUpdate}
						{...skuModalProps}
					/>
				)}

				{!!assetSelector && (
					<AssetSelectorModal
						open={!!assetSelector}
						item={assetSelector}
						onClose={addSelectedAssets}
					/>
				)}
				{!!fill && (
					<AssetFillModal
						open={!!fill}
						items={assets}
						upsertAssets={upsertAssets}
						onClose={toggleFill}
					/>
				)}

				{!!upload && (
					<UploadAssetsModal
						open={upload}
						assetAdapter={assetAdapter}
						onClose={handleAssetUpload}
					/>
				)}
			</>
		);
	}

	return [
		[skus, initSkus, addNewSku, upsertSkus, removeSkus],
		[assets, initAssets, addNewAsset],
		init,
		InventoryComponent,
		ModalComponents,
	];
}

export default useInventorySelectorHook;
