import withEmptyCard from '@pw/components/Cards/EmptyCard';
import Dashboard from '@pw/components/Dashboard';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import useOverviewQuery from '@pw/utilities/hooks/service/useOverviewQuery';

function DashboardPage() {
	const allFacilitiesMap = useCompanyFacilities();

	const facilities = Object.values(allFacilitiesMap).map((f) => ({ id: `${f.id}/0/0/0`, title: f.facility_name }));

	const { data = {}, refetch = () => {} } = useOverviewQuery(
		{},
		{ skip: false },
	);
	console.log('Loaded data', facilities.length, data);
	usePageTitleHook('Dashboard');
	return <Dashboard data={data} refetch={refetch} facilities={facilities} />;
}

export default withAppLayout(withEmptyCard(DashboardPage));
