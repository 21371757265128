const loadJSONFile = async (event, callback) => {
  try {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const { result } = e.target;
      try {
        const parsedJson = JSON.parse(
          atob(result.replace(/^data:\w+\/\w+;base64,/, '')),
        );
        console.log('loadJSONFile =>', parsedJson);

        if (typeof callback === 'function') { callback(parsedJson); }

      } catch (err) { console.log(err) }
    };
    return reader.readAsDataURL(file);

  } catch (err) { console.log(err) }
};

export default loadJSONFile;
