import { assetApproveRejectThunk, deleteAssetThunk, upsertAssetThunk } from '@pw/redux/thunks/asset';
import { createCompanyThunk, updateCompanyThunk } from '@pw/redux/thunks/company';
import { refetchFacilitiesThunk, upsertFacilityThunk } from '@pw/redux/thunks/facilities';
import { setPageLoadingMessageThunk } from '@pw/redux/thunks/log';
import { inviteOwnerThunk, refetchOwnersThunk, resetOwnerPasswordThunk, uploadOwnersThunk, upsertOwnerThunk } from '@pw/redux/thunks/owners';
import { confirmPartnerThunk, refetchPartnersThunk, shareWithPartnerThunk, upsertPartnerThunk } from '@pw/redux/thunks/partners';
import { migrateRequestThunk, requestApproveRejectThunk, requestArchiveThunk, requestAssignThunk, requestCancelThunk, upsertRequestThunk } from '@pw/redux/thunks/request';
import { refetchRolesThunk, upsertRoleThunk } from '@pw/redux/thunks/roles';
import { upsertGatewayThunk, upsertSensorThunk } from '@pw/redux/thunks/sensors';
import { cloneSKUThunk, upsertSKUThunk } from '@pw/redux/thunks/sku';
import { refetchTeamsThunk, upsertTeamThunk } from '@pw/redux/thunks/teams';
import { logoutThunk, refetchUsersThunk, refreshTokenThunk, registerPasskeyThunk, removeUserThunk, updateProfileThunk, upsertUserThunk } from '@pw/redux/thunks/user';
import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { PURGE } from 'redux-persist';
import { setOnlineThunk } from '../../thunks/connection';
import { signinUserThunk, signinWithGoogleThunk, signinWithPasskeyThunk } from '../../thunks/signin';
import { SLICE } from './consts';
import { syncChangesThunk } from '@pw/redux/thunks/sync';
import { challengeIdentResendThunk, challengeIdentThunk, recoverThunk, registerUserThunk, verifyIdentThunk, verifySocialsThunk } from '@pw/redux/thunks/register';

const pushMessage = (state, payload) => {
  state.messages.push({
    id: nanoid(),
    ...payload,
  });
};

const registerHandlers = (builder, thunk, pending, success, error) => {
  builder.addCase(thunk.pending, (state) => {
    state.page_loading = { message: pending.message ?? null };
  });
  builder.addCase(thunk.fulfilled, (state, { payload }) => {
    state.page_loading = null;
    if (success) {
      pushMessage(state, {
        severity: success.severity ?? 'success',
        title: success.title ?? 'Success',
        content: success.message ?? 'Operation completed successfully!',
      });
    }
  });
  builder.addCase(thunk.rejected, (state, { payload }) => {
    state.page_loading = null;
    pushMessage(state, {
      severity: error?.severity ?? 'error',
      title: error?.title ?? 'Error',
      content: payload ?? error?.message ?? 'Error executing operation!',
    });
  });
};

const initialState = {
  active_list_item: null,
  page: {
    title: 'Proofworks',
  },
  page_loading: null,
  error: null,
  online: true,
  messages: []    /* Notification messages */
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    setActiveListItem(state, { payload }) {
      console.log('Setting active item', payload);
      state.active_list_item = payload;
    },
    setPageLoadingMessage(state, { payload }) {
      state.page_loading = payload;
    },
    pushNotification(state, { payload }) {
      console.log('pushNotification', payload);
      pushMessage(state, payload);
    },
    popNotification(state, { payload: ids }) {
      console.log('popNotification', ids);
      state.messages = state.messages.filter(msg => !ids.includes(msg.id));
    },
    setOnline(state, { payload }) {
      state.online = payload;
    },
    setPageTitle(state, { payload }) {
      console.log('setPageTitle', payload);
      state.page = {
        ...(state.page ?? {}),
        title: payload,
      };
    },
    clearPageLoading(state) {
      state.page_loading = null;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(setPageLoadingMessageThunk.fulfilled, (state, { payload }) => {
      console.log('setPageLoadingMessageThunk.fulfilled', payload);
      state.page_loading = payload;
    });

    builder.addCase(setOnlineThunk.pending, (state, { payload }) => {
      state.page_loading = {
        message: payload ? 'Connecting...' : 'Disconnecting...',
      };
    });
    builder.addCase(setOnlineThunk.fulfilled, (state, { payload }) => {
      state.page_loading = null;
      state.online = payload;
    });
    builder.addCase(setOnlineThunk.rejected, (state, { payload }) => {
      state.page_loading = null;
      pushMessage(state, {
        severity: 'error',
        title: 'Connection Error',
        content: payload ?? 'Failed to change connection state!',
      });
    });

    registerHandlers(builder, challengeIdentThunk,
      { message: 'Sign up...' },
      null,
      { title: 'Signup Error' },
    );

    registerHandlers(builder, challengeIdentResendThunk,
      { message: 'Resending code...' },
      null,
      { title: 'Resending Code Error' },
    );
    
    registerHandlers(builder, registerUserThunk,
      { message: 'Registering user...' },
      null,
      { title: 'Registering user Error' },
    );

    registerHandlers(builder, verifySocialsThunk,
      { message: 'Registering user...' },
      null,
      { title: 'Registering user Error' },
    );

    registerHandlers(builder, recoverThunk,
      { message: 'Recovering password...' },
      null,
      { title: 'Recovering password Error' },
    );

    registerHandlers(builder, verifyIdentThunk,
      { message: 'Verifying identity...' },
      null,
      { title: 'Verifying identity Error' },
    );
    
    registerHandlers(builder, logoutThunk,
      { message: 'Logging out...' },
      null,
      { title: 'Logout Error' },
    );

    registerHandlers(builder, signinUserThunk,
      { message: 'Signing in...' },
      null,
      { title: 'Signin Error' },
    );

    registerHandlers(builder, signinWithPasskeyThunk,
      { message: 'Signing in...' },
      null,
      { title: 'Signin Error' },
    );

    registerHandlers(builder, signinWithGoogleThunk,
      { message: 'Signing in...' },
      null,
      { title: 'Signin Error' },
    );

    registerHandlers(builder, refreshTokenThunk,
      { message: 'Switching...' },
      null,
      { title: 'Failed to Switch' },
    );
    registerHandlers(builder, updateProfileThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Account', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );
    registerHandlers(builder, createCompanyThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Company', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, updateCompanyThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Company', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, registerPasskeyThunk,
      { message: 'Registering...' },
      { severity: 'success', title: 'Passkey', content: 'Passkey registered successfully!' },
      { title: 'Failed to Register' },
    );

    registerHandlers(builder, refetchFacilitiesThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(builder, upsertFacilityThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Facility', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, upsertGatewayThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Gateway', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, upsertSensorThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Sensor', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, refetchPartnersThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(builder, upsertPartnerThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Partner', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, confirmPartnerThunk,
      { message: 'Confirming...' },
      { severity: 'success', title: 'Partner', content: 'Confirmed successfully!' },
      { title: 'Failed to Confirm' },
    );

    registerHandlers(builder, shareWithPartnerThunk,
      { message: 'Sharing...' },
      { severity: 'success', title: 'Partner', content: 'Shared successfully!' },
      { title: 'Failed to Share' },
    );

    registerHandlers(builder, refetchOwnersThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(builder, upsertOwnerThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Owner', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, uploadOwnersThunk,
      { message: 'Uploading...' },
      { severity: 'success', title: 'Owners', content: 'Uploaded successfully!' },
      { title: 'Failed to Upload' },
    );

    registerHandlers(builder, inviteOwnerThunk,
      { message: 'Inviting...' },
      { severity: 'success', title: 'Owner', content: 'Invited successfully!' },
      { title: 'Failed to Invite' },
    );

    registerHandlers(builder, resetOwnerPasswordThunk,
      { message: 'Resetting...' },
      { severity: 'success', title: 'Owner', content: 'Password reset successfully!' },
      { title: 'Failed to Reset' },
    );

    registerHandlers(builder, refetchTeamsThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(builder, upsertTeamThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Team', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, refetchRolesThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(builder, upsertRoleThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Role', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, refetchUsersThunk,
      { message: 'Refetching...' },
      null,
      { title: 'Failed to Load' },
    );

    registerHandlers(builder, upsertUserThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'User', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, removeUserThunk,
      { message: 'Removing...' },
      { severity: 'success', title: 'User', content: 'Removed successfully!' },
      { title: 'Failed to Remove' },
    );

    registerHandlers(builder, upsertAssetThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Asset', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, deleteAssetThunk,
      { message: 'Deleting...' },
      { severity: 'success', title: 'Asset', content: 'Deleted successfully!' },
      { title: 'Failed to Delete' },
    );

    registerHandlers(builder, assetApproveRejectThunk,
      { message: 'Approving...' },
      { severity: 'success', title: 'Asset', content: 'Approved successfully!' },
      { title: 'Failed to Approve' },
    );

    registerHandlers(builder, upsertRequestThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'Request', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, requestApproveRejectThunk,
      { message: 'Approving...' },
      { severity: 'success', title: 'Request', content: 'Approved successfully!' },
      { title: 'Failed to Approve' },
    );

    registerHandlers(builder, migrateRequestThunk,
      { message: 'Migrating...' },
      { severity: 'success', title: 'Request', content: 'Migrated successfully!' },
      { title: 'Failed to Migrate' },
    );

    registerHandlers(builder, requestArchiveThunk,
      { message: 'Archiving...' },
      { severity: 'success', title: 'Request', content: 'Archived successfully!' },
      { title: 'Failed to Archive' },
    );

    registerHandlers(builder, requestCancelThunk,
      { message: 'Cancelling...' },
      { severity: 'success', title: 'Request', content: 'Cancelled successfully!' },
      { title: 'Failed to Cancel' },
    );

    registerHandlers(builder, requestAssignThunk,
      { message: 'Assigning...' },
      { severity: 'success', title: 'Request', content: 'Assigned successfully!' },
      { title: 'Failed to Assign' },
    );

    registerHandlers(builder, cloneSKUThunk,
      { message: 'Cloning...' },
      { severity: 'success', title: 'SKU', content: 'Cloned successfully!' },
      { title: 'Failed to Clone' },
    );

    registerHandlers(builder, upsertSKUThunk,
      { message: 'Saving...' },
      { severity: 'success', title: 'SKU', content: 'Saved successfully!' },
      { title: 'Failed to Save' },
    );

    registerHandlers(builder, syncChangesThunk,
      { message: 'Synchronizing...' },
      { severity: 'success', title: 'Sync', content: 'Synchronized successfully!' },
      { title: 'Failed to Synchronize' },
    );

    builder.addCase(PURGE, () => {
      return { ...initialState };
    });
  },
});

export const { reducer, actions } = slice;
export const {
  setActiveListItem,
  setPageLoadingMessage,
  pushNotification,
  popNotification,
  setOnline,
  setPageTitle,
  clearPageLoading,
} = actions;
