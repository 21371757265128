import { Box } from '@mui/material';
import { pageHeaderHeight } from '@pw/components/Layout/consts';
import { breakpoints } from '@pw/styles/theme';
import isEqual from 'lodash.isequal';
import { useCallback, useContext, useEffect, useState } from 'react';

import SelectedNodeContext from '@pw/components/ProductionDesigner/Diagram/SelectedNodeContext';

// drag-n-drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// import Draggable from 'src/components/ProductionDesigner/Draggable';
import DropZone from '@pw/components/ProductionDesigner/DropZone';

// react-flow
import ReactFlow, {
  Background,
  BackgroundVariant,
  // MiniMap,
  // Panel,
  Controls,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';

// components & fns
import Node from '@pw/components/ProductionDesigner/Diagram/NodeBase';
import ToolBox from '@pw/components/ProductionDesigner/ToolBox';
import {
  materials,
  observations,
  operations,
  processes,
} from '@pw/components/ProductionDesigner/nodes';
// import EdgeFloatingStep from 'src/components/ProductionDesigner/Diagram/EdgeFloatingStep';
import CustomSmoothStepEdge from '@pw/components/ProductionDesigner/Diagram/CustomSmoothStepEdge';

// import Step
import Actions from '@pw/components/ProductionDesigner/Actions';
import NodeModal from '@pw/components/ProductionDesigner/Diagram/NodeModal';
import ViewportObserver from '@pw/components/ProductionDesigner/Diagram/ViewportObserver';
import DesignerContext from '@pw/context/DesignerContext';
import { useSearchParams } from 'react-router-dom';

export const itemWidth = 190;
export const itemHeight = itemWidth;
const itemGap = 32;
export const gridGap = 50;

const styles = {
  '&.diagram-root, .dropzone-root': {
    // position: 'absolute',
    width: '100%',
    // minHeight: '100%',
    // height: {
    //   xs: `calc(100% - ${pageHeaderHeight.xs})`,
    //   sm: `calc(100% - ${pageHeaderHeight.sm})`,
    // },
    height: {
      xs: `calc(100vh - ${pageHeaderHeight.xs})`,
      sm: `calc(100vh - ${pageHeaderHeight.sm})`,
    },
  },
  '.react-flow__node': {},
  '.react-flow__controls': {
    '&.bottom': { bottom: '1rem' },
    '&.right': { right: '1rem' },
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
  },
  '.react-flow__edge-path': {
    strokeWidth: '3px',

    '&.selected': {
      border: '2px dashed red'
    },
  },
};

const globalStyles = (showMenu) => (
  <style>{`
  '.wrapper-content, .wrapper-box': {
    height: '100%',
  },
  .wrapper-box { flex-grow: 1; }
  .menu {
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    max-height: calc(100vh - ${pageHeaderHeight.xs});
    overflow-y: auto;
    transition: transform 0.3s 0, display 0.3s 0.3s;
    transition-behavior: allow-discrete;
    transform: ${showMenu
      ? 'translateX(0); opacity: 1;'
      : 'translateX(-100%); opacity: 0 !important; display: none !important;'
    }
  }
  @media (min-width: ${breakpoints.sm}px) {
    .menu.menu {
      max-height: calc(100vh - ${pageHeaderHeight.sm});
    }
  }
  .menu-box { padding-top: 0; padding-bottom: 0; }
  .menu .copy-right { padding: 0.5rem 1rem; }
  .wrapper-content {padding: 0 !important; overflow-x: hidden !important; }
  .app-title-bar {display: none !important; }
  .react-flow__attribution { display: none; }
`}</style>
);

const edgeTypes = {
  default: CustomSmoothStepEdge,
  // processEdge: ProcessEdge,
};

const nodeTypes = {
  materialNode: Node,
  processNode: Node,
  observationNode: Node,
  operationNode: Node,
};

function ProductionDesigner() {
  const {
    mode,
    isLoading,
    setIsLoading,
    skuId,
    setSkuId,
    document,
    setDocument,
    nodes,
    setNodes,
    edges,
    setEdges,
  } = useContext(DesignerContext);

  const [searchParams] = useSearchParams();
  const skuIdParam = searchParams.get('sku');

  if (skuId !== skuIdParam) {
    setSkuId(skuIdParam);
  }

  const [setPreventLoop] = useState(false);
  const [showGlobalMenu, setShowGlobalMenu] = useState(true);
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    const _b = Array(10).fill('').map((v, k) => k);
    const nowMinusDays = (days) => {
      const d = new Date();
      d.setHours(days * -24);
      return d;
    }
    const _batches = _b.map((i) => {
      const d = nowMinusDays(i).toISOString().split('T')[0];
      return {
        name: d,
        id: btoa(d)
      }
    });
    setBatches(_batches)
  }, []);

  const [loadedFromName, setLoadedFromName] = useState('');
  const [itemName, setItemName] = useState('');
  const [saveToName, setSaveToName] = useState('');
  const [isBatch, setIsBatch] = useState(false);

  const [selectedNode, setSelectedNode] = useState();
  const [viewport, setViewport] = useState();

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => {
        // console.log({ params, eds });
        return addEdge(params, eds);
      }),
    [setEdges],
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [],
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [],
  );

  const updateNodeData = useCallback(
    (nodeId, newData) => {
      const nodeIndex = nodes.findIndex((n) => n.id === nodeId);
      const updatedNodes = nodes.map((n, idx) =>
        idx !== nodeIndex
          ? n
          : {
            ...n,
            data: {
              ...n.data,
              ...newData,
            },
          },
      );
      console.log({ nodeId, newData, nodeIndex, updatedNodes });
      setNodes(updatedNodes);
    },
    [nodes],
  );

  const onNodeClick = useCallback(
    (e) => {
      const clicked = e.target;
      const clickedMore = Boolean(
        clicked.classList.contains('more-button') ||
        clicked.closest('.more-button'),
      );
      if (!clickedMore) return;
      const clickedRoot = clicked.closest('.node-root');
      const id = clickedRoot.id;
      const clickedNode = nodes.find((n) => id === n.id);
      setSelectedNode(clickedNode);
    },
    [nodes],
  );

  const onLoad = useCallback(
    (_document) => {
      console.log('onLoad', { _document });
      if (!_document) return;
      if (isEqual(document, _document)) return;

      // setPreventLoop(false);
    },
    [document, itemName, nodes, edges],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {globalStyles(showGlobalMenu)}
      <Box sx={styles} className='diagram-root'>
        <SelectedNodeContext
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
        >
          <ToolBox
            document={document}
            itemName={itemName}
            setItemName={setItemName}
            saveToName={saveToName}
            setSaveToName={setSaveToName}
            setNodes={setNodes}
            components={processes}
            ingredients={materials}
            observations={observations}
            operations={operations}
            onLoad={onLoad}
            batches={batches}
            isBatch={isBatch}
            mode={mode}
            setIsLoading={setIsLoading}
            showGlobalMenu={showGlobalMenu}
            setShowGlobalMenu={setShowGlobalMenu}
          />

          <Actions
            hasContent={Boolean(nodes.length)}
            document={document}
            setDocument={setDocument}
            onLoad={onLoad}
            batches={batches}
            loadedFromName={loadedFromName}
            setLoadedFromName={setLoadedFromName}
            itemName={itemName}
            mode={mode}
            showGlobalMenu={showGlobalMenu}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />

          <DropZone
            name='diagram'
            // viewport={viewport}
            {...viewport}
            instructions={(canDrop) =>
              !nodes?.length && (
                <Box className='instructions'>
                  {canDrop ? 'Release to drop' : 'Drag components here'}
                </Box>
              )
            }
            showGlobalMenu={showGlobalMenu}
            isLoading={isLoading}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              edgeTypes={edgeTypes}
              snapToGrid={true}
              snapGrid={[gridGap / 2, gridGap / 2]}
              connectionMode='loose'
              connectionLineType={edgeTypes.default}
              connectionRadius={gridGap}
              onNodeClick={onNodeClick}
              isValidConnection={(...args) => {
                // console.log('isValidConnection', { args });
                return true;
              }}
            >
              <Controls position='bottom-right' />
              {/* <MiniMap /> */}
              <Background
                variant={BackgroundVariant.Lines}
                lineWidth={0.5}
                gap={40}
                color='#E4E3E3'
              />
              <ViewportObserver viewport={viewport} setViewport={setViewport} />
            </ReactFlow>
          </DropZone>

          <NodeModal
            isOpen={Boolean(selectedNode)}
            setSelectedNode={setSelectedNode}
            node={selectedNode}
            updateNode={updateNodeData}
          >
            <>{JSON.stringify(selectedNode, null, 2)}</>
          </NodeModal>
        </SelectedNodeContext>
      </Box>
    </DndProvider>
  );
}

export default ProductionDesigner;
