import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import debounce from '@pw/utilities/debounce';

const useItemListManager = (id, comp, initialData = [], hooks = {}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [data, setData] = useState(() => [...initialData].sort(comp));

	const remove = useCallback(
		(item, triggerHooks = false) => {
			if (!Array.isArray(item)) {
				item = [item];
			}
			// console.log('Removing', item);

			setData((v) => {
				const result = v.filter((s) => !item.find((i) => id(s) === id(i)));
				if (triggerHooks && item.length > 0) {
					// enqueueSnackbar(`Removed ${removed.length} item(s)!`, {
					// 	variant: 'warning',
					// });
					debounce(() => hooks?.onRemove && hooks.onRemove(item), 25);
				}
				// console.log(' --> removed result', result);
				return result;
			});
		},
		[enqueueSnackbar, hooks, id],
	);

	const add = useCallback(
		(item, triggerHooks = true) => {
			if (!Array.isArray(item)) {
				item = [item];
			}
			// console.log('Adding', item);

			setData((v) => {
				const added = item.filter((i) => !v.find((s) => id(s) === id(i)));
				const all = [...v, ...added];

				if (triggerHooks && added.length > 0) {
					// enqueueSnackbar(`Added ${added.length} item(s)!`, {
					// 	variant: 'success',
					// });

					debounce(() => {
						const cb = hooks?.onAdd ?? hooks?.onUpsert;
						if (cb) cb(added);
					}, 25);
				}

				all.sort(comp);
				return all;
			});
		},
		[comp, enqueueSnackbar, hooks?.onAdd, hooks?.onUpsert],
	);

	// useEffect(() => {
	// 	hooks?.onAdd && hooks.onAdd(data);
	// }, [hooks, data]);

	const upsert = useCallback(
		(item, triggerHooks = true) => {
			if (!Array.isArray(item)) {
				item = [item];
			}
			// console.log('Upserting', item);

			// console.log('upsert assets', item);
			setData((v) => {
				const updated = [];
				const added = [];

				item.forEach((i) => {
					const existing = v.findIndex((s) => id(s) === id(i));
					if (existing >= 0) {
						v[existing] = i;
						updated.push(i);
					} else {
						v.push(i);
						added.push(i);
					}
				});

				if (added.length) {
					// enqueueSnackbar(`Added ${added.length} item(s)!`, {
					// 	variant: 'success',
					// });

					debounce(() => {
						const cb = hooks?.onAdd ?? hooks?.onUpsert;
						if (cb) cb(added);
					}, 25);
				}

				if (triggerHooks && updated.length) {
					// enqueueSnackbar(`Updated ${updated.length} item(s)!`, {
					// 	variant: 'info',
					// });

					debounce(() => {
						const cb = hooks?.onUpsert;
						if (cb) cb(updated);
					}, 25);
				}

				v.sort(comp);
				return [...v];
			});
		},
		[comp, enqueueSnackbar, hooks?.onAdd, hooks?.onUpsert, id],
	);

	const init = useCallback(
		(items) => {
			console.log('Initializing', items?.length);
			setData(items.sort(comp));
		},
		[setData],
	);

	return [data, init, add, upsert, remove];
};

export default useItemListManager;
