import * as yup from 'yup';

export default function signatureFields(fields = {}) {
	const { PROPRIETOR2 = '', TITLE = '' } = fields;

	return {
		PROPRIETOR2: [PROPRIETOR2, yup.string()],
		TITLE: [TITLE, yup.string().required('TITLE is required!')],
	};
}
