import { clientFields } from '@pw/components/Client/clientFields';
import { FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import TTBReport_75 from '@pw/components/Report/AlcoholFuelPlantReport/TTBReport_75';
import TTBReport_75Fields from '@pw/components/Report/AlcoholFuelPlantReport/TTBReport_75Fields';
import TTBReport_24 from '@pw/components/Report/ExciseTaxReturn/TTBReport_24';
import TTBReport_24Fields from '@pw/components/Report/ExciseTaxReturn/TTBReport_24Fields';
import MonthlyReport from '@pw/components/Report/MonthlyReport';
import monthlyReportFields from '@pw/components/Report/monthlyReportFields';
import TTBReport_43 from '@pw/components/Report/MonthlyReportofProcessingDenaturingOperations/TTBReport_43';
import TTBReport_43Fields from '@pw/components/Report/MonthlyReportofProcessingDenaturingOperations/TTBReport_43Fields';
import TTBReport_28 from '@pw/components/Report/MonthlyReportofProcessingOperations/TTBReport_28';
import TTBReport_28Fields from '@pw/components/Report/MonthlyReportofProcessingOperations/TTBReport_28Fields';
import TTBReport_11 from '@pw/components/Report/MonthlyReportofStorageOperations/TTBReport_11';
import TTBReport_11Fields from '@pw/components/Report/MonthlyReportofStorageOperations/TTBReport_11Fields';
import TTBReport from '@pw/components/Report/TTBReport';
import TTBReportFields from '@pw/components/Report/TTBReportFields';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DutySubmissionSourceAssetModal from '@pw/components/SKUSelector/modals/DutySubmissionSourceAssetModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { H4 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REPORT_OPTIONS, REPORT_OPTIONS_US, REPORT_TYPES } from '@pw/consts/reports';
import { ASSET_PROCESSED_STATUS, REQUEST_STATUS, REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsUsUser } from '@pw/redux/containers/User';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import useGetId from '@pw/utilities/hooks/logic/useGetId';
import useReportSummaryQuery from '@pw/utilities/hooks/service/useReportSummaryQuery';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

export function convertSourceAsset(item) {
	const { properties = {} } = item ?? {};
	const { liquid = {} } = properties;
	const { level = {} } = liquid;
	const { bl = 0, enable: le = false } = level;

	// Only add non-empty assets
	if (bl && le) {
		return toTaggedAsset({
			...item,
			processed: ASSET_PROCESSED_STATUS.PENDING,
			properties: {
				...properties,
				liquid: {
					...liquid,
					level: {
						...level,
						expected_bl: bl,
					},
				},
			},
		});
	}
	return null;
}

// eslint-disable-next-line react/prop-types
function Properties({ children, reportType }) {
	// const { readonly } = useContext(FormikContext);
	return (
		<FormikProvider path='properties'>
			{reportType === '5110.40' ? (
				<TTBReport />
			) : reportType === '5110.75' ? (
				<TTBReport_75 />
			) : reportType === '5000.24' ? (
				<TTBReport_24 />
			) : reportType === '5110.43' ? (
				<TTBReport_43 />
			) : reportType === '5110.28' ? (
				<TTBReport_28 />
			) : reportType === '5110.11' ? (
				<TTBReport_11 />
			) : reportType === REPORT_TYPES.MONTHLY ? (
				<MonthlyReport />
			) : (
				<>
					{children}
					<FormikTextField
						name='payment_reference'
						label='Payment Reference Number'
					/>
				</>
			)}
		</FormikProvider>
	);
}

function propertyFields(fields = {}) {
	const { payment_reference = '', reportType = REPORT_TYPES.MONTHLY } = fields;
	return {
		...(reportType === '5110.40'
			? TTBReportFields(fields)
			: reportType === '5110.75'
				? TTBReport_75Fields(fields)
				: reportType === '5000.24'
					? TTBReport_24Fields(fields)
					: reportType === '5110.43'
						? TTBReport_43Fields(fields)
						: reportType === '5110.28'
							? TTBReport_28Fields(fields)
							: reportType === '5110.11'
								? TTBReport_11Fields(fields)
								: reportType === REPORT_TYPES.MONTHLY
									? monthlyReportFields(fields)
									: {
											payment_reference: [
												payment_reference,
												yup
													.string()
													.required('Please enter payment reference number'),
											],
										}),
	};
}

function DutySubmissionForm() {
	const { id } = useGetId();
	const isUSUser = useIsUsUser();

	const [reportType, setReportType] = useState(REPORT_TYPES.MONTHLY);

	const source_filter = {
		asset_types: [ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.filling_tank],
		sku_types: [SKU_TYPES.FINISHED],
	};

	const { data: reportSummary } = useReportSummaryQuery(
		{},
		{ skip: !!id?.length },
	);

	const [
		[sourceSkus, initSourceSkus],
		[sourceAssets, initSourceAssets],
		,
		SourceInventory,
		SourceModals,
	] = useInventorySelectorHook({
		title: 'Sources',
		filter: source_filter,
		assetFilter: (a) =>
			[
				ASSET_TYPES.ibc,
				ASSET_TYPES.cask,
				ASSET_TYPES.tanker,
				ASSET_TYPES.filling_tank,
			].includes(a.asset_type),
		assetAdapter: convertSourceAsset,
		SKUModal: SourceSKUModal,
		skuModalProps: { include_tax_code: true, duty_paid: true },
		AssetModal: DutySubmissionSourceAssetModal,
		dutyPaidSummary: true,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
			properties: propertyFields({
				...reportSummary,
				...initialValues?.properties,
				reportType,
			}),
		}),
		[reportSummary, reportType],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = (entity) => {
		const {
			properties,
			request_status = REQUEST_STATUS.PENDING,
			sku_sources = [],
			sources = [],
		} = entity ?? {};

		sku_sources.sort((l, r) => r.sku_name.localeCompare(l.sku_name));
		sources.sort((l, r) => r.rw_asset_id.localeCompare(l.rw_asset_id));

		debounce(() => {
			console.log('Request status', request_status);
			if (
				[
					REQUEST_STATUS.DONE,
					REQUEST_STATUS.CANCELLED,
					REQUEST_STATUS.REJECTED,
					REQUEST_STATUS.ARCHIVED,
				].includes(request_status)
			) {
				// setEnableSource(false);
			}
			if (
				[REQUEST_STATUS.DONE, REQUEST_STATUS.ARCHIVED].includes(request_status)
			) {
				// setSourceReports(true);
			}
		}, 50);

		// set initial state
		debounce(() => initSourceSkus(sku_sources), 50);
		debounce(() => initSourceAssets(sources), 50);
		setReportType(properties?.report_type ?? REPORT_TYPES.MONTHLY);
	};

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_sources: sourceSkus,
				sources: sourceAssets,
				properties: {
					...(entity?.properties ?? {}),
					report_type: reportType,
				},
			};
		},
		[sourceSkus, sourceAssets, reportType],
	);
	console.log('reportType', reportType);
	return (
		<RequestForm
			requestLabel='Duty Submission'
			requestType={REQUEST_TYPES.duty_submission}
			changeSetGenerator={changeSetGenerator}
			onInit={handleInit}
			onBeforeSave={handleBeforeSave}
		>
			<H4>Report Details</H4>
			<FormikSelect
				label={'Report Type'}
				name='report_type'
				options={isUSUser ? REPORT_OPTIONS_US : REPORT_OPTIONS}
				fullWidth
				onChange={(e) => setReportType(e.target.value)}
				value={reportType}
			/>
			<Properties reportType={reportType}>
				<SourceInventory />
				<SourceModals />
			</Properties>
		</RequestForm>
	);
}

export default withAppLayout(DutySubmissionForm, { title: 'Duty Submission' });
