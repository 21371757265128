import { Stack } from '@mui/material';
import { FormikProvider } from '@pw/providers/FormikProvider';
// import { FormikProvider } from 'src/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import Part4Report from './Part4Report';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
/* eslint-disable react/prop-types */
export default function Part4({
	name,
	title = 'Special Categories',
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Part4Report
						name='Alcohol_and_Neutral_Spirits_Other_than_vodka'
						title='Alcohol and Neutral Spirits Other than Vodka'
						columnE={true}
					/>
					<Part4Report
						name='Blended_Straight_Whiskey'
						title='Blended Straight Whiskey'
						columnC={true}
						columnE={true}
					/>
					<Part4Report
						name='Blended_Whiskey_with_Neutral_Spirits'
						title='Blended Whiskey with Neutral Spirits'
						columnC={true}
						columnE={true}
					/>
					<Part4Report
						name='Blended_Whiskey_with_Light_Whiskey'
						title='Blended Whiskey with Light Whiskey'
						columnC={true}
						columnE={true}
					/>
					<Part4Report
						name='Blended_Light_Whiskey'
						title='Blended Light Whiskey'
						columnC={true}
						columnE={true}
					/>
					<Part4Report
						name='Any_Other_Blends_of_100_Whiskey'
						title='Any Other Blends of 100% Whiskey'
						columnC={true}
						columnE={true}
					/>
					<Part4Report
						name='Imported_Whiskey_Scotch'
						title='Imported Whiskey Scotch'
						columnE={true}
					/>
					<Part4Report
						name='Imported_Whiskey_Canadian'
						title='Imported Whiskey Canadian'
						columnE={true}
					/>
					<Part4Report
						name='Imported_Whiskey_Irish_and_Others'
						title='Imported Whiskey Irish and Others'
						columnE={true}
					/>
					<Part4Report
						name='Domestic_Whiskey_Distilled_at_160_and_Under'
						title='Domestic Whiskey Distilled at 160 and Under'
						columnC={true}
					/>
					<Part4Report
						name='Domestic_Whiskey_Distilled_at_Over_160'
						title='Domestic Whiskey Distilled at Over 160'
						columnC={true}
					/>
					<Part4Report
						name='Brandy_Distilled_at_170_and_Under'
						title='Brandy Distilled at 170 and Under'
					/>
					<Part4Report
						name='Brandy_Distilled_at_Over_170'
						title='Brandy Distilled at Over 170'
					/>
					<Part4Report
						name='Spirits_Rum_Puerto_Rican'
						title='Spirits Rum Puerto Rican'
						columnE={true}
					/>
					<Part4Report
						name='Spirits_Rum_Virgin_Islands'
						title='Spirits Rum Virgin Islands'
						columnE={true}
					/>
					<Part4Report
						name='Rum_Domestic'
						title='Rum Domestic'
						columnC={true}
					/>
					<Part4Report
						name='Rum_Other_Imported'
						title='Rum Other Imported'
						columnE={true}
					/>
					<Part4Report name='Gin' title='Gin' />
					<Part4Report name='Vodka' title='Vodka' />
					<Part4Report
						name='Cordials_Liqueurs_and_Specialties'
						title='Cordials Liqueurs and Specialties'
						columnE={true}
					/>
					<Part4Report
						name='Cocktails_and_Mixed_Drinks'
						title='Cocktails and Mixed Drinks'
						columnE={true}
					/>
					<Part4Report name='Tequila' title='Tequila' columnE={true} />
					<Part4Report name='Specify_66' title='Specify 66' specify={true} />
				</>
			)}
		</FormikProvider>
	);
}
