import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import {
	FormikCheckBox,
	FormikDatePicker,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';
import { useContext, useEffect } from 'react';

function StorageChargeCodePropertiesImpl() {
	const { values, setFieldValue, readonly } = useContext(FormikContext);

	useEffect(() => {
		if (!values?.enable) {
			setFieldValue('charge_code', {
				...values,
				enable: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.enable]);

	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				disabled={readonly}
				label={<H4>Storage Charge Code</H4>}
			/>

			<Collapse in={!!values?.enable}>
				<Stack spacing={3} sx={{ ...mt2 }}>
					<FlexBox>
						<FormikTextField
							label='Charge Code'
							name='code'
							fullWidth
							required
							disabled={readonly}
						/>
					</FlexBox>
					<FlexBox spacing={2} alignItems='top'>
						<FormikDatePicker
							name='start_date'
							label='Start Date'
							fullWidth
							required
							disabled={readonly}
						/>
						<FormikDatePicker
							name='end_date'
							label='End Date'
							fullWidth
							required
							disabled={readonly}
						/>
					</FlexBox>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function StorageChargeCodeProperties({ name }) {
	const { readonly } = useContext(FormikContext);

	return (
		!readonly && (
			<FormikProvider path={name}>
				<StorageChargeCodePropertiesImpl />
			</FormikProvider>
		)
	);
}
