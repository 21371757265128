import { Stack } from '@mui/material';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import Part2Report from './Part2Report';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';

export default function Part2({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Part2Report
						name='On_hand_first_of_month'
						title='On Hand First of Month'
					/>
					<Part2Report
						name='Deposited_in_bulk_storage'
						title='Deposited in Bulk Storage'
					/>
					<Part2Report
						name='Received_from_customs_custody'
						title='Received from Customs Custody'
						wine={true}
					/>
					<Part2Report
						name='Returned_to_bulk_storage'
						title='Returned to Bulk Storage'
						wine={true}
					/>
					<Part2Report name='Taxpaid' title='Taxpaid' wine={true} />
					<Part2Report
						name='Use_of_the_United_States'
						title='Use of the United States'
						wine={true}
					/>
					<Part2Report
						name='Hospital_scientific_educational_use'
						title='Hospital, Scientific, Educational Use'
						hospital={true}
					/>
					<Part2Report name='Export' title='Export' wine={true} />
					<Part2Report
						name='Transfer_to_foreign_trade_zone'
						title='Transfer to Foreign Trade Zone'
						wine={true}
					/>
					<Part2Report
						name='Transfer_to_cmbw'
						title='Transfer to CMBW'
						wine={true}
					/>
					<Part2Report
						name='Use_as_supplies_on_vessels_and_aircraft'
						title='Use as Supplies on Vessels and Aircraft'
						wine={true}
					/>
					<Part2Report
						name='Transfer_to_bonded_winery'
						title='Transfer to Bonded Winery'
						bonded={true}
					/>
					<Part2Report
						name='Transfer_to_cbw'
						title='Transfer to CBW'
						wine={true}
					/>
					<Part2Report
						name='Research_development_or_testing'
						title='Research, Development, or Testing'
						wine={true}
					/>
					<Part2Report
						name='Transferred_to_processing_account'
						title='Transferred to Processing Account'
					/>
					<Part2Report
						name='Transferred_to_production_account'
						title='Transferred to Production Account'
					/>
					<Part2Report
						name='Transferred_to_other_bonded_premises'
						title='Transferred to Other Bonded Premises'
					/>
					<Part2Report name='Destroyed' title='Destroyed' />
					<Part2Report name='Other_losses' title='Other Losses' />
					<Part2Report
						name='On_hand_end_of_month'
						title='On Hand End of Month'
					/>
				</>
			)}
		</FormikProvider>
	);
}
