import generalInformationFields from './generalInformationFields';
import transactionsFields from './transactionsFields';
import productionOfAlcoholFields from './productionOfAlcoholFields';
import productionOfWhiskeyFields from './productionOfWhiskeyFields';
import productionOfBrandyFields from './productionOfBrandyFields';
import usedInRedistillationFields from './usedInRedistillationFields';
import materialsUsedFields from './materialsUsedFields';
import signatureFields from './signatureFields';

export default function TTBReportFields(fields = {}) {
	const {
		general_information,
		transactionsData,
		production_of_alcohol,
		production_of_whiskey,
		production_of_Brandy,
		used_in_redistillation,
		materials_used,
		signature,
	} = fields;

	return {
		general_information: generalInformationFields(general_information),
		'PartI-Transactions-WhiskeyAndBrandy': transactionsFields(transactionsData),
		'PartI-Transactions-Rum,GinAndVodka': transactionsFields(transactionsData),
		'PartI-Transactions-AlcoholAndSpiritsAndOther,AndTotals':
			transactionsFields(transactionsData),
		'PartII-ProductionOfAlcoholAndSpiritsOf190OrMore':
			productionOfAlcoholFields(production_of_alcohol),
		'PartIII-ProductionOfWhiskeyByKindAndCoorperageUsed':
			productionOfWhiskeyFields(production_of_whiskey),
		'PartIV-ProductionOfBrandy': productionOfBrandyFields(production_of_Brandy),
		'PartV-UsedInRedistillation': usedInRedistillationFields(
			used_in_redistillation,
		),
		'PartVI-MaterialsUsed': materialsUsedFields(materials_used),
		Signature: signatureFields(signature),
	};
}
