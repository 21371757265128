import { AddCircle, CopyAllOutlined, Draw, Print } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import CreatorProps from '@pw/components/CreatorProps';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKUQRCode from '@pw/components/QRImage/SkuQRCode';
import { H5 } from '@pw/components/Typography';
import { Delivery, Sample } from '@pw/components/icons';
import { SKU_EDIT_URL, SKU_TYPES } from '@pw/consts/sku';
import { useIsAccountActive } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import printElement from '@pw/utilities/printElement';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PrintLabel from '../admin/SKUs/PrintLabel';
import SKUStats from './SKUStats';

function SKUSummary({ entity, refresh, showControls = true }) {
	const navigate = useNavigate();
	const { sku_id, sku_type } = entity;

	const readonly = useCheckCompany(entity);
	const userIsActive = useIsAccountActive();

	const printRef = useRef();

	const showActions = useMemo(
		() => userIsActive && !readonly,
		[readonly, userIsActive],
	);

	// const assetCards = useMemo(() => {
	// 	const type = ASSET_TYPES_REVERSE[asset_type];
	// 	return toTasksCardEntity(entity, type, true);
	// }, [entity, asset_type]);

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
	};

	const handleNew = () => {
		const route = SKU_EDIT_URL[sku_type];
		console.log('Route', sku_type, route);
		navigate(`${route}`);
	};

	const handleClone = () => {
		const route = SKU_EDIT_URL[sku_type];
		const str = JSON.stringify(entity);
		const base = Buffer.from(str).toString('base64');
		navigate(`${route}?clone=${base}`);
	};

	const handleDelivery = () => {
		navigate(`/app/delivery`, { state: { sku_id } });
	};

	const handlePO = () => {
		navigate(`/app/purchase-order`, { state: { sku_id } });
	};

	const handleDesigner = () => {
		navigate(`/app/production-designer`, { state: { sku_id } });
	};

	return (
		<Stack sx={styles} className='root' spacing={3}>
			{showControls && <H5>SKU</H5>}

			<Box className='summary-qr'>
				<Box ref={printRef} className='qr'>
					<SKUQRCode sku={entity} fullScreen={false} withDetails={false} />
				</Box>

				{showActions && (
					<IconCircleButton
						onClick={() => handlePrint(printRef)}
						variant='outlined'
						color='secondary'
					>
						<Print height={24} width={24} />
					</IconCircleButton>
				)}
			</Box>

			<FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
				{showControls && (
					<>
						<TitledButton
							handleClick={handleNew}
							variant='outlined'
							color='secondary'
							label='New'
							sx={{ width: '72px', height: '55px' }}
						>
							<AddCircle height={24} width={24} />
						</TitledButton>

						{showActions && (
							<TitledButton
								handleClick={handleClone}
								variant='outlined'
								color='secondary'
								label='Clone'
								sx={{ width: '72px', height: '55px' }}
							>
								<CopyAllOutlined height={24} width={24} />
							</TitledButton>
						)}
					</>
				)}

				{showActions && (
					<>
						{[SKU_TYPES.LIQUID].includes(sku_type) && (
							<TitledButton
								handleClick={handleDesigner}
								variant='outlined'
								color='secondary'
								label='Design'
								sx={{ width: '42px', height: '52px' }}
							>
								<Draw height={24} width={24} />
							</TitledButton>
						)}
						{![SKU_TYPES.WASTE, SKU_TYPES.WASTE, SKU_TYPES.SERVICE].includes(
							sku_type,
						) && (
							<TitledButton
								handleClick={handleDelivery}
								variant='outlined'
								color='secondary'
								label='Delivery'
								sx={{ width: '72px', height: '55px' }}
							>
								<Delivery height={24} width={24} />
							</TitledButton>
						)}
						{![SKU_TYPES.WASTE, SKU_TYPES.SERVICE].includes(sku_type) && (
							<TitledButton
								handleClick={handlePO}
								variant='outlined'
								color='secondary'
								label='PO'
								sx={{ width: '72px', height: '55px' }}
							>
								<Sample height={24} width={24} />
							</TitledButton>
						)}
						{[SKU_TYPES.FINISHED].includes(sku_type) && (
							<PrintLabel sku={entity} />
						)}
					</>
				)}
			</FlexBox>

			{showActions && <CreatorProps entity={entity} />}

			{showActions &&
				[
					SKU_TYPES.LIQUID,
					SKU_TYPES.TRACKED,
					SKU_TYPES.RAW,
					SKU_TYPES.EXPIRING,
					SKU_TYPES.CONSUMABLE,
					SKU_TYPES.FINISHED,
				].includes(sku_type) && <SKUStats entity={entity} />}
		</Stack>
	);
}

export default withSmallDialogCard(SKUSummary);
