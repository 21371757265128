import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Part2Report({
	name,
	title = 'On Hand First of Month',
	disabled,
	bonded = false,
	hospital = false,
	wine = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='h'
					label='Vodka'
					fullWidth
					sx={{ ...((bonded || hospital) && styles.transaction) }}
				/>
				<FormikTextField
					name='i'
					label='Alcohol and Spirits 190° and Over'
					fullWidth
				/>
			</FlexBox>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='j'
					label='Alcohol and Spirits Under 190°'
					fullWidth
					sx={{ ...(hospital && styles.transaction) }}
				/>
				<FormikTextField
					name='k'
					label='Other'
					fullWidth
					sx={{ ...((bonded || hospital) && styles.transaction) }}
				/>
			</FlexBox>
			<FormikTextField
				name='l'
				label='Wine'
				fullWidth
				sx={{ ...((bonded || hospital || wine) && styles.transaction) }}
			/>
		</FormikProvider>
	);
}
