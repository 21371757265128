import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikDatePicker, FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import MashSKUModal from '@pw/components/SKUSelector/modals/MashSKUModal';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { convertSourceAsset } from '@pw/pages/app/transfer';
import { useCompanySKUs } from '@pw/redux/containers/User';
import { mt2 } from '@pw/styles/margins';
import styles from '@pw/styles/modal.styles';
import debounce from '@pw/utilities/debounce';
import generateTokenId from '@pw/utilities/generateTokenId';
import { useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';

function LiquidSelector({ skus, assets }) {
	const { setFieldValue } = useContext(FormikContext);
	const liquidTypes = useLiquidTypes();
	const companySkus = useCompanySKUs();
	
	useEffect(() => {
		console.log('skus', skus);
		console.log('assets', assets);

		const uniqueliquids = [];

		// Get the set of liquid skus here and if there is only one liquid type, suggest that, else allow user to select the liquid type..
		const skuIds = skus.map((sku) => sku.sku_id);
		for (const asset of assets) {
			const skuId = asset.properties?.liquid?.sku_id;
			if (skuId && !skuIds.includes(skuId)) {
				skuIds.push(skuId);
			}
		}
		const assetLiquidSkus = companySkus.filter((sku) => skuIds.includes(sku.sku_id));

		for (const sku of assetLiquidSkus) {
			const liquidType = sku.properties?.liquid_type;
			if (liquidType && !uniqueliquids.includes(liquidType)) {
				uniqueliquids.push(liquidType);
			}
		}

		console.log('uniqueliquids', uniqueliquids);
		if (uniqueliquids.length === 1) {
			debounce(() => setFieldValue('liquid_type', uniqueliquids[0]), 250);
		}	
	}, [skus, assets]);

	return (
		<FormikSelect
			label='Liquid type'
			name='liquid_type'
			options={liquidTypes}
			fullWidth
		/>
	);
}


function ReleaseModal({ title, item, open, onClose }) {
	const { id, name, date, liquid_type } = item ?? {};

	const label = name ? `Update ${title}` : `New ${title}`;

	const changeSet = useMemo(
		() => ({
			name: [name ?? '', yup.string().required('Name required!')],
			date: [date ?? Date.now(), yup.number().required('Date required!')],
			liquid_type: [liquid_type ?? '', yup.string().required('Liquid type required!')],
		}),
		[name, date, liquid_type],
	);

	const sourceProps = useMemo(
		() => ({
			title: 'Blend',
			filter: {
				asset_types: [
					ASSET_TYPES.pallet,
					ASSET_TYPES.cask,
					ASSET_TYPES.ibc,
					ASSET_TYPES.filling_tank,
				],
				sku_types: [SKU_TYPES.LIQUID],
			},
			assetFilter: (a) =>
				[ASSET_TYPES.ibc, ASSET_TYPES.cask, ASSET_TYPES.filling_tank].includes(
					a.asset_type,
				),
			// submitForm,
			assetAdapter: convertSourceAsset,
			SKUModal: MashSKUModal,
		}),
		[],
	);

	const [[sourceSkus], [sourceAssets], init, SourceInventory, SourceModals] =
		useInventorySelectorHook(sourceProps);

	const handleSubmit = (values) => {
		const newRelease = {
			id: id ?? generateTokenId(),
			skus: sourceSkus,
			assets: sourceAssets,
			...values,
		};
		console.log('Setting release', newRelease);
		onClose(newRelease);
	};

	useEffect(() => {
		init({
			assets: item?.assets,
			skus: item?.skus,
		});
	}, [item?.assets, item?.skus]);

	return (
		<>
			<ModalWithClose open={open} onClose={onClose} title={label}>
				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing={2}>
						<FlexBox>
							<FormikTextField label='Name' name='name' fullWidth />
							<FormikDatePicker
								sx={{ ...mt2 }}
								name='date'
								label='Release Date'
								fullWidth
							/>
						</FlexBox>

						<SourceInventory />

						<LiquidSelector skus={sourceSkus} assets={sourceAssets} />

						<Errors />

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								Save
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</ModalWithClose>
			<SourceModals />
		</>
	);
}

export default ReleaseModal;
