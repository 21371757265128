import { getTaxCodes } from "@pw/services/taxCode.service";
import { setPageLoadingMessageThunk } from "../log";
const { listUsers, listPartners, listOwners, listRoles, listTeams, listPermissions } = require("@pw/services/company.service");
const { getAllFacilities } = require("@pw/services/facility.service");
const { listGateWay } = require("@pw/services/sensor.service");
const { getSKUList } = require("@pw/services/sku.service");

const loadCompanyState = async (company, dispatch, perms) => {

  dispatch(setPageLoadingMessageThunk({ message: 'Loading SKUs...' }))
  const skus = await getSKUList({ id: company.company_id });
  // console.log('skus', skus);

  dispatch(setPageLoadingMessageThunk({ message: 'Loading Facilities...' }))
  const facilities = await getAllFacilities({ id: company.company_id });
  // console.log('facilities', facilities);

  dispatch(setPageLoadingMessageThunk({ message: 'Loading Users...' }))
  const users = await listUsers({ id: company.company_id });
  // console.log('users', users);

  dispatch(setPageLoadingMessageThunk({ message: 'Loading Sensors...' }))
  const sensors = await listGateWay({ id: company.company_id });
  // console.log('sensors', sensors);

  dispatch(setPageLoadingMessageThunk({ message: 'Loading Partners...' }))
  const partners = await listPartners({ id: company.company_id });
  // console.log('partners', partners);

  dispatch(setPageLoadingMessageThunk({ message: 'Loading Owners...' }))
  const owners = await listOwners({ id: company.company_id });
  // console.log('owners', owners);

  console.log('perms', perms);
  let roles = [];
  let teams = [];
  let permissions = [];
  if (perms.companyAdmin || perms.userAdmin) {
    dispatch(setPageLoadingMessageThunk({ message: 'Loading Roles...' }))
    roles = await listRoles({ id: company.company_id });
    console.log('roles', roles);

    dispatch(setPageLoadingMessageThunk({ message: 'Loading Teams...' }))
    teams = await listTeams({ id: company.company_id });
    console.log('teams', teams);

    dispatch(setPageLoadingMessageThunk({ message: 'Loading Permissions...' }))
    permissions = await listPermissions();
    console.log('permissions', permissions);
  }
  //
  // dispatch(setPageLoadingMessageThunk({ message: 'Loading Tax Codes...' }))
  // const taxCodes = await getTaxCodes().then(({ items = [] }) =>
  //   items?.map((item) => ({
  //     label: `${item?.path?.split('/').reverse()[0]} (${item?.description})`,
  //     value: item?.path,
  //   })),
  // );
  // // console.log('taxCodes', taxCodes);

  return {
    ...company,
    skus,
    facilities,
    users,
    sensors: sensors?.items ?? [],
    partners: partners?.items ?? [],
    owners: owners?.items ?? [],
    roles,
    teams,
    permissions,
  };
};

export default loadCompanyState;
