import { clientFields } from '@pw/components/Client/clientFields';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import PurchaseSKUModal from '@pw/components/SKUSelector/modals/PurchaseSKUModal';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import * as yup from 'yup';

function Properties() {
	const { readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path='properties'>
				<FormikTextField name='invoice_id' label='Invoice Id' />
			</FormikProvider>
		)
	);
}

function propertyFields(fields = {}) {
	const { invoice_id = '' } = fields;

	return {
		invoice_id: [invoice_id, yup.string().required('Please enter invoice id')],
	};
}

function PurchaseOrderForm(props) {
	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation();

	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const { sku_id } = location.state ?? {};

	const skus = useCompanySKUs();

	const source_filter = {
		sku_types: [SKU_TYPES.TRACKED, SKU_TYPES.SERVICE, SKU_TYPES.RAW, SKU_TYPES.EXPIRING, SKU_TYPES.CONSUMABLE],
	};

	const [[sourceSkus, initSourceSkus], , initSources, SourceInventory, SourceModals] =
		useInventorySelectorHook({
			title: 'Order',
			filter: source_filter,
			submitForm,
			SKUModal: PurchaseSKUModal,
		});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
			properties: propertyFields(initialValues?.properties),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			const srcSkus = entity?.sku_sources ?? [];

			if (sku_id) {
				const exists = srcSkus.find((s) => s.sku_id === sku_id);
				if (!exists) {
					const selectedSku = skus.find((s) => s.sku_id === sku_id);
					if (selectedSku) {
						srcSkus.push(selectedSku);
					}
				}
			}

			initSources({
				...entity,
				skus: srcSkus,
			});
		},
		[sku_id, skus, initSources],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_sources: sourceSkus,
			};
		},
		[sourceSkus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Purchase Order'
				requestType={REQUEST_TYPES.purchase_order}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />
				<Properties />
				<FormSubmitter />
			</RequestForm>
			<SourceModals />
		</>
	);
}

export default withAppLayout(PurchaseOrderForm, { title: 'Purchase Order' });
