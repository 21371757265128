import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Pagination from '@pw/components/Pagination';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import { H5 } from '@pw/components/Typography';
import SKUListComponent from '@pw/components/admin/SKUListItem';
import { SKU_EDIT_URL, SKU_TYPE_REVERSE } from '@pw/consts/sku';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import usePaginatedList from '@pw/utilities/hooks/logic/usePaginatedList';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SKUList({ skuType }) {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState('5');

	const skuList = useCompanySKUs();
	const filteredSkus = skuList.filter((s) => s.sku_type === skuType);

	const [page, hasPrev, hasNext, loadPrev, loadNext] =
		usePaginatedList({ list: filteredSkus, pageSize });

	const goToItem = (params) => {
		const id = params?.sku_id ?? 'new';
		const route = SKU_EDIT_URL?.[skuType];
		navigate(`${route}/${id}`, { replace: true });
	};

	return (
		<Stack spacing={1}>
			<H5>SKU List</H5>
			{page && page.length > 0 && (
				<Stack spacing={0.5}>
					<Pagination
						hasNextPage={hasNext}
						hasPrevPage={hasPrev}
						onPressNextPage={loadNext}
						onPressPrevPage={loadPrev}
						onChangePageSize={setPageSize}
						defaultPageSize={pageSize}
					/>
					<Stack className='list'>
						{page.map((i) => (
							<SKUListComponent
								key={i.sku_id}
								item={i}
								buttonIcon={<ArrowForward />}
								onClick={() => goToItem(i)}
							/>
						))}
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}

function SKUNavigation({ skuType }) {
	const navigate = useNavigate();

	const goToItem = (params) => {
		const route = SKU_EDIT_URL[skuType];
		const id = params?.sku_id ?? 'new';
		console.log('Navigating to', params, route, id);
		navigate(`${route}/${id}`);
	};

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<Box className='section'>
				<GeneralSearch
					title='Search'
					types={{ sku_types: [skuType] }}
					label={SKU_TYPE_REVERSE[skuType]}
					buttonIcon={<ArrowForward />}
					onItemClick={goToItem}
				/>
			</Box>

			<SKUList skuType={skuType} />
		</Stack>
	);
}

export default withSmallDialogCard(SKUNavigation);
