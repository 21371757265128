import { AddCircle } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import { H5 } from '@pw/components/Typography';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import { useAllCompanies } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useState } from 'react';
import Company from './CompanyForm';

function CompanyItem({ entry }) {
  const {
    company_id, company_name, role, team,
  } = entry;

  // {company.company_status === 'pending' && <PendingActions title="Pending" aria-label="Pending" />}
  // {company.company_status === 'active' && <CheckBox />}
  // {company.company_status === 'suspended' && <WarningAmber />}

  return (
    <Box className="listItem">
      <Box className="listContent">
        <Stack direction="row" spacing="0.75rem">
          <Stack spacing={0.25}>
            <H5>{company_name}</H5>
            <IDDisplay value={company_id} />
            {role && <AccountDisplay name={role.id} />}
            {team && <AccountDisplay name={team.id} />}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

function Organizations() {
  const [target, scroll] = useScrollTarget();

  const companies = useAllCompanies();

  const [newCompany, setNewCompany] = useState(false);

  useEffect(() => scroll(), [companies]);

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      <Box className="header">
        <H5>Your Organizations</H5>

        <TitledButton
          handleClick={() => {
            setNewCompany(true);
            scroll();
          }}
          variant='outlined'
          color='secondary'
          label='New'
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>These are all the organizations you are a member of, own or assist. An organization can be used to access our products or simply for your trading activity.</Instructions>

      <Collapse in={newCompany}>
        <Company
          reload={() => {
            setNewCompany(false);
            scroll();
          }}
        />
      </Collapse>

      <Stack spacing={0} className="list">
        {companies.map((c) => <CompanyItem key={c.company_id} entry={c} />)}
      </Stack>
    </Stack>
  );
}

export default Organizations;
