import part1ReportFields from './part1ReportFields';

export default function part1Fields(fields = {}) {
	const {
		On_Hand_First_of_Month = '',
		Deposited_in_bulk_storage = '',
		Received_from_customs_custody = '',
		Returned_to_bulk_storage = '',
		Taxpaid = '',
		Use_of_the_United_States = '',
		Export = '',
		Transfer_to_foreign_trade_zone = '',
		Transfer_to_CMBW = '',
		Use_as_supplies_on_vessels_and_aircraft = '',
		Transfer_to_bonded_winery = '',
		Transfer_to_CBW = '',
		Research_development_or_testing = '',
		Transferred_to_Processing_account = '',
		Transferred_to_production_account = '',
		Transferred_to_other_bonded_premises = '',
		Destroyed = '',
		Other_losses = '',
		On_hand_end_of_month = '',
	} = fields;

	return {
		On_Hand_First_of_Month: part1ReportFields(On_Hand_First_of_Month),

		Deposited_in_bulk_storage: part1ReportFields(Deposited_in_bulk_storage),

		Received_from_customs_custody: part1ReportFields(
			Received_from_customs_custody,
		),

		Returned_to_bulk_storage: part1ReportFields(Returned_to_bulk_storage),

		Taxpaid: part1ReportFields(Taxpaid),

		Use_of_the_United_States: part1ReportFields(Use_of_the_United_States),

		Export: part1ReportFields(Export),

		Transfer_to_foreign_trade_zone: part1ReportFields(
			Transfer_to_foreign_trade_zone,
		),

		Transfer_to_CMBW: part1ReportFields(Transfer_to_CMBW),

		Use_as_supplies_on_vessels_and_aircraft: part1ReportFields(
			Use_as_supplies_on_vessels_and_aircraft,
		),

		Transfer_to_bonded_winery: part1ReportFields(Transfer_to_bonded_winery),

		Transfer_to_CBW: part1ReportFields(Transfer_to_CBW),

		Research_development_or_testing: part1ReportFields(
			Research_development_or_testing,
		),

		Transferred_to_Processing_account: part1ReportFields(
			Transferred_to_Processing_account,
		),

		Transferred_to_production_account: part1ReportFields(
			Transferred_to_production_account,
		),

		Transferred_to_other_bonded_premises: part1ReportFields(
			Transferred_to_other_bonded_premises,
		),

		Destroyed: part1ReportFields(Destroyed),

		Other_losses: part1ReportFields(Other_losses),

		On_hand_end_of_month: part1ReportFields(On_hand_end_of_month),
	};
}
