import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestID from '@pw/components/RequestID';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import { H5 } from '@pw/components/Typography';
import { REQUEST_EDIT_URL, REQUEST_TYPES } from '@pw/consts/requests';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { upsertRequestThunk } from '@pw/redux/thunks/request';
import styles from '@pw/styles/modal.styles';
import { useDispatch } from 'react-redux';

function DeliveryModal({ skus, entity, open, onClose }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const changeSet = requestIdFields({});

	const handleSubmit = useCallback(
		async (values) => {
			dispatch(upsertRequestThunk({
				...values,
				request_type: REQUEST_TYPES.delivery,
				entity,
				sku_destinations: skus.map((s) => ({
					...s,
					entries: s.entries?.length ? s.entries : [{ amount: 0 }],
				})),
			}))
				.unrwap()
				.then((response) => {
					const route = REQUEST_EDIT_URL?.[REQUEST_TYPES.delivery];
					navigate(`${route}/${response?.path}`, { replace: true });
				});
		},
		[entity, skus],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Create Delivery</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<FormikForm
					changeSet={changeSet}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					<FormWrapper>
						<RequestID
							label='ID.'
						/>

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
								label='Cancel'
							/>
							<FilledButton
								type='submit'
								size='small'
							>
								Create
							</FilledButton>
						</Box>
					</FormWrapper>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

export default DeliveryModal;
