import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from 'react';
import UploadButton from './UploadButton';
import MenuItem from '@mui/material/MenuItem';
import styles from './LoadButton.styles';
import { getDesignList, saveDesign} from "../../../services/production.service";
import DesignerContext from "../../../context/DesignerContext";

const LoadButton = ({
	type,
	filterFn,
	loadedFromName,
	value: _value = 'label',
	setValue: _setValue,
	allowUpload = false,
	forceRefresh = false,
	children,
	setIsLoading,
}) => {

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(_value || 'label');
  const [localOptions, setLocalOptions] = useState([]);

	const { setSkuId, skuId, setBatchId, refreshOptions } = useContext(DesignerContext);

  useEffect(() => {
		if (type === 'batch' && !skuId) return;

		getDesignList({type, sku_id: skuId}).then((list) => {
			const newOpts = typeof filterFn === 'function'
				? list.filter(filterFn)
				: list
			const resOpts = newOpts.map(({name, path, sku_id}) => ({name, path, sku_id}))
			setLocalOptions(resOpts);
		});
  }, [filterFn, refreshOptions]);

	const loadDesign = async () => {
		if (value === 'label' || value === 'create') return
		setIsLoading(true);
		try {
			if (type === 'recipe') {
				setSkuId(value)
			} else {
				setBatchId(value)
			}
		} catch (e) {
			console.log(`couldn't load design`)
		}
		setIsLoading(false);
	};

  useEffect(() => {
		loadDesign();
  }, [localOptions, value, loadedFromName]);

  useEffect(() => {
    if (!forceRefresh) return;
		loadDesign();
  }, [localOptions, value, loadedFromName, forceRefresh]);

  useEffect(() => {
    if (typeof _setValue === 'function') _setValue(value);
    console.log('syncing value', { value, _setValue, typeOf: typeof _setValue })
  }, [value])

  return (
    <Box className="load-button-root" sx={styles}>
      <Select
        open={open}
        value={value}
        onClick={() => { setOpen((curr) => !curr); }}
        variant='filled'
        onChange={(e) => {
          setValue(e.target.value);
        }}
      // sx={selectStyles}
      // menuProps={{
      //   sx: selectStyles
      // }}
      >
        <MenuItem
          disabled
          value="label"
        >
          {`Select ${type}`}
        </MenuItem>
        {allowUpload && (
          <MenuItem
            value="upload">
            <UploadButton
              label={`Upload ${type}`}
              callback={(i) => {
                setLocalOptions((curr) => [...curr, {path: i.path, name: i.name}]);
                setOpen(false);
                setValue(i.path);
								saveDesign(i);
              }}
            />
          </MenuItem>
        )}
        {/* {createHandler && (
          <MenuItem
            handleClick={(e) => {
              console.log('CREATE CLICK INTO', e)
              e.stopImmediatePropagation();
              e.preventDefault(true);
              return false;
              // createHandler(
              //   e,
              //   localOptions, setLocalOptions,
              //   open, setOpen,
              //   value, setValue,
              // );
            }}
          >
            <TextField />
            <FilledButton
            >Create {type}</FilledButton>
          </MenuItem>
        )} */}
        {localOptions.map(({ name, path, sku_id }) => {
          return (
            <MenuItem
              value={type === 'recipe' ? sku_id : path}
              key={path}
            >{type === 'recipe' ? sku_id : name}</MenuItem>
          )
        }
        )}
        {children}
      </Select>
    </Box >
  );
}

export default LoadButton;
