import generalInformationFields from './generalInformationFields';
import part1Fields from './part1Fields';
import part2Fields from './part2Fields';
import part3Fields from './part3Fields';
import part4Fields from './part4Fields';
import signatureFields from './signatureFields';

export default function TTBReport_24Fields(fields = {}) {
	const { general_information, part1, part2, part3, part4, signature } = fields;

	return {
		general_information: generalInformationFields(general_information),
		part1: part1Fields(part1),
		part2: part2Fields(part2),
		part3: part3Fields(part3),
		part4: part4Fields(part4),
		signature: signatureFields(signature),
	};
}
