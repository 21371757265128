import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import fetchPut from '@pw/utilities/fetchPut';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/request`;

export const getRequest = async (params, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const createRequest = async (request, headers = {}) =>
	fetchPost(`${BASE}`, headers, request);

export const recent = async (request, headers = {}) =>
	fetchPost(`${BASE}/recent`, headers, request);

export const updateRequest = async (request, headers = {}) =>
	fetchPut(`${BASE}`, headers, request);

export const getRequestsList = async (headers = {}) =>
	fetchGet(`${BASE}/list`, headers);

export const updateRequestAdmin = async (request, headers = {}) =>
	fetchPut(`${BASE}/admin`, headers, request);

export const updateRequestAssign = async (request, headers = {}) =>
	fetchPut(`${BASE}/assign`, headers, request);

export const migrateRequest = async (request, headers = {}) =>
	fetchPost(`${BASE}/migrate`, headers, request);
