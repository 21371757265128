import generalInformationFields from './generalInformationFields';
import part1Fields from './part1Fields';
import part2Fields from './part2Fields';
import part3Fields from './part3Fields';

export default function TTBReport_75Fields(fields = {}) {
	const { general_information, part1fields, part2fields, part3fields } = fields;

	return {
		general_information: generalInformationFields(general_information),
		Part1: part1Fields(part1fields),
		Part2: part2Fields(part2fields),
		Part3: part3Fields(part3fields),
	};
}
