import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Body3 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { getTags } from '@pw/services/tag.service';
import { br3 } from '@pw/styles/border';
import { primary } from '@pw/styles/colors';
import { ph2, pv2 } from '@pw/styles/padding';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

const styles = {
	root: {
		width: '100%',
		...ph2,
		...pv2,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		// ...ba,
		// ...b__black_20,
		...br3,
	},
	// textField: {
	//   '& .MuiOutlinedInput-root': {
	//     '& fieldset': {
	//       border: 'none',
	//     },
	//   },
	//   '& .MuiInputBase-input': {
	//     ...pv0,
	//     ...bg_near_white,
	//     ...shadow_inset_1,
	//     ...br3,
	//   },
	// },
};

export function TagSelect({ name, type = 'cask', disabled = false, initialTags=[] }) {
	const { values, setFieldValue, readonly } = useContext(FormikContext);

	const [options, setOptions] = useState([]);
	const [tags, setTags] = useState([...(values?.[name] ?? []), ...initialTags]);
	const tagRef = useRef();

	const handleDelete = useCallback(
		(value) => {
			const newtags = tags.filter((val) => val !== value);
			setTags(newtags);
			setFieldValue(name, newtags);
		},
		[tags],
	);

	const checkTag = useCallback(
		(e) => {
			const tag = tagRef.current.value.trim().toLowerCase();
			if (tag) {
				if (e.code === 'Space') {
					e.preventDefault();
					if (!tags.includes(tag) && tag.length > 1) {
						const tv = [...tags, tag];
						setTags(tv);
						setFieldValue(name, tv);
					}
					tagRef.current.value = '';
				} else {
					//
					return debounce(() => {
						if (tag) {
							getTags({ tag_category: type, tag_label: tag })
								.then((r) => {
									setOptions(r.items);
								})
								.catch((ex) => {
									console.log('Error getting tags', ex);
									setOptions([]);
								});
						}
					}, 200);
				}
			}
		},
		[tags, tagRef],
	);

	const handleSelect = useCallback(
		(data) => {
			if (!tags.includes(data)) {
				const tv = [...tags, data];
				setTags(tv);
				setFieldValue(name, tv);
			}
			tagRef.current.value = '';

			// Clear the options after a short delay
			const clearOptions = setTimeout(() => setOptions([]), 5000);
			return () => clearTimeout(clearOptions);
		},
		[tags, tagRef],
	);

	useEffect(() => {
		debounce(() => setTags([...(values?.[name] ?? []), ...initialTags]), 50);
	}, [values?.[name], initialTags]);

	return (
		<Stack sx={styles.root} spacing={2}>
			{tags.length > 0 && (
				<Box sx={{ display: 'flex-wrap' }}>
					{tags.map((data) => (
						<Chip
							color='primary'
							sx={{ ...primary, margin: '0.1rem' }}
							label={data}
							key={data}
							size='small'
							onDelete={() => handleDelete(data)}
						/>
					))}
				</Box>
			)}
			<Stack spacing={0.25}>
				<TextField
					type='input'
					variant='filled'
					inputRef={tagRef}
					fullWidth
					size='small'
					margin='none'
					placeholder='Tags'
					onKeyUp={checkTag}
					disabled={disabled}
					onKeyDown={(e) => {
						if (e.key === 'Enter') e.preventDefault();
					}}
					InputProps={{
						disableUnderline: true,
						hiddenLabel: true,
						readOnly: readonly,
					}}
				/>
				{options.length > 0 && (
					<Box sx={{ display: 'flex-wrap' }}>
						{options.map((data) => (
							<Chip
								clickable
								variant='outlined'
								label={data.tag_label}
								key={data.path}
								size='small'
								sx={{ margin: '0.1rem' }}
								onClick={() => handleSelect(data.tag_label)}
							/>
						))}
					</Box>
				)}
				<Body3>Type a tag word and press space to add to the list</Body3>
			</Stack>
		</Stack>
	);
}
