import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import CompanyMemberships from '@pw/components/Company/CompanyMemberships';
import CompanySettings from '@pw/components/Company/CompanySettings';
import CompanyType from '@pw/components/Company/CompanyType';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { H5, Overline } from '@pw/components/Typography';
import { useCurrentCompany } from '@pw/redux/containers/User';
import { updateCompanyThunk } from '@pw/redux/thunks/company';
import styles from '@pw/styles/content.styles';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import Address from './Address';

function CompanyForm({ company }) {
	const dispatch = useDispatch();

	const {
		company_compliance = {},
		company_settings = { imperial: false },
		company_address = {},
		company_type = null,
		company_features = {},
	} = company;

	const {
		warehousing = false,
		production = false,
		bottling = false,
	} = company_features;

	const changeSet = {
		// Set the editable properties
		excise_id: [company_compliance.excise_id ?? '', yup.string()],
		compliance_id: [company_compliance.compliance_id ?? '', yup.string()],
		wowgr: [company_compliance.wowgr, yup.boolean()],
		// settings
		imperial_units: [company_settings.imperial, yup.boolean()],
		//  address
		line1: [
			company_address.address_1 ?? '',
			yup.string().required('Address is required!'),
		],
		line2: [company_address.address_2 ?? '', yup.string()],
		line3: [company_address.address_3 ?? '', yup.string()],
		city: [
			company_address.city ?? '',
			yup.string().required('City is required!'),
		],
		state: [company_address.state ?? '', yup.string()],
		country: [
			company_address.country ?? '',
			yup.string().required('Country is required!'),
		],
		postCode: [
			company_address.post_code ?? '',
			yup.string().required('Post code is required!'),
		],
		company_type: [
			company_type ?? '',
			yup.string().required('Company type is required!'),
		],
		warehousing: [warehousing, yup.boolean()],
		production: [production, yup.boolean()],
		bottling: [bottling, yup.boolean()],
	};

	const onSubmit = (values) => {
		dispatch(updateCompanyThunk({
			id: company.id,
			path: company.path,
			...values,
		}));
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={onSubmit}>
			<Stack className='form' spacing='2rem'>
				<Stack spacing='1.5rem'>
					<Stack spacing='0.75rem'>
						<Overline>Name</Overline>
						<H5>{company.company_name}</H5>
					</Stack>
					<Stack spacing='0.75rem'>
						<Overline>Proofworks ID</Overline>
						<H5>{company.company_id}</H5>
					</Stack>
					<Stack spacing='0.75rem'>
						<Overline>Registration</Overline>
						<H5>{company_compliance.registration_id}</H5>
					</Stack>
					<FormikTextField name='excise_id' label='Excise/Tax id' />
					<FormikTextField
						name='compliance_id'
						label='Compliance reporting id'
					/>
				</Stack>

				<Stack spacing='1.5rem'>
					<H5>Address</H5>
					<Address />
				</Stack>

				<CompanyType />

				<CompanyMemberships />

				<CompanySettings />

				<Box className='action-buttons'>
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

function Company() {
	const [target, scroll] = useScrollTarget();

	const company = useCurrentCompany();

	useEffect(() => scroll(), [company]);

	return (
		<Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
			{company && <CompanyForm company={company} />}
		</Stack>
	);
}

export default Company;
