import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import GeneralSKU from '@pw/components/SKUSelector/modals/dest/GeneralSKU';
import TrackedSKU from '@pw/components/SKUSelector/modals/dest/TrackedSKU';
import WasteSKU from '@pw/components/SKUSelector/modals/dest/WasteSKU';
import { Body3, H5 } from '@pw/components/Typography';
import { SKU_TYPES } from '@pw/consts/sku';
import styles from '@pw/styles/modal.styles';

function DestinationSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_type, sku_description } = item ?? {};

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing="2rem">
				<FlexBox>
					<Stack spacing={1}>
						<H5>{sku_name}</H5>
						<Body3>{sku_description}</Body3>
					</Stack>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					{[SKU_TYPES.TRACKED].includes(sku_type) && (
						<TrackedSKU item={item} onClose={onClose} {...rest} />
					)}

					{[SKU_TYPES.WASTE].includes(sku_type) && (
						<WasteSKU item={item} onClose={onClose} {...rest} />
					)}

					{[SKU_TYPES.FINISHED, SKU_TYPES.WIP].includes(sku_type) && (
						<GeneralSKU item={item} onClose={onClose} {...rest} />
					)}
				</Box>
			</Stack>
		</Modal>
	);
}

export default DestinationSKUModal;
