import { confirmPartnerRequest, createPartner, listPartners, shareWithPartner, updatePartner } from "@pw/services/company.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const refetchPartnersThunk = createAsyncThunk(
  'partners/refetch',
  async (params, { rejectWithValue }) => {
    try {
      return await listPartners({...params, force: true});
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const upsertPartnerThunk = createAsyncThunk(
  'partners/upsert',
  async (params, { rejectWithValue}) => {
    try {
      return await (params.path ? updatePartner(params) : createPartner(params));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmPartnerThunk = createAsyncThunk(
  'partners/confirm',
  async (params, { rejectWithValue }) => {
    try {
      return await confirmPartnerRequest(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const shareWithPartnerThunk = createAsyncThunk(
  'partners/share',
  async (params, { rejectWithValue }) => {
    try {
      return await shareWithPartner(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);