import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

export default function MaterialsUsedReport({
	name,
	title = 'Corn',
	disabled = false,
	specify = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Kind'
				// required
				fullWidth
				disabled={!specify}
			/>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='b'
					label='Used in Distilled Spirits Pounds'
					// required
					fullWidth
					disabled={specify}
				/>
				<FormikTextField
					name='c'
					label='Used in Distilled Spirits Gallons'
					// required
					fullWidth
					disabled={specify}
				/>
			</FlexBox>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='d'
					label='Other Than Distilled Spirits Pounds'
					// required
					fullWidth
					disabled={specify}
				/>
				<FormikTextField
					name='d'
					label='Other Than Distilled Spirits Gallons'
					// required
					fullWidth
					disabled={specify}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
