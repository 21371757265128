export const PERMISSIONS = {
	USER_ADMIN: BigInt('0b10'),
	COMPANY_ADMIN: BigInt('0b100'),
	FACILITY_ADMIN: BigInt('0b1000'),
	SKU_ADMIN: BigInt('0b10000'),
	VIEW_ACTIVITY: BigInt('0b100000'),
	MODIFY_REQUEST: BigInt('0b1000000'),
	EDIT_LOCATION: BigInt('0b10000000'),
	EDIT_OWNERS: BigInt('0b100000000'),
	EDIT_LIQUID: BigInt('0b1000000000'),
	CAN_APPROVE: BigInt('0b10000000000'),
	ASSIGN_TASK: BigInt('0b100000000000'),
	TOKEN_MINT: BigInt('0b1000000000000'),
	CONTRACT_INSTALL: BigInt('0b10000000000000'),
	FUND_ACCOUNT: BigInt('0b100000000000000'),
	VIEW_COMPANY_LIST: BigInt('0b1000000000000000'),
	SENSOR_ADMIN: BigInt('0b10000000000000000'),
	MODIFY_ASSET: BigInt('0b100000000000000000'),
};
//
// USER_ADMIN: BigInt('0b10'),
//   COMPANY_ADMIN: BigInt('0b100'),
//   FACILITY_ADMIN: BigInt('0b1000'),
//   SKU_ADMIN: BigInt('0b10000'),
//   VIEW_ACTIVITY: BigInt('0b100000'),
//   MODIFY_REQUEST: BigInt('0b1000000'),
//   EDIT_LOCATION: BigInt('0b10000000'),
//   EDIT_OWNERS: BigInt('0b100000000'),
//   EDIT_LIQUID: BigInt('0b1000000000'),
//   CAN_APPROVE: BigInt('0b10000000000'),
//   ASSIGN_TASK: BigInt('0b100000000000'),
