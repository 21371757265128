import InventorySelector from '@pw/components/InventorySelector';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';

function ParentAssetID({ hasParent }) {
	const { enqueueSnackbar } = useSnackbar();

	const { values, setFieldValue } = useContext(FormikContext);

	const [parentAsset, , addAsset, , removeAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
		values?.['parent_asset']?.['rw_asset_id'] ? [values['parent_asset']] : [],
		{
			onRemove: () => {
				debounce(() => {
					setFieldValue('parent_asset_id', undefined);
					setFieldValue('parent_asset', {});
				}, 50);
			},
			onAdd: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							asset_id,
							asset_status,
							rw_asset_id,
							asset_type,
							unique_location_id,
						} = toTaggedAsset(items[0]);

						if (asset_status === ASSET_STATUS.DEFECTED) {
							enqueueSnackbar(`Defective assets cannot be selected!`, {
								variant: 'error',
							});
							return;
						}

						setFieldValue('parent_asset_id', asset_id);
						setFieldValue('parent_asset', {
							rw_asset_id,
							asset_type,
							unique_location_id,
						});
					}, 50);
				}
			},
			onUpsert: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							asset_id,
							asset_status,
							rw_asset_id,
							asset_type,
							unique_location_id,
						} = items[0];

						if (asset_status === ASSET_STATUS.DEFECTED) {
							enqueueSnackbar(`Defective assets cannot be selected!`, {
								variant: 'error',
							});
							return;
						}

						setFieldValue('parent_asset_id', asset_id);
						setFieldValue('parent_asset', {
							asset_id,
							rw_asset_id,
							asset_type,
							unique_location_id,
						});
					}, 50);
				}
			},
		},
	);

	useEffect(() => {
		hasParent && hasParent(!!values['parent_asset_id']);
	}, [hasParent, values]);

	return (
		<InventorySelector
			sectionTitle='Pallet'
			list={parentAsset}
			types={{ asset_types: [ASSET_TYPES.pallet] }}
			single
			onAdd={(i) => addAsset(i, true)}
			onRemove={(i) => removeAsset(i, true)}
			summary={false}
		/>
	);
}

export default ParentAssetID;
