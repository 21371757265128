import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikNumberField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import { useMemo } from 'react';
import * as yup from 'yup';

function MashSKUModal({ item, open, onClose }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_id, sku_name, sku_description, percent = '', age = '' } = item ?? {};

	const changeSet = useMemo(
		() => ({
			percent: [
				percent,
				yup.number().positive('Must be positive!').required('Percentage required!'),
			],
			age: [
				age,
				yup.number().positive('Must be positive!'),
			],
		}),
		[percent, age],
	);

	const handleSubmit = (values) => {
		const entry = {
			sku_id: item.sku_id,
			sku_name: item.sku_name,
			sku_description: item.sku_description,
			sku_type: item.sku_type,
			...values,
		};
		console.log('Updating sku..', entry);
		onClose(entry);
	};

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{sku_name}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<Instructions>{sku_description}</Instructions>

					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={1}>
							<FlexBox>
								<FormikNumberField label='Percent' name='percent' fullWidth />
								<FormikNumberField label='Age' name='age' fullWidth />
							</FlexBox>

							<AlertView />

							<Box className='action-buttons'>
								<TextButton size='small' handleClick={() => onClose()} color='secondary'>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small'>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default MashSKUModal;
