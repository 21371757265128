import { Box } from '@mui/material';
import withDialogCard from '@pw/components/Cards/DialogCard';
import LatestAddedSKUs from '@pw/components/LatestAdded/LatestAddedSKUs';
import SKUForm from '@pw/components/admin/SKUForm/SKUForm';
import SKUSummary from '@pw/components/summary/SKUSummary';
import { SKU_EDIT_URL } from '@pw/consts/sku';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import debounce from '@pw/utilities/debounce';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const SKUFormHandlerImpl = withDialogCard(
	({ children, skuType, readonly, onSave, ...rest }) => {
		const navigate = useNavigate();
		const [target, scroll] = useScrollTarget();

		// eslint-disable-next-line react-hooks/exhaustive-deps
		useEffect(scroll, []);

		const handleSave = (mutatedSku) => {
			console.log('Mutated sku', mutatedSku);

			debounce(() => {
				onSave?.(mutatedSku);
				const route = SKU_EDIT_URL?.[skuType];
				navigate(`${route}/${mutatedSku?.sku_id}`, { replace: true });
			}, 50);
		};

		return (
			<Box sx={styles} className='root' ref={target}>
				<SKUForm
					onSave={handleSave}
					readonly={readonly}
					{...rest}
				>
					{children}
				</SKUForm>
			</Box>
		);
	},
);

function NewForm({ changeSetGenerator, onInit, ...rest }) {
	const [searchParams] = useSearchParams();

	const { skuType, skuLabel } = rest;

	usePageTitleHook(`Create ${skuLabel}`);

	// // // AI stuff disabled for now
	// // // const [suggested, setSuggested] = useState(null);
	const clone = searchParams?.get('clone');

	const formValues = useMemo(() => {
		let obj = {};
		if (clone) {
			try {
				const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
				obj = JSON.parse(cv);
			} catch (e) {
				console.log('Failed to decode clone!', e);
			}
		}

		// Always call onInit, but with different arguments
		if (onInit) {
			onInit(clone ? obj : {});
		}

		return { ...obj, sku_type: skuType };
	}, [skuType, clone, onInit]);

	const changeSet = useMemo(
		() => changeSetGenerator(formValues),
		[changeSetGenerator, formValues],
	);

	return (
		<>
			 <SKUFormHandlerImpl changeSet={changeSet} {...rest} />
		</>
	);
}

function ShowSKU({ changeSetGenerator, onInit, skuLabel, ...rest }) {
	const { id, skuType } = rest;

	const skuList = useCompanySKUs();

	const entity = skuList.find((s) => s.sku_id === id);

	const readonly = useCheckCompany(entity);

	usePageTitleHook(`${skuLabel} ${entity?.sku_name ?? '...'}`);

	// Generate the change set
	const formValues = useMemo(() => {
		// Specific initializer
		if (onInit) {
			onInit(entity);
		}
		return { ...(entity ?? {}), sku_type: skuType };
	}, [entity, skuType]);

	const changeSet = useMemo(
		() => changeSetGenerator(formValues),
		[changeSetGenerator, formValues],
	);

	const refreshData = () => {

	};

	return (
		<Box className='adaptive-content'>
			{!entity && (
				<NewForm
					changeSetGenerator={changeSetGenerator}
					onInit={onInit}
					skuLabel={skuLabel}
					{...rest}
				/>
			)}
			{entity && changeSet && (
				<>
					<SKUSummary entity={entity} refresh={refreshData} />
					<SKUFormHandlerImpl
						entity={entity}
						changeSet={changeSet}
						edit
						readonly={readonly}
						onSave={refreshData}
						{...rest}
					/>
				</>
			)}
			<LatestAddedSKUs skuType={skuType} />
		</Box>
	);
}

function SKUFormHandler(props) {
	let { id } = useParams();

	// console.log('SKUFormHandler', id, props);

	return (
		<>
			{!id && (
				<Box className='adaptive-content'>
					<NewForm {...props} />
					<LatestAddedSKUs skuType={props.skuType} />
				</Box>
			)}
			{id && <ShowSKU id={id} {...props} />}
		</>
	);
}

export default SKUFormHandler;
