import { AccountCircleRounded, AddCircle, PendingActions, Send } from '@mui/icons-material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box, Collapse, Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body3, H5, Overline } from '@pw/components/Typography';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import { useCompanyUsers } from '@pw/redux/containers/User/hooks';
import { removeUserThunk } from '@pw/redux/thunks/user';
import styles from '@pw/styles/content.styles';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import User from './UserForm';

function UserItem({ user, disableAccount, editInvite }) {
  const { path, account_id, account_username, account_name, role, team, invited, joined } = user;
  // console.log('%cindex.js line:14 user', 'color: white; background-color: #007acc;', account);
  return (
    <Box className="listItem">
      <FlexBox>
        <Box className="listContent">
          <Stack direction='row' spacing='0.75rem'>
            {!account_id && (
              <PendingActions title='Pending' aria-label='Pending' />
            )}
            {account_id && <AccountCircleRounded />}
            <Stack spacing={0.25}>
              <H5>{account_name}</H5>
              <IDDisplay value={account_id} />
              <Body3>{account_username}</Body3>
              {role && <AccountDisplay name={role.id} />}
              {team && <AccountDisplay name={team.id} />}
              {!account_id && <Overline>{new Date(invited).toLocaleString()}</Overline>}
            </Stack>
          </Stack>
        </Box>

        <Stack className='listButtons'>
          {account_id && joined && (
            <IconCircleButton onClick={() => disableAccount(user)}>
              <PersonOffIcon />
            </IconCircleButton>
          )}

          {(!account_id || !joined) && (
            <IconCircleButton
              onClick={() => editInvite({ name: account_name, email: path.split('/').reverse()[0], role_id: role?.id ? `/r/${role?.id}` : undefined, team_id: team?.id ? `/t/${team?.id}` : undefined })}
            >
              <Send />
            </IconCircleButton>
          )}
        </Stack>
      </FlexBox>
    </Box>
  );
}

function Users() {
  const dispatch = useDispatch();
  const users = useCompanyUsers();

  // console.log('%cindex.js line:57 users', 'color: white; background-color: #007acc;', users);

  const [target, scroll] = useScrollTarget();

  const [user, setUser] = useState(null);

  useEffect(() => scroll(), [scroll]);

  const disableAccount = (user) => dispatch(removeUserThunk({ user_id: user.account_id }));

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      <Box className="header">
        <H5>Manage Users</H5>

        <TitledButton
          handleClick={() => {
            setUser({});
            scroll();
          }}
          variant='outlined'
          color='secondary'
          label='New'
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>Manage the users in your organization.</Instructions>

      <Collapse in={!!user} unmountOnExit>
        <User
          user={user}
          onClose={() => {
            setUser(null);
            scroll();
          }}
        />
      </Collapse>

      <Stack spacing={0} className="list">
        {users.map((p) => <UserItem key={p.account_id} user={p} disableAccount={disableAccount} editInvite={setUser} />)}
      </Stack>

    </Stack>
  );
}

export default Users;
