import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import {
	FormikForm,
	FormikNumberField
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { useMemo } from 'react';
import * as yup from 'yup';

function ExpensesSKU({ item, onClose }) {
	const { price } = item ?? {};

	const changeSet = useMemo(
		() => ({
			price: [
				price ?? '',
				yup.number().positive('Must be positive!').required('Price required!'),
			],
		}),
		[price],
	);

	const handleSubmit = (values) => {
		const sku = {
			...item,
			price: values.price,
		};
		console.log('Setting SKU', values, sku);
		onClose(sku);
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={1}>
				<FlexBox>
					<FormikNumberField label='Price' name='price' fullWidth />
				</FlexBox>

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default ExpensesSKU;
