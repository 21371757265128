import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { LocationSelect } from '@pw/components/ExplorerLocation';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useEffect } from 'react';

function LocationImpl({
	hide,
	setLocation,
	disabled = false,
	showCheckBox = true,
}) {
	const { values, setFieldValue } = useContext(FormikContext);

	const facilities = useCompanyFacilities();

	useEffect(() => {
		if (hide) {
			debounce(() => setFieldValue('enable', false), 50);
		}
	}, [hide]);

	const setValues = (facility_id, bay, row, level) => {
		console.log('Setting location', facility_id, bay, row, level);

		debounce(() => {
			setFieldValue('facility_id', facility_id);
			setFieldValue('bay', bay);
			setFieldValue('row', row);
			setFieldValue('level', level);
		}, 25);
	};

	const setLocationValues = useCallback(
		(facility_id, bay, row, level) => {
			console.log('Checking location', facility_id, bay, row, level);
			// First find the facility
			if (!facilities?.facility_id) {
				setValues('', '', '', '');
				return;
			}
			const facility = facilities[facility_id];

			console.log('Facility', facility);

			// Find the bay
			const facilityBay = facility.bays.find(([b]) => b === bay);
			if (!facilityBay) {
				setValues(facility.facility_id, '', '', '');
				return;
			}
			console.log('Bay, facilityBay', facilityBay);
			setValues(facility_id, bay, row, level);
		},
		[setFieldValue, facilities],
	);

	const handleOnQrSuccess = useCallback(
		(data) => {
			// const { text } = data; // { t: 'loc', l: ['f', 'b', 'r', 'lv'] }
			// try {
			// 	const { t, l } = JSON.parse(text);
			// 	if (t && l && t === 'loc' && Array.isArray(l)) {
			// 		const [f, b, r, lv] = l;
			// 		setLocationValues(f, b, r, lv);
			// 	}
			// } catch (e) {
			// 	console.log('Invalid location!', text, e);
			// }
			const { text } = data; // 'https://test.metacask.com:4430/explorer?dt=1,2,3,4';
			const [facility_id, bay, row, level] = decodeURIComponent(
				text.split('?dt=')[1],
			).split(',');
			setLocationValues(facility_id, bay, row, level);
		},
		[setLocationValues],
	);

	// will remove form data if location is disabled
	useEffect(() => {
		if (values?.enable === false) {
			setValues('', '', '', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.enable]);

	return (
		<Stack>
			{!!showCheckBox && (
				<FormikCheckBox
					name='enable'
					label={<H5>Location</H5>}
					disabled={hide || disabled}
				/>
			)}

			<Collapse in={showCheckBox ? !!values?.enable : !showCheckBox}>
				<FlexBox alignItems='flex-start' gap={2}>
					<ScanQR
						onSuccess={handleOnQrSuccess}
						variant='outlined'
						color='secondary'
						sx={{ width: '72px', height: '55px' }}
						width={24}
						height={24}
						withLabel
						disabled={disabled}
						Component={TitledButton}
					/>
					<LocationSelect
						facilities={facilities}
						setLocation={setLocation}
						disabled={disabled}
					/>
				</FlexBox>
			</Collapse>
		</Stack>
	);
}

export default function Location({ name, ...rest }) {
	const { readonly } = useContext(FormikContext);
	return (
		<FormikProvider path={name}>
			<LocationImpl {...rest} disabled={readonly || rest.disabled} />
		</FormikProvider>
	);
}
