import Box from '@mui/material/Box';
import SideBar from '@pw/components/Layout/SideBar';
import TitleBar from '@pw/components/TitleBar';
import { useCompanyName } from '@pw/redux/containers/User';
import { useState } from 'react';
import AppHeader from './AppHeader';
import styles from './app-layout.styles';

export function AppLayout({ children, ...rest }) {
	const companyName = useCompanyName();

	// By default menu is always closed - unless in desktop mode
	const [toggleMenu, setToggleMenu] = useState(false);

	return (
		<Box sx={styles} className='root'>
			<AppHeader
				{...rest}
				toggleMenu={toggleMenu}
				setToggleMenu={() => setToggleMenu((v) => !v)}
			/>

			<Box className='content'>
				<SideBar
					active={true}
					companyName={companyName}
					toggleMenu={toggleMenu}
				/>

				<Box className='wrapper-content'>
					<TitleBar {...rest} />
					<Box className='wrapper-box'>{children}</Box>
				</Box>
			</Box>
		</Box>
	);
}

export const withAppLayout = (WrappedComponent, properties) =>
	function WithAppLayout(props) {
		return (
			<AppLayout {...props} {...properties}>
				<WrappedComponent {...props} />
			</AppLayout>
		);
	};
