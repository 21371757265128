import { AddCircle } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import { Body3, H5 } from '@pw/components/Typography';
import { useCompanyRoles } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useState } from 'react';
import Role from './RoleForm';

function RoleItem({ role }) {
  const roleName = role.path.replace('/r/', '');

  return (
    <Box className="listItem">
      <Box className="listContent">
        <H5>{roleName}</H5>
        <Body3>{role.description}</Body3>
      </Box>
    </Box>
  );
}

function Roles() {
  const [target, scroll] = useScrollTarget();

  const roles = useCompanyRoles();

  const [role, setRole] = useState(null);

  useEffect(() => scroll(), [scroll]);

  return (
    <Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
      <Box className="header">
        <H5>Manage Roles</H5>

        <TitledButton
          handleClick={() => {
            setRole({});
            scroll();
          }}
          variant='outlined'
          color='secondary'
          label='New'
          sx={{ width: '42px', height: '52px' }}
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>Configure the roles for users in your organization.</Instructions>

      <Collapse in={!!role} unmountOnExit>
        <Role
          role={role}
          onClose={() => {
            setRole(null);
            scroll();
          }}
        />
      </Collapse>

      <Stack spacing={0} className="list">
        {roles.map((p) => <RoleItem key={p.path} role={p} />)}
      </Stack>

    </Stack>
  );
}

export default Roles;
