import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Overline } from '../Typography';

const styles = {
	'&.root': {
		color: '#fff',
		fill: '#fff',
		zIndex: 9999,

		'.box': {
			minWidth: 'min-content',
			minHeight: 'auto',
			padding: '10px',
			gap: '5px',
			borderRadius: '10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			backgroundColor: 'rgba(0, 0, 0, 0.15)',
			backdropFilter: 'blur(5px)',

			'.spinner': {
				margin: 'auto',
			},
		},
	},
};

export default function Loading({ loading }) {
	console.log('loading', loading);
	return (
		<Backdrop sx={styles} className='root' open={!!loading}>
			<Box className='box'>
				<CircularProgress className='spinner' color='inherit' />
				{typeof loading === 'object' && loading?.message && <Overline>{loading.message}</Overline>}
			</Box>
		</Backdrop>
	);
}
