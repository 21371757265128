import { styles } from 'react-qr-reader/src/QrReader/styles';
import { useQrReader } from './hooks';

export const QrReader = ({
	videoContainerStyle,
	containerStyle,
	videoStyle,
	constraints,
	ViewFinder,
	scanDelay,
	className,
	onResult,
	videoId,
	onControls,
}) => {
	useQrReader({
		constraints,
		scanDelay,
		onResult,
		videoId,
		onControls,
	});

	return (
		<section className={className} style={containerStyle}>
			<div
				style={{
					...styles.container,
					...videoContainerStyle,
				}}
			>
				{!!ViewFinder && <ViewFinder />}
				<video
					muted
					id={videoId}
					style={{
						...styles.video,
						...videoStyle,
						transform: constraints?.facingMode === 'user' && 'scaleX(-1)',
					}}
				/>
			</div>
		</section>
	);
};

QrReader.displayName = 'QrReader';
QrReader.defaultProps = {
	constraints: {
		facingMode: 'user',
	},
	videoId: 'video',
	scanDelay: 500,
	onControls: (ctrl) => {
		console.log('controls', ctrl);
	},
};
