import i18n from '@pw/consts/i18n';

export const REPORT_STATUS = {
	PENDING: 'pending',
	APPROVED: 'approved',
};

export const REPORT_TYPES = {
	MONTHLY: 'm',
	INSTANT: 'i',
};

export const REPORT_OPTIONS = [
	{ label: i18n.t('monthly'), value: 'm' },
	{ label: i18n.t('instant'), value: 'i' },
];

export const REPORT_OPTIONS_US = [
	{ label: 'TTB F 5110.75', value: '5110.75' },
	{ label: 'TTB F 5000.24', value: '5000.24' },
	{ label: 'TTB F 5110.43', value: '5110.43' },
	{ label: 'TTB F 5110.28', value: '5110.28' },
	{ label: 'TTB F 5110.40', value: '5110.40' },
	{ label: 'TTB F 5110.11', value: '5110.11' },
];

export const REPORT_EDIT_URL = {
	m: '/app/reports/monthly',
	i: '/app/reports/instant',
};
