import { Print } from '@mui/icons-material';
import { Box } from '@mui/material';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import styles from './index.style';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKULocatorQRCode from '@pw/components/QRImage/SKULocatorQRCode';
import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS } from '@pw/consts/units';
import { useCompanySKUs, useCurrentCompany } from '@pw/redux/containers/User';
import convertStyleObjectToCSS from '@pw/utilities/convertStyleObjectToCSS';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import printElement from '@pw/utilities/printElement';
import { useSnackbar } from 'notistack';
import { useMemo, useRef } from 'react';
import Barcode from 'react-barcode';
import { useLocation, useNavigate } from 'react-router-dom';

function StandardLabel() {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation();
	const converter = useConverter();
	const liquidTypes = useLiquidTypes();

	const company = useCurrentCompany();
	const skuList = useCompanySKUs();

	const printRef = useRef();

	const { sku_id, labels, release: release_id } = location.state ?? {};

	if (!sku_id || !labels || !release_id) {
		enqueueSnackbar('No release specified!', { variant: 'error' });
		navigate('/admin/skus/finished');
	}

	const sku = skuList.find((s) => s.sku_id === sku_id);
	const properties = sku?.properties;

	if (!sku || sku.sku_type !== SKU_TYPES.FINISHED || !properties) {
		enqueueSnackbar('Invalid SKU!', { variant: 'error' });
		navigate(`/admin/skus/finished/${sku_id}`);
	}

	const release = properties?.releases?.find((r) => r.id === release_id);
	if (!release) {
		enqueueSnackbar('Invalid Release!', { variant: 'error' });
		navigate(`/admin/skus/finished/${sku_id}`);
	}

	console.log('Company', company);
	const name = company?.company_name;

	const {
		lot_code = '',
		count = '',
		size,
		abv,
	} = properties;

	const filledDate = (new Date(release?.date))?.toLocaleDateString();
	const sizeML = Number(converter.cx(size?.value, size?.unit, UNITS.milliliters)).toFixed(0);
	const abvV = Number(converter.cx(abv?.value, abv?.unit, UNITS.abv)).toFixed(1);

	const liquid = liquidTypes.find((l) => l.value === release?.liquid_type);
	const lcs = Number(sizeML && count ? converter.cx(sizeML * count, UNITS.milliliters, UNITS.liters) : 0).toFixed(2);

	const qrParams = useMemo(() => {
		return {
			p: {
				l: lot_code,
			},
			s: sku_id,
			n: release?.name,
			r: release_id,
			c: company?.company_id,
			y: company?.company_name,
			d: company?.company_compliance?.excise_id,
			f: filledDate,
			m: sizeML,
			a: abvV,
			b: count,
			l: lcs,
			k: liquid.label,
		};
	}, [properties, company, release, liquid]);

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: convertStyleObjectToCSS(styles) });
	};

	return (
		<Box sx={styles} className="root">
			<FlexBox justifyContent="flex-end" sx={{ marginBottom: 2 }}>
				<TitledButton
					handleClick={() => handlePrint(printRef)}
					variant="outlined"
					color="secondary"
					label="Print"
					sx={{ width: '72px', height: '55px' }}
				>
					<Print height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box ref={printRef} sx={{ display: 'grid', gap: '10px' }}>
				{labels &&
					Array.from({ length: labels }, (_, index) => (
						<div
							key={index}
							className="container"
						>
							<div className="label">
								<div className="long" style={{ gridColumn: '1 / span 6', gridRow: '1' }}>
									{name}
								</div>
								<div className="long" style={{ gridColumn: '1 / span 6', gridRow: '2' }}>
									{`Liquid: ${liquid.label}`}
								</div>

								{/* First row */}
								<div className="upcBarcode" style={{ gridColumn: '1 / span 6', gridRow: '3 / span 6' }}>
									<Barcode value={lot_code} format="ITF" />
								</div>
								{/* Fifth row */}
								<div className="upcBarcode" style={{ gridColumn: '1 / span 3', gridRow: '9 / span 7' }}>
									<SKULocatorQRCode params={{ ...qrParams, i: index + 1 }} type='s' />
								</div>
								<div className="long"
										 style={{ gridColumn: '4 / span 3', gridRow: '9' }}>{`Serial Number: `}<strong>{index + 1}</strong>
								</div>
								<div className="long"
										 style={{ gridColumn: '4 / span 3', gridRow: '10' }}>{`Date Filled: `}<strong>{filledDate}</strong>
								</div>
								<div className="long"
										 style={{ gridColumn: '4 / span 3', gridRow: '11' }}>{'Btl Size: '}<strong>{`${sizeML} mL`}</strong>
								</div>
								<div className="long"
										 style={{ gridColumn: '4 / span 3', gridRow: '12' }}>{`Btls/CS: `}<strong>{count}</strong></div>
								<div className="long"
										 style={{ gridColumn: '4 / span 3', gridRow: '13' }}>{'L/CS: '}<strong>{`${lcs} L`}</strong></div>
								<div className="long"
										 style={{ gridColumn: '4 / span 3', gridRow: '14 / span 2' }}>{' '}</div>
							</div>
						</div>
					))}
			</Box>
		</Box>
	);
}

export default StandardLabel;
