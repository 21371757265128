import { Box } from '@mui/material';
import { useMemo } from 'react';

import { Body1, H3 } from '@pw/components/Typography';
import { createKey } from '../nodes';
import DraggableTile from './DraggableTile';
import ToggleMenuButton from './ToggleMenuButton';
import styles from './ToolBox.styles';
import ToolBoxSection from './ToolBoxSection';

const LHS = ({
	components,
	ingredients,
	observations,
	operations,
	setNodes,
	// isBatch,
	className,
	showGlobalMenu,
	setShowGlobalMenu,
}) => {
	const componentTiles = useMemo(
		() =>
			components.map((n) => (
				<DraggableTile node={n} setNodes={setNodes} key={createKey(n)} />
			)),
		[components, setNodes],
	);

	const ingredienTTiles = useMemo(
		() =>
			ingredients.map((n) => (
				<DraggableTile node={n} setNodes={setNodes} key={createKey(n)} />
			)),
		[ingredients, setNodes],
	);

	const observationTiles = useMemo(
		() =>
			observations.map((n) => (
				<DraggableTile node={n} setNodes={setNodes} key={createKey(n)} />
			)),
		[observations, setNodes],
	);

	const operationTiles = useMemo(
		() =>
			operations.map((n) => (
				<DraggableTile node={n} setNodes={setNodes} key={createKey(n)} />
			)),
		[operations, setNodes],
	);

	return (
		<Box className={`toolbox-root toolbar-root ${className || ''}`} sx={styles}>
			<Box className='toolbox_header'>
				<ToggleMenuButton {...{ showGlobalMenu, setShowGlobalMenu }} />
				<H3 className='toolbox_heading'>Recipe Designer</H3>
				<Body1>Drag items and drop to the recipe board</Body1>
			</Box>
			<Box className='toolbox_content'>
				<ToolBoxSection heading='Components'>
					<Box className='tiles-list'>{componentTiles}</Box>
				</ToolBoxSection>
				<ToolBoxSection heading='Ingredients' className='section-background'>
					<Box className='tiles-list'>{ingredienTTiles}</Box>
				</ToolBoxSection>
				<ToolBoxSection heading='Observations'>
					<Box className='tiles-list'>{observationTiles}</Box>
				</ToolBoxSection>
				<ToolBoxSection heading='Operations' className='section-background'>
					<Box className='tiles-list'>{operationTiles}</Box>
				</ToolBoxSection>
			</Box>
		</Box>
	);
};

export default LHS;
