import LHS from './LHS';
import LHSSchedule from './LHS_Schedule';
import { useContext } from "react";
import DesignerContext from "../../../context/DesignerContext";

const ToolBox = ({
	document,
	itemName,
	setItemName,
	saveToName,
	setSaveToName,
	components,
	ingredients,
	observations,
	operations,
	// setNodes,
	isBatch,
	batches,
	onLoad,
	loadedFromName,
	// mode,
	className,
	showGlobalMenu,
	setShowGlobalMenu,
	setIsLoading,
}) => {
	const { mode, setNodes } = useContext(DesignerContext);

	return (
		<>
			{mode === 'schedule' ? (
				<LHSSchedule
					className={`left LHS-Schedule 
					${showGlobalMenu ? 'with-global-menu' : ''}`}
					{...{
						onLoad,
						loadedFromName,
						showGlobalMenu,
						setShowGlobalMenu,
						setIsLoading,
					}}
				/>
			) : (
				<LHS
					className={`left LHS ${showGlobalMenu ? 'with-global-menu' : ''}`}
					{...{
						components,
						ingredients,
						observations,
						operations,
						setNodes,
						isBatch,
						showGlobalMenu,
						setShowGlobalMenu,
					}}
				/>
			)}

			{/* <RHS
        className="right"
        {...{
          itemName, setItemName,
          saveToName, setSaveToName,
          components, ingredients, observations,
          setNodes,
          isBatch,
          batches,
          onLoad,
        }} /> */}
		</>
	);
};

export default ToolBox;
