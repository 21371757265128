import {
	CheckBoxRounded,
	KeyboardArrowLeft,
	KeyboardArrowRight
} from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikForm,
	FormikSelect,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import InventorySearch from '@pw/components/Search/InventorySearch';
import { H5, Overline } from '@pw/components/Typography';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { ASSET_NAMES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useGetId from '@pw/utilities/hooks/logic/useGetId';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useGetSKUEntriesLazyQuery } from '@pw/utilities/hooks/service/useGetSKUEntriesQuery';
import flatten from 'lodash.flatten';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

function SKUSelection({
	name,
	selection,
	skuEntryItems,
	unit,
	isSelected,
	toggleSelection,
}) {
	const { values } = useContext(FormikContext);
	const [storageAllocation, setStorageAllocation] = useState(null);

	const processSelection = useCallback(
		(item) => {
			if (isSelected(item)) {
				// Remove the amount..
				item.amount = 0;
				toggleSelection(item);
			} else {
				// Amount outstanding..
				const amount = values?.[name] ?? 0; // Total amount we need

				setStorageAllocation(item);
				// let selectedAmount =
				// 	selection?.reduce((v, i) => v + Number(i?.amount ?? 0), 0) ?? 0;
				// const storageList = flatten(skuEntryItems.map((e) => e.storage ?? []));
				// selectedAmount +=
				// 	storageList?.reduce((v, i) => v + Number(i?.amount ?? 0), 0) ?? 0;
				//
				// if (selectedAmount < amount) {
				// 	item.amount = amount - selectedAmount;
				// 	toggleSelection(item);
				// }
			}
		},
		[isSelected, toggleSelection, values, name, selection, skuEntryItems],
	);

	const handleStorageSelection = useCallback((item) => {
		if (item && storageAllocation) {
			console.log('Storage selection', item);
			storageAllocation.amount = item.amount;
			toggleSelection(item);
		}
		setStorageAllocation(null);
	}, [storageAllocation, toggleSelection]);

	if (!skuEntryItems || skuEntryItems.length === 0) return;

	return (
		<>
			<Stack spacing={0} className='list'>
				{skuEntryItems.map((entry) => (
					<Stack className='listItem storage-container' key={entry.path}>
						<Stack spacing={0.75} className='listContent'>
							<Stack
								spacing={0.5}
								sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}
							>
								<IDDisplay value={entry?.sku_id} />
								<SourceDisplay
									type={entry?.request_type}
									name={entry?.request_name}
								/>
							</Stack>
							{entry?.storage?.map((_storage) => (
								<SKUStorageItem
									key={_storage.asset_id}
									item={_storage}
									unit={unit}
									selected={isSelected(_storage)}
									onSelect={() => processSelection(_storage)}
								/>
							))}
						</Stack>
					</Stack>
				))}
			</Stack>
			{storageAllocation && (
				<StorageSetupModal open={!!storageAllocation} title="Pick" item={storageAllocation} onClose={handleStorageSelection} />
			)}
		</>
	);
}

function SKUStorageItem({ item, unit, selected = false, onSelect }) {
	const {
		rw_asset_id,
		asset_type,
		amount,
		available_quantity,
		duty_paid,
		reserved_quantity,
	} = item;

	const onClick = useCallback(() => {
		if (onSelect) {
			onSelect(!selected);
		}
	}, [selected, onSelect]);

	return (
		<Box className='card' action={ASSET_NAMES[asset_type]} onClick={onClick}>
			<Box className='card-tab'>
				{selected && <CheckBoxRounded className='check' />}
			</Box>
			<Stack
				className={`card-content ${duty_paid ? 'card-highlight' : ''}`}
				spacing={0.25}
			>
				<IDDisplay value={rw_asset_id} />
				<DutyPaidDisplay value={duty_paid} />
				<Divider variant='middle' sx={{ opacity: 0.6 }} />
				<AmountDisplay amount={amount} unit={unit} />
				<AmountDisplay
					label='Reserved'
					amount={reserved_quantity}
					unit={unit}
				/>
				<AmountDisplay
					label='Available'
					amount={available_quantity}
					unit={unit}
				/>
			</Stack>
		</Box>
	);
}


function StorageItem({ item, onSelect, unit }) {
	return (
		<SKUStorageItem
			item={item}
			unit={unit}
			selected={item.selected ?? false}
			onSelect={(selected) => onSelect(item, selected)}
		/>
	)
}

function ChildList({ label, items, update, unit }) {
	const handleSelect = useCallback((item, selected) => {

		// Deselect any other option (so only allow single selects);
		const updatedItems = items.map((i) => ({ ...i, selected: (i.asset_id === item.asset_id ? selected: false) }));

		debounce(() => update(updatedItems), 25);
	}, [update, items]);

	return (
		<Stack className="inventory" sx={{ width: '40%' }}>
			<Box className="inventory-header">
				<Overline>{label}</Overline>
			</Box>
			<Box className="inventory-contents">
				<Stack spacing={0.5}>
					{items.map((_item) => (
						<StorageItem key={_item.asset_id} item={_item} onSelect={handleSelect} unit={unit} />
					))}
				</Stack>
			</Box>
		</Stack>
	);
}

function GeneralSourceSKU({
	item,
	onClose,
	duty_paid,
	include_tax_code = false,
	taxCodes = [],
	exportItems = false,
}) {
	const { enqueueSnackbar } = useSnackbar();
	const { path } = useGetId();

	const converter = useConverter();

	// We track the "amount" we want at the SKU Item level
	const {
		sku_id,
		sku_type,
		tax_code,
		amount,
		properties = {},
		include_holder,
		entries = [],
	} = item ?? {};
	const { unit } = properties;

	const [storageAllocation, setStorageAllocation] = useState(null);

	const existingStorage = useMemo(
		() => flatten(entries.map((e) => e.storage ?? [])),
		[entries],
	);

	const [available, , , upsertAvailable, removeAvailable] = useItemListManager(
		ID.asset,
		COMP.asset,
		[],
	);

	console.log('Available', available);

	const [selected, , , upsertSelected, removeSelected] = useItemListManager(
		ID.asset,
		COMP.asset,
		existingStorage.map((i) => ({ ...i, selected: false }))
	);

	console.log('Selected', selected);

	const finished = useMemo(() => sku_type === SKU_TYPES.FINISHED, [sku_type]);

	const [refetch, { data: skuEntryItems = [], error, isLoading }] =
		useGetSKUEntriesLazyQuery(entries);

	// const [selection, initSelection, toggleSelection, isSelected] = useSelections(
	// 	storage,
	// 	EQUAL.asset,
	// );

	const [ProgressBar, { setProgress }] = useProgress();

	const changeSet = useMemo(
		() => ({
			amount: [
				amount && unit ? converter.cx(amount ?? 0, null, unit) : amount ?? '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
			include_holder: [!!include_holder, yup.boolean()],
			...(include_tax_code
				? {
						tax_code: [
							tax_code ?? '',
							yup.string().required('Tax code required!'),
						],
					}
				: {}),
		}),
		[amount],
	);

	useEffect(() => {
		if (error) {
			enqueueSnackbar(error?.message, { variant: 'error' });
		}
	}, [error]);

	const handleSubmit = (values) => {
		try {
			// let requiredAmount = Number(values.amount);
			const sku = {
				...item,
				amount: converter.cx(values.amount, unit), // convert the amount back
				include_holder: values.include_holder,
				entries: skuEntryItems.reduce((acc, entry) => {
					const storage = entry.storage
						.map((_storage) => {
							const isSelected = selected.find((s) => s.asset_id === _storage.asset_id);
							if (isSelected) {
								return { ...isSelected, selected: undefined };
							} else {
								return { ..._storage, amount: 0, selected: undefined };
							}
							// if this storage is selected, we will use it and set amount
							// if (isSelected(_storage) && requiredAmount > 0) {
							// 	const available_quantity = Number(_storage.available_quantity);
							// 	// if available_quantity is greater than requiredAmount, we will use requiredAmount
							// 	if (available_quantity > requiredAmount) {
							// 		st = { ..._storage, amount: requiredAmount };
							// 		requiredAmount = 0;
							// 	} else {
							// 		// otherwise, we will use available_quantity and reduce requiredAmount
							// 		st = { ..._storage, amount: available_quantity };
							// 		requiredAmount -= available_quantity;
							// 	}
							// } else {
							// 	// otherwise, we will use 0
							// 	st = { ..._storage, amount: 0 };
							// }
							// return st;
						})
						.filter((st) => st.amount > 0);

					// recalculate the amount used in this entry
					const amount = storage.reduce(
						(v, i) => v + Number(i.amount),
						0,
					);
					// if (amount > entry.available_quantity) {
					// 	throw new Error(
					// 		`Amount ${amount} is greater than available quantity ${entry.available_quantity}`,
					// 	);
					// }
					acc.push({ ...entry, storage, amount });
					return acc;
				}, []),
				...(include_tax_code
					? {
							tax_code: values?.tax_code,
						}
					: {}),
			};
			console.log('Setting SKU', values, sku);
			onClose(sku);
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	const onLoadEntries = useCallback(
		async (value) => {
			const params = {
				amount: value,
				sku_id,
				request_id: path,
				...(duty_paid !== undefined ? { duty_paid } : {}),
			};
			const entryList = await refetch(params);
			console.log('Refetch', entryList);

			const storageList = flatten(entryList.map((e) => e.storage ?? [])).map((i) => ({ ...i, amount: undefined}));
			console.log('storageList', storageList);

			const unused = storageList.filter((i) => !selected.find((s) => s.asset_id === i.asset_id));
			console.log('Unused', unused);
			upsertAvailable(unused);
			// initSelection(storageList.filter((i) => i.amount > 0));
		},
		[path, refetch, sku_id, duty_paid, upsertAvailable, selected],
	);

	useEffect(
		() =>
			setProgress(
				selected.reduce(
					(v, i) =>
						v +
						Number(
							i?.amount ??
								(i?.available_quantity ?? 0) - (i?.reserved_quantity ?? 0),
						),
					0,
				),
			),
		[selected],
	);

	// const saveDisabled = useMemo(() => !(completion === 100), [completion]);

	const handleAdd = useCallback(() => {
		const selectedItems = available.filter((s) => s.selected).map((s) => ({ ...s, selected: false }));
		if (selectedItems.length === 1) {
			setStorageAllocation(selectedItems[0]);

			debounce(() => {
				removeAvailable(selectedItems);
				// upsertSelected(selectedItems);
			}, 25);
		}
	}, [available, selected]);

	const handleRemove = useCallback(() => {
		const selectedItems = selected.filter((s) => s.selected).map((s) => ({ ...s, amount: undefined, selected: false }));
		debounce(() => {
			removeSelected(selectedItems);
			upsertAvailable(selectedItems);
		}, 25);
	}, [available, selected]);

	const handleStorageSelection = useCallback((item) => {
		if (item) {
			upsertSelected(item)
		}
		setStorageAllocation(null);
	}, [storageAllocation, upsertSelected]);

	return (
		<>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={2}>
					<InventorySearch
						label='Amount'
						name='amount'
						unit={finished ? null : unit}
						fullWidth
						handler={onLoadEntries}
						searching={isLoading}
					/>

					{exportItems && (
						<FormikCheckBox
							label='Include Holder'
							name='include_holder'
							fullWidth
						/>
					)}
					{!!include_tax_code && (
						<FormikSelect
							label={'Tax Code'}
							name='tax_code'
							options={taxCodes}
							fullWidth
						/>
					)}

					<Instructions>Please select from available inventory.</Instructions>

					<ProgressBar name='amount' label='Amount' />

					<H5>Inventory</H5>
					<FlexBox alignItems="stretch" sx={{ position: 'relative' }}>
						<ChildList label="Available" items={available} update={upsertAvailable} unit={unit} />
						<Stack sx={{ width: '10%' }}>
							<IconButton
								onClick={handleAdd}
								disabled={!available.some((s) => s.selected)}
								aria-label='Add'
							>
								<KeyboardArrowRight />
							</IconButton>
							<IconButton
								onClick={handleRemove}
								disabled={!selected.some((s) => s.selected)}
								aria-label='Remove'
							>
								<KeyboardArrowLeft />
							</IconButton>
						</Stack>
						<ChildList label="Selected" items={selected} update={upsertSelected} unit={unit} />
					</FlexBox>

					<Errors />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton
							loading={isLoading}
							type='submit'
							size='small'
						>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
			{storageAllocation && (
				<StorageSetupModal open={!!storageAllocation} title="Pick" item={storageAllocation} onClose={handleStorageSelection} />
			)}
		</>
	);
}

export default GeneralSourceSKU;
