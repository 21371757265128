import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikNumberField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import toSKUStorageItem from '@pw/utilities/adapters/toSKUStorageItem';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

function StorageSetupModalWrapper({ total, allocated, ...props }) {
	const left = (total ?? 0) - (allocated ?? 0);
	console.log('Amount', total, allocated, left);
	return (
		<StorageSetupModal left={left} {...props} />
	);
}

function SKUStorageItem({ item }) {
	const { amount } = item;

	return (
		<Box className='card'>
			<Box className='card-tab' />
			<Box className='card-content'>
				<Body3>
					<Bolder>Items:</Bolder>
					&nbsp;
					{amount}
				</Body3>
			</Box>
		</Box>
	);
}

function GeneralSKU({ item, onClose }) {
	// const { enqueueSnackbar } = useSnackbar();
	const { entries = [{ amount: 0 }], sku_type, unit } = item ?? {};

	console.log('SKU Setup', item);

	// We expect there to be an entry - which has the specific values we want for delivery..
	const entry = entries[0] ?? { amount: 0 };
	const { amount, storage = [] } = entry;

	const [ProgressBar, { setProgress, completion, progressTotal }] = useProgress();

	const storageProps = useMemo(() => ({
		title: 'Storage Allocation',
		filter: {
			asset_types: [
				ASSET_TYPES.pallet,
				ASSET_TYPES.container,
			],
		},
		disableEvents: true,
		assetFilter: (i) => (i.asset_status !== ASSET_STATUS.DEFECTED),
		assetAdapter: (i) => (i.asset_status !== ASSET_STATUS.DEFECTED) ? toSKUStorageItem(i, sku_type, unit) : null,
		assetConverter: (i, s) => ({ ...i, processed: (!!s) ? ASSET_PROCESSED_STATUS.CONFIRMED : i.processed }),
		initialAssets: [...storage],
		displayAsset: (item) => <SKUStorageItem item={item} key={item.asset_id} />,
		AssetModal: (props) => <StorageSetupModalWrapper {...props} total={progressTotal} allocated={completion} />,
		allowAssetSelector: false,
	}), [storage, sku_type, unit]);

	const [
		,
		[storageAssets],
		,
		StorageInventory,
		StorageModals,
	] = useInventorySelectorHook(storageProps);

	const changeSet = useMemo(
		() => ({
			amount: [
				amount,
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
		}),
		[amount],
	);

	const handleSubmit = useCallback(
		(values) => {
			const updatedEntry = {
				...entry,
				amount: values.amount,
				storage: storageAssets,
			};
			console.log('Storage items', storageAssets);
			const pending = storageAssets.length === 0 || storageAssets.some(
				(a) => a.processed !== ASSET_PROCESSED_STATUS.CONFIRMED,
			);

			const sku = {
				...item,
				processed: pending
					? INVENTORY_STATUS.PENDING
					: INVENTORY_STATUS.CONFIRMED,
				entries: [updatedEntry],
			};
			onClose(sku);
		},
		[item, entry, storageAssets],
	);

	useEffect(() => {
		const allocated = storageAssets.reduce((v, i) => v + Number(i.amount), 0);
		setProgress(allocated);
	},[storageAssets]);

	// // Need to adapt the SKU to meet the SKUItem/SKUEntryItem/SKUStorage structure
	// // As this is a delivery, we'll add one basic entry here
	// const handleAdd = (asset) => {
	// 	if (asset?.asset_status === ASSET_STATUS.DEFECTED) {
	// 		enqueueSnackbar(`Defective assets cannot be selected!`, {
	// 			variant: 'error',
	// 		});
	// 		return;
	// 	}
	// 	upsertAsset(toSKUStorageItem(asset));
	// };
	//
	// const display = (value) => (
	// 	<SKUStorageItem item={value} key={value.asset_id} />
	// );

	// const filter = useMemo(
	// 	() => ({
	// 		asset_types: [ASSET_TYPES.pallet],
	// 	}),
	// 	[],
	// );

	return (
		<>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing="2rem">
					<FormikNumberField label='Items' name='amount' fullWidth />

					<Instructions>
						Please select the storage for the produced items.
					</Instructions>

					<ProgressBar name='amount' label='Amount' />

					{/*<InventorySelectorV2*/}
					{/*	title='Storage Allocation'*/}
					{/*	types={filter}*/}
					{/*	onAdd={handleAdd}*/}
					{/*	assets={[storageAssets, setStorageAsset, removeAsset, display]}*/}
					{/*/>*/}
					<StorageInventory />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
							label='Cancel'
						/>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>

			<StorageModals />

			{/*{!!storageAsset && (*/}
			{/*	<StorageSetupModal*/}
			{/*		open={!!storageAsset}*/}
			{/*		item={storageAsset}*/}
			{/*		upsert={upsertAsset}*/}
			{/*		onClose={() => setStorageAsset(null)}*/}
			{/*	/>*/}
			{/*)}*/}
		</>
	);
}

export default GeneralSKU;
