import { Box, Tab, Tabs } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import FilledButton from '@pw/components/Buttons/FilledButton';
import { isBatch as checkIsBatch, saveToLS } from '@pw/components/ProductionDesigner/functions/localStorage';
import { H4 } from "@pw/components/Typography";

import DesignerContext from '@pw/context/DesignerContext';
import { saveDesign } from '@pw/services/production.service';
import styles from './Actions.styles';

const ButtonTabs = ({ labels, tab, setTab }) => (
  <Tabs
    value={tab}
    onChange={(_, tabVal) => { setTab(tabVal) }}>
    {labels.map(({ label, id }) => (
      <Tab value={label} label={label} key={id} />
    ))}
  </Tabs>
);

const lockNodes = (nodes) => nodes.map((n) => (
  {
    ...n,
    // draggable: false, deletable: false, connectable: false,
  }
));

const Actions = ({
  mode,
  showGlobalMenu,
  hasContent,
  loadedFromName,
  batches, onLoad }) => {
  const { document, setIsLoading, setRefreshOptions } = useContext(DesignerContext);

  const [tab, setTab] = useState('');

  const recipeBatches = useMemo(() => (batches || [])
    .filter(
      (b) => b.recipe_id === document.recipe_id || b.recipe_id === document.id
    ), [batches, document?.recipe_id, document?.id]);

  const isBatch = useMemo(() => checkIsBatch(document), [document]);
  const type = isBatch ? 'Batch' : 'Recipe';

  const createBatch = useCallback(() => {
    const now = new Date();
    const ts = now.getTime();
    const isoDate = now.toISOString().split('T')[0];
    const _name = `Untitled ${isoDate}`;
    const batch = {
      ...document,
      name: _name,
      created: ts,
      updated: ts,
      entity_id: btoa(ts),
      recipe_id: document.recipe_id || document.entity_id,
      nodes: lockNodes(document.nodes),
    };
    saveToLS(batch);
    onLoad(batch);
  }, [document]);

  useEffect(() => {
    if (!recipeBatches.length) return;
    const newTab = recipeBatches.find((b) => b.name === tab) ||
      recipeBatches.find((b) => b.name === loadedFromName);

    if (newTab && newTab.name !== tab) setTab(newTab.name);
    onLoad(newTab);
  }, [recipeBatches, tab, loadedFromName]);

  const saveDesignCallback = async (design) => {
    setIsLoading(true);
    await saveDesign(design);
    setRefreshOptions((prev) => prev + 1);
    setIsLoading(false);
  };

  return (
    <Box
      className={`recipe-actions-root 
        ${showGlobalMenu ? 'with-global-menu' : ''}`}
      sx={styles}
    >
      {mode === 'schedule' /* recipeBatches?.length > 0 */ && (
        <Box className="tabs">
          <H4 className="heading">Production runs</H4>
          <ButtonTabs
            labels={recipeBatches.map(({ name, id }) => ({ label: name, id }))}
            tab={tab}
            setTab={setTab}
          />
        </Box>
      )}

      <Box className="actions">
        {/*{hasContent && (*/}
        {/*  <FilledButton*/}
        {/*    size="small"*/}
        {/*    handleClick={() => {*/}
        {/*      createBatch();*/}
        {/*    }}>*/}
        {/*    <Add />New run*/}
        {/*  </FilledButton>*/}
        {/*)}*/}
        {/*{mode === 'design' && (*/}
        {/*  <LoadButton*/}
        {/*    type="recipe"*/}
        {/*    onLoad={onLoad}*/}
        {/*    loadedFromName={loadedFromName}*/}
        {/*    value={loadedFromName}*/}
        {/*		valueKey={'id'}*/}
        {/*    allowUpload*/}
        {/*		refreshOptions={refreshOptions}*/}
        {/*		setIsLoading={setIsLoading}*/}
        {/*  />*/}
        {/*)}*/}
        {hasContent && (
          <FilledButton
            size="small"
            // disabled={!hasContent}
            handleClick={() => {
              saveDesignCallback(document);
            }}

          >Save&nbsp;{type}</FilledButton>
        )}
      </Box>
    </Box >
  );
}

export default Actions;
